import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import CustomButton from "../../components/Button";
import AddUserModal from "./AddUserModal";
import InstanceUsers from "./UserTable";
import { UserapiClient } from "../../components/userManagementApiclient";
import NotFound from "../NotFound";
import UsersNegativeScreen from "../../components/NegativeScreens/Users/UsersNegativeScreen";

const Users = ({ accessToken, userProfile, sellerId, currentBusiness }) => {
 
  const userId = userProfile?.userId;
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const handleShowAddUserModal = () => setShowAddUserModal(true);
  const handleCloseAddUserModal = () => setShowAddUserModal(false);

  const isAdmin = currentBusiness?.users?.some(
    (user) => user?.userId === userId && user?.role === "admin"
  );
  console.log("accessToken", accessToken);
  console.log("userProfile", userProfile);
  console.log("currentBusiness", currentBusiness);
  console.log("isAdmin", isAdmin);
  const handleSaveUser = (userData) => {
    setUserList([...userList, userData]);
  };

  useEffect(() => {
    const GetUsers = async () => {
      if (!isAdmin) return; // Don't fetch users if not admin

      try {
        const UserClient = UserapiClient(accessToken);
        setIsLoading(true);
        const response = await UserClient.get(
          `/getusers/${currentBusiness?.businessId}/${userProfile?.userId}`
        );
        setUserList(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };
    GetUsers();
  }, [refresh, sellerId, isAdmin, currentBusiness, userProfile]);

  if (!isAdmin) {
    return <NotFound />;
  }

  return (
    <>
      <div className="displays-bg-container">
        <Row className="p-2 mb-3">
          <Col md={6}>
            <h6>Users</h6>
          </Col>
          <Col align="right" md={6} className="add-layout-search-container">
            <div className="search-input-container-lay">
              <AiOutlineSearch style={{ fontSize: "23px" }} />
              <input
                type="text"
                className="search-input"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <CustomButton
              disabledButton={false}
              btnType="button"
              BtnClassName="add-layout-button"
              BtnTxt={<>Add User</>}
              ClickEvent={handleShowAddUserModal}
            />
          </Col>
        </Row>

        {isLoading ? (
          <>
            <Row>
              <Col md={12} align="center">
                <div className="loader"></div>
              </Col>
            </Row>
          </>
        ) : userList.length === 1 ? (
          <UsersNegativeScreen
            handleShowAddUserModal={handleShowAddUserModal}
          />
        ) : (
          <>
            <InstanceUsers
              data={userList}
              setRefresh={setRefresh}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              currentBusiness={currentBusiness?.businessId}
              idToken={accessToken}
            />
          </>
        )}

        <AddUserModal
          showAddUserModal={showAddUserModal}
          handleCloseAddUserModal={handleCloseAddUserModal}
          navigate={() => {}}
          setuserStatus={() => {}}
          handleSaveUser={handleSaveUser}
          setRefresh={setRefresh}
          userId={userId}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  userProfile: state.userProfile,
  sellerId: state.sellerIdReducer,
  currentBusiness: state.currentBusiness,

  ...state,
});

export default connect(mapStateToProps)(Users);
