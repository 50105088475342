import React, { useState, useEffect } from "react";
import { FaImage, FaList } from "react-icons/fa";
import { BsGrid } from "react-icons/bs";
import { Button } from "antd";
import CustomButton from "../../components/Button";
import Images from "./Images/Images";
import Videos from "./Videos/Videos";
import AddmoreImages from "./AddmoreImages";
import AddmoreVideos from "./AddmoreVideos";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Cookies from "js-cookie";
import "./Library.css";
import { AiOutlineSearch } from "react-icons/ai";

const Library = ({ userProfile, business }) => {
  const [view, setView] = useState("grid");
  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [isImages, setImages] = useState(true);
  const [itemLength, setItemLength] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const preferredView = Cookies.get("preferredView");
    if (preferredView) {
      setView(preferredView);
    }

    const length = localStorage.getItem("length");
    setItemLength(parseInt(length, 10) || 0); // Parse as integer, default to 0 if null or undefined

    // Determine the default view based on item length
    if (length && parseInt(length, 10) >= 7) {
      // If the number of items is greater than or equal to 7, use list view
      if (preferredView !== "grid") {
        setView("list");
      }
    } else {
      // Otherwise, use grid view
      if (preferredView !== "list") {
        setView("grid");
      }
    }
  }, []);
  const handleListview = () => {
    setView("list");
    Cookies.set("preferredView", "list", { expires: 365 });
  };

  const handleGridview = () => {
    setView("grid");
    Cookies.set("preferredView", "grid", { expires: 365 });
  };
  const [searchTerm, setSearchTerm] = useState("");

  const clickToVideos = () => {
    setImages(false);
  };

  const clickToImages = () => {
    setImages(true);
  };

  const handleAddMoreClick = () => {
    if (isImages) {
      setShowImageModal(true);
    } else {
      setShowVideoModal(true);
    }
  };

  const handleCloseImageModal = () => setShowImageModal(false);
  const handleCloseVideoModal = () => setShowVideoModal(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 430); // Adjust the breakpoint according to your needs
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setView("grid"); // Set grid view as default for mobile
    }
  }, [isMobile]);

  return (
    <div className="displays-bg-container">
      <Row className="p-2">
        <Col md={6}>
          <h6>Library of {business?.businessName} </h6>
        </Col>
        <Col align="right" md={6} className="add-layout-search-container">
          <div className="search-input-container-lay ">
            <AiOutlineSearch style={{ fontSize: "23px" }} />
            <input
              type="search"
              className="search-input"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <CustomButton
            disabledButton={false}
            btnType="button"
            BtnClassName="add-layout-button"
            BtnTxt={<>Add More</>}
            ClickEvent={handleAddMoreClick}
          />
        </Col>
      </Row>
      <br />
      <div className="images-videos-btn-container d-flex flex-column flex-sm-row justify-content-between">
        <div className="d-flex justify-content-end mb-2 mb-sm-0">
          <Button
            className="default tw-flex tw-items-center poppins-font"
            onClick={clickToImages}
            style={{
              background: isImages ? "transparent" : "",
              border: isImages ? "1px solid #4096FF" : "",
              color: isImages ? "#4096FF" : "",
              marginRight: 16,
            }}
          >
            <FaImage style={{ marginRight: "5px" }} />
            Images
          </Button>
          <Button
            className="default tw-flex tw-items-center poppins-font"
            onClick={clickToVideos}
            style={{
              background: !isImages ? "transparent" : "",
              border: !isImages ? "1px solid #4096FF" : "",
              color: !isImages ? "#4096FF" : "",
            }}
          >
            <FaList style={{ marginRight: "5px" }} />
            Videos
          </Button>
        </div>

        <div className="d-flex justify-content-end">
          <Button
            className="default tw-flex tw-items-center poppins-font"
            onClick={handleListview}
            style={{
              marginRight: 16,
              background: view === "list" ? "#DEEBFF" : "",
            }}
          >
            <FaList style={{ marginRight: "5px" }} />
            List View
          </Button>
          <Button
            className="default tw-flex tw-items-center poppins-font"
            onClick={handleGridview}
            style={{ background: view === "grid" ? "#DEEBFF" : "" }}
          >
            <BsGrid style={{ marginRight: "5px" }} />
            Grid View
          </Button>
        </div>
      </div>

      <div>
        {isImages ? (
          <Images
            view={view}
            searchTerm={searchTerm}
            handleAddMoreClick={handleAddMoreClick}
          />
        ) : (
          <Videos
            view={view}
            searchTerm={searchTerm}
            handleAddMoreClick={handleAddMoreClick}
          />
        )}
      </div>

      <AddmoreImages
        showImageModal={showImageModal}
        handleCloseImageModal={handleCloseImageModal}
      />
      <AddmoreVideos
        showVideoModal={showVideoModal}
        handleCloseVideoModal={handleCloseVideoModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.userProfile,
  business: state.currentBusiness,
});

export default connect(mapStateToProps)(Library);
