import { combineReducers } from "redux";

const login = (state = false, action) => {
  switch (action.type) {
    case "LOGIN":
      return action.login;
    case "LOGOUT":
      return false;

    default:
      return state;
  }
};

const accessToken = (state = null, action) => {
  switch (action.type) {
    case "ACCESSTOKEN":
      return action.accessToken;
    default:
      return state;
  }
};
const sellerIdReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_SELLER_ID":
      return action.sellerId;
    default:
      return state;
  }
};
const currentBusiness = (state = null, action) => {
  switch (action.type) {
    case "SET_CURRENT_BUSINESS":
      return action.business;

    default:
      return state;
  }
};

const RefreshToken = (state = null, action) => {
  switch (action.type) {
    case "REFRESHTOKEN":
      return action.RefreshToken;
    default:
      return state;
  }
};
const allTokensReducer = (state = [null], action) => {
  switch (action.type) {
    case "SET_ALLTOKEN":
      return action.AllTokens;
    default:
      return state;
  }
};
const userProfile = (state = null, action) => {
  switch (action.type) {
    case "USERPROFILE":
      return action.userProfile;
    default:
      return state;
  }
};

const Profileinfo = (state = null, action) => {
  switch (action.type) {
    case "PROFILEINFO":
      return action.profileInfo;
    default:
      return state;
  }
};

const userRoutes = (state = null, action) => {
  switch (action.type) {
    case "USERROUTES":
      return action.userRoutes;
    default:
      return state;
  }
};

const initialImageState = {
  imageFiles: [],
  currentPage: 0,
  shouldRefreshData: false,
};

export const imageReducer = (state = initialImageState, action) => {
  switch (action.type) {
    case "SET_IMAGE_FILES":
      return {
        ...state,
        imageFiles: action.payload,
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload,
      };
    case "REFRESH_IMAGE_DATA":
      return {
        ...state,
        shouldRefreshData: !state.shouldRefreshData,
      };
    default:
      return state;
  }
};

const initialVideoState = {
  videoFiles: [],
  currentPage: 0,
};

export const videoReducer = (state = initialVideoState, action) => {
  switch (action.type) {
    case "SET_VIDEO_FILES":
      return {
        ...state,
        videoFiles: action.payload,
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  login,
  accessToken,
  sellerIdReducer,
  userProfile,
  userRoutes,
  RefreshToken,
  Profileinfo,
  currentBusiness,
  imagemedia: imageReducer,
  videomedia: videoReducer,
  allTokensReducer,
});

export default rootReducer;
