import React, { useEffect, useState } from "react";
import {  IoIosInformationCircleOutline } from "react-icons/io";
import { motion } from "framer-motion";
import {  EWallLogo, EWallLogoSmall, EWallLogowithName } from "./icons";
import { FaPlay } from "react-icons/fa";
import { FiMinus } from "react-icons/fi";
import { UserapiClient } from "../components/userManagementApiclient";
import { connect } from "react-redux";

const chatVariants = {
  maximized: {
    width: "23rem",
    height: "30rem",
    transition: { type: "spring", stiffness: 300, damping: 25 },
  },
  minimized: {
    width: "4rem",
    height: "4rem",
    transition: { type: "spring", stiffness: 300, damping: 25 },
  },
};

const ChatBot = ({ chatMenuState, setChatMenuState, sellerId ,accessToken, userId}) => {
  console.log("accessToken", accessToken);
  const [hiState, setHiState] = useState(false);
  const [chatState, setChatState] = useState(true);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [caseId, setCaseId] = useState(null);
  const [detailContainer, setDetailContainer] = useState(true);
  const [quickReplay, setQuickReplay] = useState([]);
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const [minimized, setMinimized] = useState(false);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [isLoadingInitialMessage, setIsLoadingInitialMessage] = useState(false);
  const [UserData, SetUserData] = useState([]);
  const UserClient = UserapiClient(accessToken);
  const [userName, setUserName] = useState("");
  const [userEmail , setUserEmail] = useState("");

  console.log('UserId',userId )


  useEffect (() => {
    getProfille();
  }, [userId , accessToken]);
  const getProfille = async () => {
    try {
      const response = await UserClient.get(`/getprofile/${userId}`);
      SetUserData(response.data);
      setUserName(response.data.name);
      setUserEmail(response.data.email);
    } catch {
      console.log("Error fetching profile");
    }
  };
  


  console.log("User Details:" , userName, userEmail)

  console.log("UserData", UserData);
  useEffect(() => {
    const timer = setTimeout(() => {
      setHiState(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, [accessToken]);

  const sendWelcomeMessage = async () => {
    setIsLoadingInitialMessage(true);
    try {
      const welcomeMessage = {
        message: "Welcome",
        caseId: "12424",
        sellerId: sellerId,
        name: userName,
        email: userEmail,
        phoneNumber: "",
      };
      const response = await UserClient.post("/chat", welcomeMessage);
      const botResponse = response.data.response;
      const suggestion = response.data.titles;

      setMessages([{ bot: botResponse[0].content }]);
      setQuickReplay([...suggestion]);
      setIsFirstMessage(false);
    } catch (error) {
      console.error("Error sending welcome message:", error);
    } finally {
      setIsLoadingInitialMessage(false);
    }
  };

  const handleChatStateChange = (newState) => {
    setChatState(newState);
    if (!newState && isFirstMessage) {
      sendWelcomeMessage();
    }
  };

  const sendMessage = async (message = input) => {
    if (!message.trim()) return;
  
    setMessages([...messages, { user: message }]);
    setInput("");
    setIsBotTyping(true);
    setUserName(UserData.name);
    setUserEmail(UserData.email);
  
    const newMessage = {
      message: message,
      caseId: "12424",
      sellerId: "252w5525",
      email: userEmail || UserData.email,  // Use userEmail instead of emailInput
      phoneNumber: phoneInput,
      name: userName || UserData.name // Add userName to the message body
    };

    try {
      const response = await UserClient.post("/chat", newMessage);
      const botResponse = response.data.response;
      const suggestion = response.data.titles;

      setIsBotTyping(false);

      if (!botResponse || botResponse.length === 0) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { bot: "I'm sorry, I couldn't understand your request. Could you please try again?" },
        ]);
        setQuickReplay(["Main Menu"]);
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          { bot: botResponse[0].content },
        ]);
        setQuickReplay(suggestion || []);
      }

      setCaseId(response.data.caseId);
    } catch (error) {
      setIsBotTyping(false);
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { bot: "I'm sorry, there was an error processing your request. Please try again." },
      ]);
      setQuickReplay(["Main Menu"]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    sendMessage(suggestion);
  };

  const toggleMinimize = () => {
    setMinimized(!minimized);
  };

  const SkeletonLoader = () => (
    <div className="tw-animate-pulse tw-space-y-2">
      <div className="tw-h-4 tw-bg-gray-200 tw-rounded"></div>
      <div className="tw-h-4 tw-bg-gray-200 tw-rounded tw-w-5/6"></div>
      <div className="tw-h-4 tw-bg-gray-200 tw-rounded tw-w-4/6"></div>
    </div>
  );

  return (
    <div className="tw-flex tw-flex-col tw-items-end tw-z-20 tw-absolute tw-bottom-0 tw-right-10 ">
      {chatMenuState && (
        <motion.div
          initial="maximized"
          animate={minimized ? "minimized" : "maximized"}
          variants={chatVariants}
          className={`tw-bg-[#F2F2F2] tw-mb-5 tw-rounded-3xl tw-relative tw-shadow-lg tw-overflow-hidden`}
        >
          {minimized ? (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
              className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
              onClick={toggleMinimize}
            >
              <EWallLogoSmall className="tw-w-8 tw-h-8" />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              className="tw-h-full"
            >
              <div className="tw-absolute tw-top-4 tw-right-4 tw-z-10">
                <button
                  className="tw-bg-blue-500 tw-rounded-full tw-p-1 tw-flex tw-items-center tw-justify-center"
                  onClick={toggleMinimize}
                >
                  <FiMinus className="tw-text-white tw-w-4 tw-h-4" />
                </button>
              </div>
              <div className="tw-flex tw-flex-col tw-justify-between tw-h-full">
                {chatState ? (
                  <div>
                    <div className="tw-flex tw-flex-col">
                      <div
                        className="tw-h-[14rem]"
                        style={{
                          backgroundColor: "#001D6E",
                          borderRadius: "20px 20px 0px 0px",
                        }}
                      >
                        <div className="tw-m-4">
                          <EWallLogowithName />
                        </div>
                        <div className="tw-mx-4 tw-mt-10 tw-font-bold tw-text-3xl tw-tracking-wide">
                          <p>Hi there 👋</p>
                          <p>How can we help?</p>
                        </div>
                      </div>
                      <motion.div
                        whileTap={{
                          rotate: -2,
                        }}
                        className="tw-h-auto tw-w-[90%] tw-p-2 tw-bg-white tw-text-black tw-shadow-2xl tw-rounded-2xl tw-mx-5 tw-cursor-pointer tw-tracking-wide"
                        style={{
                          top: "190px",
                          position: "absolute",
                        }}
                        onClick={() => handleChatStateChange(false)}
                      >
                        <div className="tw-flex tw-justify-between tw-items-center">
                          <div>
                            <p className="tw-font-bold">Ask a question</p>
                            <p className="tw-text-gray-500">
                              Our Bot and team can help
                            </p>
                          </div>
                          <div>
                            <IoIosInformationCircleOutline size={25} />
                          </div>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                ) : (
                  <div className="tw-flex tw-flex-col tw-justify-between tw-h-full">
                    <div className="tw-text-black tw-text-sm tw-flex tw-flex-col tw-justify-between tw-h-auto tw-overflow-y-scroll tw-no-scrollbar chatbot-container">
                      <div>
                        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                          <br />
                          <div className="tw-font-bold">eWalls</div>
                          <EWallLogo />
                          <div className="tw-font-bold">
                            Bot answers instantly
                          </div>
                          <div className="tw-text-gray-500">
                            Ask for the team if needed
                          </div>
                        </div>
                      </div>
                      <div className="tw-m-4">
                        {isLoadingInitialMessage ? (
                          <SkeletonLoader />
                        ) : (
                          <>
                            {messages.map((msg, index) => (
                              <div key={index}>
                                <div
                                  className={`tw-flex ${
                                    msg.user ? "tw-justify-end" : ""
                                  } tw-space-x-2`}
                                >
                                  {msg.bot && (
                                    <div className="tw-flex-shrink-0">
                                      <EWallLogoSmall className="tw-w-6 tw-h-6" />
                                    </div>
                                  )}
                                  <div
                                    className={`tw-p-2 tw-mb-4 tw-rounded-lg tw-font-medium ${
                                      msg.user
                                        ? "tw-bg-blue-200"
                                        : "tw-bg-white"
                                    }`}
                                  >
                                    {msg.user ||
                                      msg.bot.split("\n").map((line, i) => (
                                        <React.Fragment key={i}>
                                          {line}
                                          {i <
                                            msg.bot.split("\n").length - 1 && (
                                            <br />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </div>
                                </div>
                                {msg.bot &&
                                  index === messages.length - 1 &&
                                  quickReplay.length > 0 && (
                                    <div className="tw-flex tw-flex-col tw-space-y-2 tw-mt-2 tw-pb-2 tw-ml-4 chatbot-container">
                                      {quickReplay.map((data, index) => (
                                        <motion.div
                                          key={index}
                                          whileTap={{ scale: 0.95 }}
                                          onClick={() =>
                                            handleSuggestionClick(data)
                                          }
                                          className="tw-p-2 tw-bg-blue-100 tw-ml-[20px] tw-rounded-lg tw-cursor-pointer hover:tw-bg-blue-200 tw-transition-colors tw-duration-200"
                                        >
                                          <p className="tw-text-black tw-m-0 tw-text-sm tw-font-medium">
                                            {data}
                                          </p>
                                        </motion.div>
                                      ))}
                                    </div>
                                  )}
                              </div>
                            ))}
                          </>
                        )}
                        {isBotTyping && (
                          <div className="tw-flex tw-items-center tw-space-x-2 tw-ml-4">
                            <EWallLogoSmall className="tw-w-6 tw-h-6" />
                            <div className="tw-bg-gray-200 tw-rounded-full tw-px-3 tw-py-2">
                              <div className="tw-flex tw-space-x-1">
                                <div className="tw-w-2 tw-h-2 tw-bg-gray-500 tw-rounded-full tw-animate-bounce"></div>
                                <div
                                  className="tw-w-2 tw-h-2 tw-bg-gray-500 tw-rounded-full tw-animate-bounce"
                                  style={{ animationDelay: "0.2s" }}
                                ></div>
                                <div
                                  className="tw-w-2 tw-h-2 tw-bg-gray-500 tw-rounded-full tw-animate-bounce"
                                  style={{ animationDelay: "0.4s" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="tw-mx-4 tw-mb-5">
                      <div className="tw-flex tw-flex-row tw-justify-between tw-mt-2 tw-text-black tw-items-center">
                        <div className="tw-bg-white tw-p-2 tw-w-[85%] tw-justify-between tw-rounded-md tw-border tw-border-[#ccc]">
                          <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter" && input.trim() !== "") {
                                sendMessage();
                              }
                            }}
                            placeholder="Type your message..."
                            className="tw-bg-white tw-outline-none tw-w-full tw-font-medium"
                          />
                        </div>
                        <motion.div
                          initial={{ scale: 0 }}
                          animate={{ rotate: 0, scale: 1 }}
                          transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20,
                          }}
                          whileTap={{
                            scale: 0.8,
                            borderRadius: "100%",
                          }}
                        >
                          <button
                            onClick={() => sendMessage()}
                            className="tw-bg-blue-200 tw-w-11 tw-h-11 tw-flex tw-justify-center tw-p-2 tw-rounded-full tw-items-center tw-transition-all"
                            disabled={input === ""}
                            style={{ opacity: input !== "" ? "1" : "0.5" }}
                          >
                            <FaPlay />
                          </button>
                        </motion.div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </motion.div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  sellerId: state.sellerIdReducer,
  accessToken: state.accessToken,
  ...state,
});

export default connect(mapStateToProps)(ChatBot);
