import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Toaster,toast } from "sonner";
import { IoCloudUploadOutline } from "react-icons/io5";
import Cookies from "js-cookie";

import { useState, useEffect } from "react";
import { UserapiClient } from "../../components/userManagementApiclient";
import { useDispatch } from "react-redux";
import { setCurrentBusiness, setSellerId } from "../../redux/action";
const EditBusiness = ({ userProfile, currentBusiness,accessToken, state }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("currentBusiness", currentBusiness);
  const UserClient = UserapiClient(accessToken);
  const businessId = currentBusiness?.businessId;
  const userId = userProfile?.sub;
  const [fileUrl, setFilesUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [fileLocation, setFileLocation] = useState("");

  const [originalFormData, setOriginalFormData] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false); // State to track edit mode
  useEffect(() => {
    setFormData({
      businessName: currentBusiness.businessName || "",
      businessType: currentBusiness.businessType || "",
      email: currentBusiness.email || "",
      phone: currentBusiness.phone || "",
      address1: currentBusiness.address1 || "",
      address2: currentBusiness.address2 || "",
      country: currentBusiness.country || "",
      state: currentBusiness.state || "",
      city: currentBusiness.city || "",
      postalCode: currentBusiness.postalCode || "",
      abnNumber: currentBusiness.abnNumber || "",
    });
    setOriginalFormData({
      businessName: currentBusiness.businessName || "",
      businessType: currentBusiness.businessType || "",
      email: currentBusiness.email || "",
      phone: currentBusiness.phone || "",
      address1: currentBusiness.address1 || "",
      address2: currentBusiness.address2 || "",
      country: currentBusiness.country || "",
      state: currentBusiness.state || "",
      city: currentBusiness.city || "",
      postalCode: currentBusiness.postalCode || "",
      abnNumber: currentBusiness.abnNumber || "",
    });
  }, [currentBusiness]);

  // const getBusinessData = async () => {
  //   try {
  //     const response = await UserClient.get(`/businesses/${userId}`);
  //     const lastUsedSellerId = Cookies.get("lastusedsellerId");
  //     const lastUserBusinessInfo = Cookies.get("lastusedbusinessDetails");
  //     if (lastUsedSellerId && !sellerIdDispatched && lastUserBusinessInfo) {
  //       console.log("lastUsedSellerId", lastUsedSellerId);
  //       dispatch(setSellerId(lastUsedSellerId));
  //       dispatch(setCurrentBusiness(JSON.parse(lastUserBusinessInfo)));
  //       setSellerIdDispatched(true);
  //     } else if (!sellerIdDispatched) {
  //       console.log(
  //         "seller Id dispatching if no last used ",
  //         response.data.businesses[0].sellerId
  //       );
  //       dispatch(setSellerId(response.data.businesses[0].sellerId));
  //       dispatch(setCurrentBusiness(response.data.businesses[0]));
  //       setSellerIdDispatched(true);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleCancel = () => {
    setEditMode(false);
    navigate(-1);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSaveBusiness = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!isFormChanged() && !file) {
      toast.info("No changes made.");
      return;
    }
    if (!formData.businessName || !formData.email) {
      // Alert or handle the error appropriately

      return;
    }

    try {
      const storedBusinessData = localStorage.getItem("getAllBusiness");

      const parsedBusinessData = storedBusinessData
        ? JSON.parse(storedBusinessData)
        : [];
      const existingBusiness = parsedBusinessData.find(
        (business) => business.businessName === formData.businessName
      );
      if (existingBusiness) {
        toast.error(`Business name "${formData.businessName}" already exists.`);
        // setLoading(false);
        return;
      }
      let metadata = {}; // Declare metadata outside the conditional block

      if (file) {
        const res = await UserClient.post("/generatePresignurl", {
          businessId: businessId,
          userId: userId,
          fileName: file.name,
          fileType: file.type,
        });

        // console.log("rrrr", res);
        if (res.status === 200) {
          const presignedUrls = res?.data;

          const formDatas = new FormData();
          const key = presignedUrls?.fields?.key;
          const filse = file;

          formDatas.append("key", key);
          formDatas.append("file", filse);

          const uploadResponse = await fetch(presignedUrls?.url, {
            method: "POST",
            body: formDatas,
          });

          // console.log("pree", uploadResponse);
          if (uploadResponse.status === 204) {
            metadata = {
              // Update metadata here
              fileDetails: [
                {
                  fileName: file?.name,
                  fileType: file?.type,
                  fileSize: file?.size,
                  fileLocation: `${presignedUrls.url}/${encodeURIComponent(
                    key
                  )}`,
                },
              ],
            };

            setFileLocation(metadata.fileDetails[0].fileLocation);
          }
        }
      }
      const updatedFormData = {
        userId: userId,
        ...formData,
        fileLocation: file
          ? metadata.fileDetails[0].fileLocation
          : fileLocation,
      };
      // Update business information even if file is not attachedddd
      const response = await UserClient.put(
        `/updateBusiness/${businessId}`,
        updatedFormData
      );
      toast.success("Business updated successfully");
      console.log(
        "Business updated successfully!",
        response.data.updatedProfile
      );
      dispatch(setCurrentBusiness(response.data.updatedProfile));
      // await getBusinessData();
      Cookies.set("businessEdited", "true");
      Cookies.set("lastusedbusinessDetails", response.data.updatedProfile, {
        expires: 365,
      });
      window.location.reload();
    } catch (error) {
      toast.error("Error updating business");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    const fileUrl = URL.createObjectURL(selectedFile);
    setFilesUrl(fileUrl);
    setFile(selectedFile);
  };

  const isFormChanged = () => {
    for (const key in formData) {
      if (formData[key] !== originalFormData[key]) {
        return true;
      }
    }
    return false;
  };
  return (
    <div className=" tw-w-full  tw-overflow-y-scroll tw-py-3 tw-justify-center tw-items-center tw-bg-[#f3f3f3f0]">
      <div className="tw-w-full  tw-mt-2 tw-p-10 tw-space-y-8 ">
        <div className="tw-bg-white tw-rounded-xl tw-shadow-lg tw-w-full tw-h-4/5">
          <div
            className="tw-px-16 tw-py-2 tw-xl:py-6 tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-bg-gradient-to-b  tw-bg-blue-500 tw-relative"
            style={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}
          >
            <div>
              <p className="tw-text-2xl tw-font-semibold tw-text-white">
                Business Information
              </p>
              <p className="tw-text-sm tw-font-semibold tw-text-white">
                Details about your Business
              </p>
            </div>

            <div className="tw-absolute tw-top-1/2 tw-mt-[-15] tw-right-0">
              {fileUrl !== null ? (
                <div
                  className="tw-w-24 tw-h-24 tw-border-solid tw-border-2 tw-flex tw-items-center tw-justify-center tw-border-[#ccc] tw-rounded-md tw-bg-white tw-mr-6"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${fileUrl})`,
                    backgroundSize: "cover",
                    backgroundBlendMode: "multiply",
                    color: "#fff",
                  }}
                >
                  {editMode && (
                    <label
                      htmlFor="fileInput"
                      className="tw-flex tw-flex-col tw-items-center tw-justify-center"
                    >
                      <IoCloudUploadOutline className="tw-text-center" />
                      <p className="tw-text-xs" style={{ cursor: "pointer" }}>
                        {" "}
                        Upload Image
                      </p>
                    </label>
                  )}
                  <input
                    id="fileInput"
                    type="file"
                    // required
                    accept="image/*"
                    className="tw-hidden"
                    style={{ cursor: "pointer" }}
                    onChange={(e) => handleFileChange(e)}
                  />
                </div>
              ) : (
                <div
                  className="tw-w-24 tw-h-24 tw-border-solid tw-border-2 tw-flex tw-items-center tw-justify-center tw-border-[#E2E2E2] tw-rounded-md tw-bg-white tw-mr-6"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${currentBusiness.fileLocation})`,
                    backgroundSize: "cover",
                    backgroundBlendMode: "multiply",
                    color: "#fff",
                  }}
                >
                  {editMode && (
                    <label
                      htmlFor="fileInput"
                      className="tw-flex tw-flex-col tw-items-center tw-justify-center"
                    >
                      <IoCloudUploadOutline className="tw-text-center" />
                      <p className="tw-text-xs" style={{ cursor: "pointer" }}>
                        {" "}
                        Upload Image
                      </p>
                    </label>
                  )}

                  <input
                    id="fileInput"
                    type="file"
                    // required
                    accept="image/*"
                    className="tw-hidden"
                    style={{ cursor: "pointer" }}
                    onChange={(e) => handleFileChange(e)}
                  />
                </div>
              )}

              <button
                className="tw-absolute tw-top-[-46px] tw-right-[5px] tw-p-1 tw-bg-white tw-rounded-full tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-text-[20px]"
                onClick={toggleEditMode}
              >
                {editMode ? (
                  <MdOutlineCancel className="tw-text-gray-600" />
                ) : (
                  <AiOutlineEdit className="tw-text-gray-600" />
                )}
              </button>
              {/* <p style={{ maxWidth: "200px" }}>{file?.fileName}</p> */}
            </div>
          </div>
          <div className=" ">
            <div className="tw-px-16 tw-py-2 tw-flex w-full">
              <div className="tw-flex tw-justify-start tw-w-1/3 tw-mr-20">
                <div className="tw-w-full tw-flex tw-flex-col tw-mt-6 tw-gap-4 tw-justify-between">
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Business Name
                    </label>
                    <input
                      type="text"
                      required
                      name="businessName"
                      value={formData.businessName}
                      disabled={!editMode}
                      readOnly={!editMode}
                      onChange={handleChange}
                      className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                        editMode ? "" : "tw-text-[#b8b5b5]"
                      }`}
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Email Id
                    </label>
                    <input
                      type="email"
                      name="email"
                      // required
                      readOnly
                      disabled
                      value={formData.email}
                      // onChange={handleChange}
                      // style={{ border: "1px solid #CED4DA" }}
                      className={`tw-mt-1 tw-px-2 input-outline   tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md tw-text-[#6C757D] 
                      ${editMode ? "" : "tw-text-[#b8b5b5]"}`}
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Address 1
                    </label>
                    <input
                      type="text"
                      required
                      name="address1"
                      value={formData.address1}
                      onChange={handleChange}
                      disabled={!editMode}
                      readOnly={!editMode}
                      className={`tw-mt-1 tw-px-2 input-outline tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                        editMode ? "" : "tw-text-[#b8b5b5]"
                      }`}
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Country
                    </label>
                    <input
                      type="text"
                      name="country"
                      required
                      disabled={!editMode}
                      readOnly={!editMode}
                      value={formData.country}
                      onChange={handleChange}
                      className={`tw-mt-1 tw-px-2 input-outline tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                        editMode ? "" : "tw-text-[#b8b5b5]"
                      }`}
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      required
                      disabled={!editMode}
                      readOnly={!editMode}
                      value={formData.city}
                      onChange={handleChange}
                      className={`tw-mt-1 tw-px-2 input-outline tw-bg-white tw-border tw-border-[#CED4DA]   tw-w-full  tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                        editMode ? "" : "tw-text-[#b8b5b5]"
                      }`}
                    />
                  </div>
                </div>
              </div>

              <div className="tw-flex tw-justify-start tw-w-1/3">
                <div className="tw-w-full tw-flex tw-flex-col tw-mt-6 tw-gap-4 tw-justify-between">
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Business Type
                    </label>
                    <input
                      type="text"
                      // required
                      name="businessType"
                      value={formData.businessType}
                      disabled={!editMode}
                      readOnly={!editMode}
                      onChange={handleChange}
                      className={`tw-mt-1 tw-px-2 input-outline tw-border tw-bg-white tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                        editMode ? "" : "tw-text-[#b8b5b5]"
                      }`}
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Phone
                    </label>
                    <input
                      type="number"
                      name="phone"
                      // required
                      value={formData.phone}
                      onChange={handleChange}
                      disabled={!editMode}
                      readOnly={!editMode}
                      className={`tw-mt-1 tw-px-2 input-outline tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                        editMode ? "" : "tw-text-[#b8b5b5]"
                      }`}
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Address 2
                    </label>
                    <input
                      type="text"
                      name="address2"
                      // required
                      value={formData.address2}
                      onChange={handleChange}
                      disabled={!editMode}
                      readOnly={!editMode}
                      className={`tw-mt-1 tw-px-2 input-outline tw-border tw-bg-white tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                        editMode ? "" : "tw-text-[#b8b5b5]"
                      }`}
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      State
                    </label>
                    <input
                      type="text"
                      name="state"
                      // required
                      value={formData.state}
                      onChange={handleChange}
                      disabled={!editMode}
                      readOnly={!editMode}
                      className={`tw-mt-1 tw-px-2 input-outline tw-border tw-bg-white tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                        editMode ? "" : "tw-text-[#b8b5b5]"
                      }`}
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Postal Code
                    </label>
                    <input
                      type="text"
                      name="postalCode"
                      // required
                      value={formData.postalCode}
                      disabled={!editMode}
                      readOnly={!editMode}
                      onChange={handleChange}
                      className={`tw-mt-1 tw-px-2 input-outline tw-border tw-bg-white tw-border-[#CED4DA]   tw-w-full  tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                        editMode ? "" : "tw-text-[#b8b5b5]"
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            <hr />
            <div className=" tw-px-16 tw-py-2  w-full">
              <p className="tw-text-xl tw-font-semibold tw-text-black">
                Business Identification Number
              </p>
              <div className=" tw-w-1/3 tw-justify-start">
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    Australian Business No(ABN)
                  </label>
                  <input
                    type="text"
                    name="abnNumber"
                    value={formData.abnNumber}
                    disabled={!editMode}
                    readOnly={!editMode}
                    onChange={handleChange}
                    className="tw-mt-1 tw-px-2 input-outline tw-border tw-bg-white tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-px-16 tw-py-3 tw-flex tw-justify-end tw-border-t tw-border-gray-200">
            <button
              className="tw-bg-white tw-text-black tw-px-4 tw-py-2 tw-rounded tw-border tw-border-[#CED4DA] tw-mr-2"
              onClick={handleCancel}
              type="button"
            >
              Cancel
            </button>

            <button
              className={`tw-bg-[#0d6efd] tw-text-white tw-px-4 tw-py-2 tw-rounded tw-border tw-border-[#0d6efd] ${
                !editMode ? "tw-opacity-50 tw-cursor-not-allowed" : ""
              }`}
              // disabled={loading || !editMode || !isFormChanged()}
              onClick={handleSaveBusiness}
            >
              {" "}
              Save Changes{" "}
            </button>
          </div>
          <Toaster position="bottom-right" className="tw-mr-4"  />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.userProfile,
  currentBusiness: state.currentBusiness,
  accessToken: state.accessToken,
});
export default connect(mapStateToProps)(EditBusiness);
