import axios from "axios";

export const UserapiClient = (token) => {
  const baseurl = process.env.REACT_APP_BASE_URL_USER_MANAGEMENT;
  // const token = "";
  //const baseurl = "http://localhost:3020"
  return axios.create({
    baseURL: baseurl,
    headers: {
      "Content-type": "application/json",
       Authorization: `Bearer ${token}`,
    },
  });
};
