import axios from "axios";

export const apiClient = (token = null) => {
  const baseurl = process.env.REACT_APP_BASE_URL;
  // const token = "";
  //const baseurl = "http://localhost:8080/api/v1/"
  return axios.create({
    baseURL: baseurl,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

// export default axios.create({
//   baseURL: baseurl,
//   headers: {
//     "Content-type": "application/json",
//     Authorization: token,
//   },
// });
