import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { AiOutlineSearch, AiOutlineEnvironment } from "react-icons/ai";
import { Switch, Card, Pagination } from "antd";
import { BsGrid } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { Menu } from "antd";
import { IoLocationOutline } from "react-icons/io5";
import { PiGoogleChromeLogoThin } from "react-icons/pi";
import CustomButton from "../../components/Button";
import { FaList } from "react-icons/fa";

import { Tooltip, Button } from "antd";
import { Routes as AppRoutes } from "../../routes";
import { useNavigate } from "react-router-dom";
import "./Displays.css";

import { apiClient } from "../../components/apiClient";
import ViewDisplay from "./ViewDisplay";

import CustomDataTable from "../../components/DataTable";
import CustomDropDown from "../../components/CustomDropDown";
import { MdDeleteForever } from "react-icons/md";
import Cookies from "js-cookie";
import { Toaster, toast } from "sonner";
import DisplaysNegativeScreen from "../../components/NegativeScreens/Displays/DisplaysNegativeScreen";
import axios from "axios";

const Displays = ({
  currentBusiness,
  accessToken,
  userId,
  AllTokens,
  refreshToken,
  userProfile,
  sellerId,
  state,
  screenShotPath,
}) => {
  console.log("User Profile:", userProfile);
  console.log("Current Business:", currentBusiness);
  let logUserId = userId;
  const navigate = useNavigate();
  const [showPlusModal, setPlusModal] = useState(false);
  const [displayArr, setDisplayArr] = useState([]);

  const [isFetchingData, setFetchingData] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [buttonColor, setButtonColor] = useState({});
  const [healthCheckDataArray, setHealthCheckDataArray] = useState([]);
  const [displayIdArray, setDisplayIdArray] = useState([]);
  const [showSwitchModal, setSwitchModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [displayId, setDisplayId] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredDisplays, setFilteredDisplays] = useState([]);
  const [formData, setFormData] = useState({
    RegistrationCode: "",
    displayName: "",
    //   location: '',
    //   fcmToken: "",
    //   displayGroup: '',
    //   assignedLayouts: '',
    //   baseOS: '',
    //   appVersion: '',
    //   displayBrand: '',
    //   displayDevice: '',
    //   lastCheckIn: '',
    //   lastHeartbeat: '',
    //   lastLayout: '',
    screenShotPath: "",
    //   eventLogs: '',
    //   userId: '',
  });

  // console.log(formData.RegistrationCode);
  const handlePlusClose = () => setPlusModal(false);
  const handlePlusModalShow = () => setPlusModal(true);

  const [showViewDisplay, setShowViewDisplay] = useState(false);
  const [viewData, setViewData] = useState({});
  const [visible, setVisible] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [viewType, setViewType] = useState("list");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = displayArr.slice(indexOfFirstItem, indexOfLastItem);
  const imageUrl = require("../../assets/defaultDisplay.png");

  useEffect(() => {
    let filteredDisplays = displayArr;

    if (selectedStatus !== null) {
      filteredDisplays = filteredDisplays.filter(
        (display) => display.status === selectedStatus
      );
    }

    if (search.trim() !== "") {
      filteredDisplays = filteredDisplays.filter((display) =>
        display.displayName.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredDisplays(filteredDisplays);
  }, [selectedStatus, search, displayArr]);

  useEffect(() => {
    const DisplaypreferredView = Cookies.get("displaypreferredView");
    if (DisplaypreferredView) {
      setViewType(DisplaypreferredView);
    }
  }, []);
  const handleGridView = () => {
    setViewType("grid");
  };

  const handleListView = () => {
    setViewType("list");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingData(true);
        const response = await apiClient(accessToken).get(
          `/display/list/${sellerId}`
        );
        

        const filteredData = selectedLocation
          ? response.data.data.filter(
              (param) => param.location === selectedLocation
            )
          : response.data.data;

        const modifiedData = filteredData.map((item) => ({
          ...item,
          lastCheckIn: new Date(item.lastCheckIn),
        }));
        const displayIds = modifiedData.map(({ id }) => id);
        setDisplayArr(modifiedData);
        console.log("modifiedData", modifiedData);
        setDisplayIdArray(displayIds);
        setHealthCheckDataArray(Array(displayIds.length).fill([]));
        healthCheck(modifiedData);
        console.log("displayIds", displayIds, modifiedData);
        if (modifiedData.length < 7) {
          setViewType("list");
        }
      } catch (error) {
        console.error("Error fetching display", error);
      } finally {
        setFetchingData(false);
      }
    };

    fetchData();
  }, [accessToken, selectedLocation]);
  useEffect(() => {
    setSearchFilter(
      displayArr.filter((val) =>
        val.displayName.toLowerCase().includes(search.toLowerCase())
      )
    );
    //console.log("Search filter updated:", searchFilter);
  }, [displayArr, search]);
  const clickToFlip = () => {
    setOpen(!isOpen);
  };

  const locations = [...new Set(displayArr.map((param) => param.location))];
  const handleLocationFilter = (location) => {
    setSelectedLocation(location);
  };

  // console.log("sz", displayArr);

  const clearLocationFilter = () => {
    setSelectedLocation(null);
  };
  const clearStatusFilter = () => {
    setSelectedStatus(null);
  };
  const filteredDisplayArr = selectedLocation
    ? displayArr.filter((param) => param.location === selectedLocation)
    : displayArr;

  const items = locations.map((eachLocation, index) => {
    return {
      key: `${index}`,
      label: (
        <Button
          key={index}
          style={{
            height: "50px",
            width: "100%",
            textAlign: "left",
            padding: "8px 12px",
            backgroundColor:
              selectedLocation === eachLocation ? "#1890ff" : "transparent",
            color: selectedLocation === eachLocation ? "#fff" : "#000",
            border: "0px solid #d9d9d9",
          }}
          onClick={() => handleLocationFilter(eachLocation)}
        >
          <AiOutlineEnvironment style={{ marginRight: "8px" }} />
          {eachLocation}
        </Button>
      ),
    };
  });

  const handleShowViewAddDisplay = () => {
    navigate("/addDisplay");
  };

  const handleShowViewDisplay = (val = null, visibleVal) => {
    if (val) {
      setVisible(visibleVal);
      setViewData(val);
      setShowViewDisplay(true);
    }
  };

  const handleCloseViewDisplay = (val = null) => {
    if (val);
    setShowViewDisplay(false);
  };
  const [getfcm, setGetfcm] = useState(null);
  const handleAddDisplays = async () => {
    try {
      setFetchingData(true);
      const data = { id: formData.RegistrationCode };
      const client = apiClient(accessToken);

      const response = await client.post("/display/getFcm", data);
      setGetfcm(response?.data);
      const fcmData = response?.data;
      if (response.status === 200) {
        const existingDisplay = displayArr.find(
          (display) => display.displayName === formData.displayName
        );

        // console.log("addidplay called", response.status);
        if (existingDisplay) {
          // If display with the same name exists, notify user and do not create a new one
          toast.error("Display with the same name already exists");
        } else {
          // If display with the same name doesn't exist, proceed to create a new one
          addDisplay(fcmData, formData.displayName);
        }

        setFetchingData(false);
      }
      formData.RegistrationCode = "";
      formData.displayName = "";
      formData.screenShotPath = "";
    } catch (error) {
      toast.error("fcm is not found");
      // console.log("error", error);
      setFetchingData(false);
    }
  };

  const handleShowDisplayPreview = (param) => {
    navigate(`/displayPreview/${param.id}`, { state: { param } });
  };

  function formatDateToCustomString(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds =
      String(date.getMilliseconds()).padStart(3, "0") + "000000";

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  const healthCheck = async (displayData) => {
    const currentBusinessId = localStorage.getItem("currentbusinesssid");
    const sellerid = currentBusiness.sellerId;
    console.log("sellerid", sellerid);
    if (currentBusinessId) {
      console.log("Current Business ID:", currentBusinessId);
    } else {
      console.log("No business ID found in localStorage.");
    }
    try {
      const idToken = accessToken;
      const client = apiClient(idToken);
      const payload = {
        businessId: currentBusinessId, // Assuming userProfile has businessId
        sellerId: sellerid, // Provided by props
        userId: userId, // Provided by props
        displayIds: displayData.map(({ id, displayName }) => ({
          id,
          displayName,
        })),
      };
      const response = await client.post("/display/healthCheck", payload);
      console.log("Server response:", response.data);

      const data = response.data.healthCheckData;
      if (Array.isArray(data) && data.length > 0) {
        const updatedArray = data.map((health) => {
          console.log("Health:", health);
          const lastCheckIn = new Date(health.lastCheckIn);
          console.log("Last Check In:", lastCheckIn);
          console.log("Last Check In:", lastCheckIn);
          const formattedDate = formatDateToCustomString(lastCheckIn);
          const displayId = health.displayId;
          const color = getColorBasedOnTime(lastCheckIn);
          // console.log(`Display ID: ${displayId}, Color: ${color}`);
          return { ...health, lastCheckIn: formattedDate, color };
        });

        const updatedDisplayArr = displayData.map((display) => {
          const healthCheck = updatedArray.find(
            (item) => item.displayId === display.id
          );
          const color = healthCheck ? healthCheck.color : "red";
          return { ...display, color };
        });

        setDisplayArr(updatedDisplayArr);
        setHealthCheckDataArray(updatedArray);
      } else {
        console.log("No health check data received from the server.");
      }
    } catch (error) {
      console.error("Error fetching health check data:", error);
    }
  };

  const getColorBasedOnTime = (lastCheckIn) => {
    if (lastCheckIn) {
      const timeDifference = calculateTimeDifference(lastCheckIn);
      const oneHourInMillis = 60 * 60 * 1000;
      return timeDifference <= oneHourInMillis ? "green" : "red";
    } else {
      return "red";
    }
  };

  const calculateTimeDifference = (lastCheckIn) => {
    const currentTimeUTC = new Date().toUTCString();
    console.log("Current Time UTC:", currentTimeUTC);
    const lastCheckInDate = new Date(lastCheckIn);

    const timeDifference = new Date(currentTimeUTC) - lastCheckInDate;
    return timeDifference;
  };

  const updateColorBasedOnTime = () => {
    // console.log("healthCheckDataArray:", healthCheckDataArray);

    const color = healthCheckDataArray.map((item) => item.color);
    console.log("Colors:", color);
    setButtonColor((prevColor) => ({
      ...prevColor,
      ...healthCheckDataArray.reduce(
        (acc, item, index) => ({
          ...acc,
          [item.displayId]: color[index],
        }),
        {}
      ),
    }));
  };

  useEffect(() => {
    const fetchHealthChecks = async () => {
      if (displayIdArray.length > 0) {
        updateColorBasedOnTime();
      }
    };

    fetchHealthChecks();

    const intervalId = setInterval(() => {
      fetchHealthChecks();
    }, 60 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [displayIdArray, healthCheckDataArray, setButtonColor]);

  const [addedLayouts, setAddedLayouts] = useState([]);

  useEffect(() => {
    // Retrieve addedlayouts data from localStorage
    const storedData = localStorage.getItem("addedlayouts");
    if (storedData) {
      setAddedLayouts(JSON.parse(storedData));
    }
  }, []);
  console.log("setAddedLayouts", addedLayouts);
  const handleDeleteDisplay = async (param) => {
    console.log("deleteclicked", param);
    const displayId = param.id;
    console.log("deleteclicked", displayId);
    try {
      // Confirm before deleting

      await apiClient(accessToken).delete(`display/delete/${displayId}`);

      // Remove the deleted display from the state
      setDisplayArr(displayArr.filter((display) => display.id !== displayId));
      toast.success("Display deleted successfully!");
    } catch (error) {
      console.error("Error deleting display:", error);
      toast.error("Error deleting display. Please try again later.");
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const handleOpenDeleteModal = (param) => {
    setCurrentItem(param);
    setIsModalOpen(true);
  };
  const handleDeleteConfirmed = async () => {
    if (!currentItem) return;

    console.log("Deleting item:", currentItem);
    try {
      const response = await apiClient(accessToken).delete(
        `display/delete/${currentItem.id}`
      );

      // Assuming `setDisplayArr` is your state updater for the display array
      setDisplayArr((prevDisplays) =>
        prevDisplays.filter((display) => display.id !== currentItem.id)
      );
      toast.success("Display deleted successfully!");
      setIsModalOpen(false); // Close the modal after successful deletion
    } catch (error) {
      console.error("Error deleting display:", error);
      toast.error("Error deleting display. Please try again later.");
      setIsModalOpen(false);
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Simple Modal Component
  const DeleteConfirmationModal = () => (
    <div
      className="tw-rounded-lg"
      style={{
        display: isModalOpen ? "block" : "none",
        position: "fixed",
        zIndex: 100,
        left: "50%",
        top: "10%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "20px",
        border: "1px solid #ccc",
      }}
    >
      <h1 className="tw-text-lg">Are you sure you want to delete?</h1>
      <div className="tw-flex tw-justify-end">
        <div className="tw-flex tw-gap-2">
          <button
            className="tw-rounded-lg tw-bg-blue-600 tw-px-2 tw-py-1 tw-text-blue-100"
            onClick={handleDeleteConfirmed}
          >
            Yes
          </button>
          <button
            className="tw-rounded-lg tw-bg-blue-100 tw-px-3 tw-py-1 tw-text-blue-600"
            onClick={closeModal}
          >
            No{" "}
          </button>
        </div>
      </div>
    </div>
  );
  const columns = [
    {
      name: "Name",
      selector: (param) => param.displayName,
      sortable: true,
    },

    {
      name: "Version",
      selector: (param) => param.appVersion,
      sortable: true,
    },
    {
      name: "Device",
      cell: (param) => param.displayBrand,
      sortable: true,
    },
    {
      name: "Location",
      cell: (param) => param.location || "Select Location",
      sortable: true,
    },
    {
      name: "Layout",
      selector: (param) => {
        let layoutCount = 0;
        try {
          const displayLayouts = JSON.parse(param.layoutId);
          layoutCount = Array.isArray(displayLayouts)
            ? displayLayouts.length
            : 0;
        } catch (error) {
          // console.error("Error parsing layoutId:", error);
        }
        return layoutCount.toString();
      },
      sortable: true,
    },
    {
      name: "Layout Names",
      cell: (param) => {
        try {
          const displayLayouts = JSON.parse(param.layoutId);
          if (Array.isArray(displayLayouts)) {
            return displayLayouts.map((layout) => layout.name).join(", ");
          }
        } catch (error) {
          // console.error("Error parsing layoutId:", error);
        }
        return "";
      },
    },

    {
      name: "Health",
      cell: (param) => {
        const { health, color } = param;
        const backgroundColor = color || "red";
        console.log(`Display ID: ${param.id}, Color received: ${color}`);

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={health} color={backgroundColor}>
              <span
                title={health}
                style={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: backgroundColor,
                  marginRight: "5px",
                }}
              ></span>
            </Tooltip>
            <span>{health}</span>
          </div>
        );
      },
    },
    {
      name: "Enabled",
      cell: (param) => (
        <Switch
          className="tw-bg-switch-bg tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-disabled:opacity-50"
          checked={param.status === "1"}
          onClick={() => handleShowSwitchModal(param, false)}
        />
      ),
    },
    {
      name: "Action",
      cell: (param) => (
        <>
          <div className="tw-flex tw-gap-2">
            <Tooltip title="View" color="#666666">
              <span
                title="View"
                style={{
                  cursor: "pointer",
                  padding: "5px",
                  display: "inline-block",
                  border: "1px solid #DFDFDF",
                  color: "#66666",
                  background: "#F0F0F0",
                  borderRadius: "4px",
                }}
                onClick={() => handleShowDisplayPreview(param, true)}
              >
                <div
                  style={{
                    width: "24px", // Adjust the size as needed
                    height: "24px", // Adjust the size as needed
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AiOutlineEdit
                    style={{ fontSize: "20px", color: "#666666" }}
                  />
                </div>
              </span>
            </Tooltip>
            <br />
            <Tooltip title="delete" color="#666666">
              <span
                title="delete"
                style={{
                  cursor: "pointer",
                  padding: "5px",
                  display: "inline-block",
                  border: "1px solid #DFDFDF",
                  color: "#66666",
                  background: "#F0F0F0",
                  borderRadius: "4px",
                }}
                onClick={() => handleOpenDeleteModal(param)}
                // onClick={() => handleDeleteDisplay(param)}
              >
                <div
                  style={{
                    width: "24px", // Adjust the size as needed
                    height: "24px", // Adjust the size as needed
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdDeleteForever
                    style={{ fontSize: "20px", color: "#666666" }}
                  />
                </div>
              </span>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const addDisplay = async (fcmData, dispName) => {
    try {
      const tokenResponse = await apiClient(accessToken).get(
        "/display/accessToken"
      );

      if (!tokenResponse.status !== 200)
        console.error("Failed to fetch access token");

      const fcmAccessToken = tokenResponse.data.token;
      console.log("FCM Token:", fcmAccessToken);
      const client = apiClient(accessToken);

      const bodydata = {
        appVersion: fcmData.appVersion,
        baseOS: fcmData.baseOS,
        displayBrand: fcmData.displayBrand,
        displayDevice: fcmData.displayDevice,
        fcm: fcmData.fcm,
        displayName: dispName,
        screenShotPath: formData.screenShotPath,
        status: "1",
        notificationPreference: true,
      };

      const response = await apiClient(accessToken).post(
        `display/add/${sellerId}`,
        {
          bodydata: bodydata,
        }
      );

      if (response.status === 200) {
        const displayResponse = response.data;
        const fcmToken = displayResponse.fcmToken;
        const deviceId = displayResponse.id;
        const appVersion = displayResponse.appVersion;
        const status = displayResponse.status;

        console.log("Display Responsesss", displayResponse)
        const notificationData = {
          message: {
            token: fcmToken,
            notification: {
              title: "Registered",
              body: JSON.stringify({
                refreshToken: refreshToken,
                deviceId,
              }),
            },
          },
        };

        const projectId = process.env.REACT_APP_PROJECT_ID;

        const notificationResponse = await fetch(
          `https://fcm.googleapis.com/v1/projects/${projectId}/messages:send`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${fcmAccessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(notificationData),
          }
        );

        if (notificationResponse.ok) {
          const notification = await notificationResponse.json();
          console.log("Notification response:", notification);
        } else {
          throw new Error("Failed to send notification");
        }

        setDisplayArr((prevDisplayArr) => [...prevDisplayArr, displayResponse]);
        toast.success("Display added successfully");
      }
      handlePlusClose();
      setFormData({
        RegistrationCode: "",
        displayName: "",
        screenShotPath: "",
      });
    } catch (error) {
      toast.error("Error adding display");
      console.error("Error adding display", error);
    }
  };
  const handleactiveDisplay = async (displayId) => {
    try {
      const response = await apiClient(accessToken).post(`/display/active`, {
        displayId: displayId,
        userId: logUserId,
      });

      if (response.status === 200) {
        setDisplayArr((prevDisplayArr) =>
          prevDisplayArr.map((display) =>
            display.id === displayId ? { ...display, status: "1" } : display
          )
        );
        toast.success("Display activated successfully");
      } else {
        toast.error("Failed to activate display");
      }
    } catch (error) {
      toast.error("Error activating display");
      console.error("Error activating display:", error);
    }
  };

  const handleDeactiveDisplay = async (displayId) => {
    try {
      const response = await apiClient(accessToken).post(`/display/deactive`, {
        displayId: displayId,
        userId: logUserId,
      });

      if (response.status === 200) {
        setDisplayArr((prevDisplayArr) =>
          prevDisplayArr.map((display) =>
            display.id === displayId ? { ...display, status: "2" } : display
          )
        );
        toast.success("Display deactivated successfully");
      } else {
        toast.error("Failed to deactivate display");
      }
    } catch (error) {
      toast.error("Error deactivating display");
      console.error("Error deactivating display:", error);
    }
  };

  // console.log(displayArr);

  const handleShowSwitchModal = (display) => {
    // Set the message based on the switch state
    const message =
      display.status === "1"
        ? "Do you want to deactivate it ?"
        : "Do you want to activate it ?";
    setModalMessage(message);
    setSwitchModal(true);
    setDisplayId(display.id);
  };

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setSearchFilter(displayArr.slice(start, end));
  }, [currentPage, itemsPerPage, displayArr]);

  const [screenshotImages, setScreenshotImages] = useState([]);

  useEffect(() => {
    const loadScreenshotImages = () => {
      const promises = searchFilter.map((display) => {
        return fetch(display.screenShotPath)
          .then((response) => {
            return response.blob().then((blob) => {
              if (response.ok) {
                const url = URL.createObjectURL(blob);
                return { id: display.id, url };
              } else {
                throw new Error("Forbidden: Unable to access the image");
              }
            });
          })
          .catch((error) => {
            console.error("Error loading screenshot image:", error);
            return { id: display.id, url: null };
          });
      });

      Promise.all(promises)
        .then((images) => {
          setScreenshotImages(images);
          console.log("Screenshot images:", images);
        })
        .catch((error) => {
          console.error("Error loading screenshot images:", error);
        });
    };

    loadScreenshotImages();
  }, [searchFilter]);

  useEffect(() => {
    let filtered = displayArr;

    // Filter by status if selectedStatus is not null
    if (selectedStatus) {
      filtered = filtered.filter(
        (display) => display.status === selectedStatus
      );
    }

    // Assuming other filters here, for example, a search filter
    if (search) {
      filtered = filtered.filter((display) =>
        display.displayName.includes(search)
      );
    }

    // Pagination logic should be applied after all filters
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setSearchFilter(filtered.slice(start, end));
  }, [currentPage, itemsPerPage, displayArr, selectedStatus, search]);

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
  };

  const StatusFilterMenu = (
    <Menu onClick={({ key }) => handleStatusFilter(key)}>
      <Menu.Item key="1">Active</Menu.Item>
      <Menu.Item key="2">Inactive</Menu.Item>
    </Menu>
  );

  return (
    <div className="displays-bg-container">
      <DeleteConfirmationModal />
      <Row className="p-2">
        <Col md={6}>
          <h6 className="texas-heading">
            Displays of {currentBusiness.businessName}
          </h6>
        </Col>
        <Col align="right" md={6} className="add-layout-search-container">
          <div className="search-input-container-lay">
            <AiOutlineSearch style={{ fontSize: "23px" }} />
            <input
              type="text"
              className="search-input"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <CustomButton
            disabledButton={false}
            btnType="button"
            BtnClassName="Filter-button"
            ClickEvent={() => setPlusModal(true)}
            BtnTxt={<> Add</>}
          />
        </Col>
      </Row>
      <div className="search-container mt-3">
        <CustomDropDown items={items} text={selectedLocation || "Location"} />

        {selectedLocation ? (
          <Button
            style={{ marginLeft: "10px" }}
            className="clear-filter-btn"
            onClick={clearLocationFilter}
          >
            X
          </Button>
        ) : null}
        <CustomDropDown
          filterMenu={StatusFilterMenu}
          selectedStatus={selectedStatus}
          text={
            selectedStatus === "1"
              ? "Active"
              : selectedStatus === "2"
              ? "Inactive"
              : "Status"
          }
        />

        {selectedStatus ? (
          <Button
            style={{ marginLeft: "10px" }}
            className="clear-filter-btn"
            onClick={clearStatusFilter}
          >
            X
          </Button>
        ) : null}
        <Button
          className="default tw-flex tw-items-center poppins-font"
          onClick={handleListView}
          style={{ background: viewType === "list" ? "#DEEBFF" : "" }}
        >
          <FaList style={{ marginRight: "5px" }} /> List View
        </Button>
        <Button
          className="default tw-flex tw-items-center poppins-font"
          onClick={handleGridView}
          style={{ background: viewType === "grid" ? "#DEEBFF" : "" }}
        >
          <BsGrid style={{ marginRight: "5px" }} /> Grid View
        </Button>
      </div>

      <hr className="horizontalline" />
      {isFetchingData ? (
        <div className="loader"></div>
      ) : filteredDisplays.length === 0 ? (
        <DisplaysNegativeScreen />
      ) : (
        <>
          {viewType === "list" ? (
            <div>
              <CustomDataTable
                columns={columns}
                data={filteredDisplays}
                tableKey="displaysTable"
              />
            </div>
          ) : (
            <div className="images-list-container">
              <Row>
                <div className="tw-grid tw-grid-cols-3 tw-sm:grid-cols-2 tw-md:grid-cols-3 tw-lg:grid-cols-3 tw-gap-8">
                  {searchFilter.map((display) => (
                    <Col key={display.id}>
                      <Card
                        className=" tw-cursor-pointer"
                        style={{
                          width: "370px",
                          height: "330px",
                          overflow: "hidden",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                          marginBottom: "16px",
                        }}
                        onClick={() => handleShowDisplayPreview(display, true)}
                      >
                        <div
                          className="name"
                          style={{
                            display: "flex",
                            fontFamily: "poppins",
                            fontWeight: "500",
                            margin: "5px",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{ marginRight: "8px", fontSize: "16px" }}
                            >
                              {display.displayName}
                            </span>
                            {/* <AiOutlineEdit
                              style={{
                                fontSize: "19px",
                                cursor: "pointer",
                                color: "#666666",
                              }}
                             
                            /> */}
                          </div>
                          <Switch
                            className="tw-bg-switch-bg tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-disabled:opacity-50"
                            checked={display.status === "1"}
                            onClick={() =>
                              handleShowSwitchModal(display, false)
                            }
                          />
                        </div>
                        <div style={{ position: "relative" }}>
                          <img
                            alt="example"
                            src={
                              screenshotImages.find(
                                (img) => img.id === display.id
                              )?.url || imageUrl
                            }
                            style={{ width: "100%" }}
                          />

                          <div
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          >
                            <Tooltip
                              title={display.health}
                              color={display.color || "red"}
                            >
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  backgroundColor: display.color || "red",
                                }}
                              ></span>
                            </Tooltip>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "10px 0",
                            fontFamily: "poppins",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            color: "#666666",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <PiGoogleChromeLogoThin
                              style={{ fontSize: "24px", marginRight: "8px" }}
                            />
                            <span
                              style={{
                                display: "inline-block",
                                width: "22ch",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {display.displayBrand}
                            </span>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <IoLocationOutline
                              style={{ fontSize: "24px", marginRight: "8px" }}
                            />
                            <span style={{ display: "inline-block" }}>
                              {display.location}
                            </span>
                          </div>
                          {/* <div>
                          <span style={{ display: "inline-block" }}>
                            Version - {display.baseOS}
                          </span>
                        </div> */}
                        </div>
                      </Card>
                    </Col>
                  ))}
                </div>
              </Row>
            </div>
          )}
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          margin: "20px",
        }}
      >
        {viewType === "grid" && (
          <Pagination
            current={currentPage}
            total={displayArr.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
          />
        )}
      </div>

      <ViewDisplay
        data={viewData}
        disableField={visible}
        showViewDisplay={showViewDisplay}
        handleCloseViewDisplay={handleCloseViewDisplay}
      />
      <Modal
        show={showPlusModal}
        onHide={handlePlusClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div style={{ width: "100%" }}>
            <h6 style={{ margin: 0, alignItems: "center" }}>
              Add a new Display
            </h6>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <input
                className="form-control"
                type="text"
                placeholder="Display Name"
                required
                name="displayName"
                value={formData.displayName}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Registration Code"
                required
                name="RegistrationCode"
                value={formData.RegistrationCode}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="modal-footer" style={{ background: "#fff" }}>
          <CustomButton
            disabledButton={false}
            ClickEvent={() => {
              handleAddDisplays();
              setPlusModal(false);
            }}
            BtnClassName="Filter-button"
            btnType="submit"
            BtnTxt={<>Submit</>}
          />
        </Modal.Footer>
      </Modal>
      {/* Confirmation modal */}
      <Modal
        size="sm"
        animation
        show={showSwitchModal}
        onHide={handleShowSwitchModal}
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        backdrop="static"
      >
        <Modal.Header>
          <div style={{ width: "100%" }}>
            <h4 style={{ fontSize: "16px", textAlign: "center" }}>
              {modalMessage}
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="">
            <Col style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                BtnTxt={<>Yes</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => {
                  if (modalMessage === "Do you want to deactivate it ?") {
                    handleDeactiveDisplay(displayId);
                  } else if (modalMessage === "Do you want to activate it ?") {
                    handleactiveDisplay(displayId);
                  }
                  setSwitchModal(false);
                }}
              />
              <CustomButton
                BtnTxt={<>No</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => setSwitchModal(false)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Toaster position="bottom-right" className="tw-mr-4" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  AllTokens: state.allTokensReducer,
  userId: state.userProfile.userId,
  refreshToken: state.RefreshToken,
  userProfile: state.userProfile,
  currentBusiness: state.currentBusiness,
  sellerId: state.sellerIdReducer,
  ...state,
});

export default connect(mapStateToProps)(Displays);
