import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../components/apiClient";

const useFetchMedia = (accessToken, sellerId, fileType, searchTerm) => {
  return useQuery({
    queryKey: ["media", sellerId, fileType, searchTerm],
    queryFn: async () => {
      const client = apiClient(accessToken);
      let endpoint = `library/mediaretrieveall/${sellerId}`;

      if (searchTerm) {
        endpoint = `library/search/${sellerId}`;
      }

      const response = await client.get(endpoint, {
        params: {
          fileType,
          filename: searchTerm,
        },
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Invalid response format");
      }
    },
    refetchOnWindowFocus: false,
    staleTime: 1 * 60 * 60 * 1000, // 1 hour
  });
};

export const useFetchImages = (accessToken, sellerId, searchTerm) => {
  return useFetchMedia(accessToken, sellerId, 1, searchTerm);
};

export const useFetchVideos = (accessToken, sellerId, searchTerm) => {
  return useFetchMedia(accessToken, sellerId, 2, searchTerm);
};
