import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie"; // Import js-cookie
import OriginalDataTable from "react-data-table-component";
import tableStyle from "./Datatablestyle";
import ResizeIcon from "../assets/resize.svg";
export default function CustomDataTable({ columns = [], data = [], tableKey }) {
  // Initialize state based on saved cookie data or default column widths
  const [columnWidths, setColumnWidths] = useState(() => {
    const savedWidths = Cookies.get(`columnWidths_${tableKey}`);
    return savedWidths
      ? JSON.parse(savedWidths)
      : (columns || []).map((col) => col.width || 200);
  });

  const [hoveredColumn, setHoveredColumn] = useState(null);

  const tableRef = useRef(null);

  useEffect(() => {
    const adjustColumnWidths = () => {
      if (tableRef.current) {
        const containerWidth = tableRef.current.offsetWidth;
        const totalInitialWidth = columnWidths.reduce(
          (acc, width) => acc + width,
          0
        );

        if (totalInitialWidth < containerWidth) {
          const extraWidth = containerWidth - totalInitialWidth;
          const newWidths = columnWidths.map(
            (width) => width + extraWidth / columnWidths.length
          );
          setColumnWidths(newWidths);
        }
      }
    };

    adjustColumnWidths();

    window.addEventListener("resize", adjustColumnWidths);
    return () => window.removeEventListener("resize", adjustColumnWidths);
  }, [columnWidths]);

  const handleResize = (index, newWidth) => {
    setColumnWidths((prevWidths) => {
      const updatedWidths = [...prevWidths];
      updatedWidths[index] = Math.max(newWidth, 50); // Ensure minimum width of 50px

      // Save column widths in cookies, scoped by the unique tableKey
      Cookies.set(`columnWidths_${tableKey}`, JSON.stringify(updatedWidths));

      return updatedWidths;
    });
  };

  const renderHeader = (col, index) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        position: "relative",
        boxSizing: "border-box",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
      onMouseEnter={() => setHoveredColumn(index)}
      onMouseLeave={() => setHoveredColumn(null)}
    >
      <span
        style={{
          flex: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
        }}
      >
        {col.name}
      </span>
      {hoveredColumn === index && (
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            width: "16px",
            cursor: "col-resize",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onMouseDown={(e) => initResize(e, index)}
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src={ResizeIcon}
            alt="Resize"
            style={{ width: "12px", height: "12px" }}
          />
        </div>
      )}
    </div>
  );

  // const renderHeader = (col, index) => (
  //   <div
  //     style={{
  //       display: "flex",
  //       width: "100%",
  //       position: "relative",
  //       boxSizing: "border-box",
  //       paddingLeft: "16px",
  //       paddingRight: "16px",
  //       border: "1px solid pink",
  //     }}
  //   >
  //     <span
  //       style={{
  //         flex: 1,
  //         overflow: "hidden",
  //         textOverflow: "ellipsis",
  //         whiteSpace: "nowrap",
  //         border: "1px solid blue",
  //       }}
  //     >
  //       {col.name}
  //     </span>
  //     <div
  //       style={{
  //         position: "absolute",
  //         right: 0,
  //         top: 0,
  //         bottom: 0,
  //         width: "8px",
  //         cursor: "col-resize",
  //         zIndex: 1, // Ensure the resize handle is above other elements
  //       }}
  //       onMouseDown={(e) => initResize(e, index)}
  //       onClick={(e) => e.stopPropagation()} // Prevent sorting when clicking on the resize handle
  //     />
  //   </div>
  // );

  const initResize = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    const startX = e.clientX;
    const startWidth = columnWidths[index];

    const doDrag = (dragEvent) => {
      dragEvent.preventDefault();
      const newWidth = startWidth + (dragEvent.clientX - startX);
      handleResize(index, newWidth);
    };

    const stopDrag = () => {
      document.removeEventListener("mousemove", doDrag);
      document.removeEventListener("mouseup", stopDrag);
    };

    document.addEventListener("mousemove", doDrag);
    document.addEventListener("mouseup", stopDrag);
  };

  const resizedColumns = columns.map((col, index) => ({
    ...col,
    name: renderHeader(col, index),
    width: `${columnWidths[index]}px`,
    style: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  }));

  return (
    <div ref={tableRef} className="all-table-container">
      {Array.isArray(data) &&
        data.length > 0 &&
        Array.isArray(columns) &&
        columns.length > 0 && (
          <OriginalDataTable
            title=""
            columns={resizedColumns}
            data={data}
            pagination
            customStyles={tableStyle}
            paginationRowsPerPageOptions={[5, 10, 15]}
            paginationPerPage={10}
          />
        )}
    </div>
  );
}
