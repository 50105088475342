export const Routes = {
  NotFound: "/404",
  Dashboard: "/",
  Signup: "/register",
  ForgotPassword: "/forgot-password",
  Dashboard: "/dashboard",
  UserProfile: "/users",
  Library: "/library",
  Layouts: "/layouts",
  Displays: "/displays",
  Layoutviews: "/layouts-view",
  Location: "/location",
  DisplayView: "/displayPreview/:id",
  AddDisplay: "/addDisplay",
};

