import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { Toaster, toast } from "sonner";
import { Authenticator, defaultTheme } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { useRef } from "react";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { useAuthenticator, Button, View } from "@aws-amplify/ui-react";
import { CheckboxField } from "@aws-amplify/ui-react";
import { Modal } from "antd";
import { useTheme } from "@aws-amplify/ui-react";
import logo from "./assets/logo192.png";
import { useLocation, useNavigate } from "react-router-dom";

import { Bars } from "react-loader-spinner";
import React, { useState, useEffect } from "react";
import {
  getCurrentUser,
  fetchAuthSession,
  AuthTokens,
} from "@aws-amplify/auth";
import "@aws-amplify/ui-react/styles.css";
import { useDispatch } from "react-redux";
import { apiClient } from "./components/apiClient";
import "react-toastify/dist/ReactToastify.css";
import { userRoutes, login, accessToken, userProfile } from "./redux/action";
import store from "../src/redux/store";
import Main from "./Main";
import Cookies from "js-cookie";
import { Route, Routes } from "react-router-dom";
import TermsandCondition from "./pages/termsandconditon";

const commonFieldStyles = {
  width: "280px",
  border: "1px solid #ccc",
  borderRadius: "5px",
  padding: "10px",
  margin: "0px",
};

const fieldsetStyles = {
  border: "1px solid #333",
  borderRadius: "10px",
  padding: "20px",
  margin: "20px",
};

const components = {
  SignIn: {
    Header() {
      return <h1 style={{ textAlign: "center" }}></h1>;
    },
    Footer() {
      const { toSignUp } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignUp}
            borderColor="blue"
            maxWidth="88%"
            className="amplify-button--fullwidth bgbtn"
            variation="link"
          >
            Sign Up
          </Button>
        </View>
      );
    },
    formSection: {
      style: fieldsetStyles,
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();
      return <></>;
    },
    formSection: {
      style: fieldsetStyles,
    },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your email",
    },
    password: {
      placeholder: "Enter your password",
    },
  },
  signUp: {
    email: {
      placeholder: "Email",
      order: 1,
    },
    password: {
      placeholder: "Password:",
      isRequired: false,
      order: 6,
    },
    confirm_password: {
      placeholder: "Confirm Password",
      order: 7,
    },
    address: {
      label: "Address",
      placeholder: "Address",
      order: 5,
    },
    zoneinfo: {
      label: "Country",
      placeholder: "Country",
      order: 4,
    },
    name: {
      label: "Name",
      placeholder: "Name",
      order: 2,
    },
    given_name: {
      label: "Business Name",
      placeholder: "Business Name",
      order: 3,
    },
  },
};
const myTheme = {
  ...defaultTheme, // Use the default theme as a starting point
  signInButton: {
    backgroundColor: "#4caf50",
    color: "white",
  },
  signUpButton: {
    backgroundColor: "#2196f3",
    color: "white",
  },
  confirmSignUpButton: {
    backgroundColor: "#ff9800",
    color: "white",
  },
};
const authConfig = {
  cookieStorage: {
    expires: 1,
    secure: false,
  },
  Cognito: {
    userPoolId: process.env.REACT_APP_AMPLIFY_USERPOOL_ID,
    userPoolClientId: process.env.REACT_APP_AMPLIFY_WEBCLIENT_ID,
  },
};
Amplify.configure({
  Auth: authConfig,
});

const resukt = cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage()
);

function App() {
  const intervalRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const errorMessage = params.get("autherror");
  console.log("errorMessage", errorMessage);

  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [hasCleared, setHasCleared] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      // Reload the entire app
      window.location.reload();
    }, 2 * 60 * 60 * 1000);
  };

  useEffect(() => {
    resetInterval();

    // Reset the interval whenever the window is opened
    window.addEventListener("focus", resetInterval);

    // Clear the interval and remove the event listener when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      window.removeEventListener("focus", resetInterval);
    };
  }, []);
  useEffect(() => {
    document.cookie =
      "cookie=cookie; domain=.ewalls.io; path=/; secure; SameSite=None";
  }, []);
  const dispatch = useDispatch();
  const [authState, setAuthState] = useState("initial");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const { route } = useAuthenticator((context) => [context.route]);
  const [stateauth, setStateauth] = useState("initial");
  const [showSignUpForm, setShowSignUpForm] = useState(false);

  // useEffect(() => {
  //   if (route === "authenticated" && !hasCleared) {
  //     // Clear the URL parameters only if they haven't been cleared yet
  //     navigate(location.pathname, { replace: true });
  //     setHasCleared(true);
  //   }
  // }, [route, navigate, location, hasCleared]);

  const retrieveAllCookies = () => {
    const allCookies = Cookies.get();

    // If you need to decode the values, you can map over the entries
    const decodedCookies = Object.fromEntries(
      Object.entries(allCookies).map(([key, value]) => [
        key,
        decodeURIComponent(value),
      ])
    );

    //console.log("All cookies:", decodedCookies);

    return decodedCookies;
  };

  const curentautthuser = async () => {
    try {
      const currentUser = await getCurrentUser();

      const tokensss = await fetchAuthSession();

      const accessTokenJWT = tokensss.tokens.accessToken.payload;
      const idTokenJWT = tokensss.tokens.idToken.payload;

      function base64UrlEncode(str) {
        return btoa(str)
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/=+$/, "");
      }

      // console.log("Decoded AccessToken:", accessTokenJWT);
      // console.log("Decoded IdToken:", idTokenJWT);

      const accessTokenJWTString = `${base64UrlEncode(
        JSON.stringify(tokensss.tokens.idToken.payload)
      )}`;
      const idTokenJWTString = `${base64UrlEncode(
        JSON.stringify(tokensss.tokens.idToken.header)
      )}.${base64UrlEncode(JSON.stringify(tokensss.tokens.idToken.payload))}.${
        tokensss.tokens.idToken.signature
      }`;

      // console.log("AccessToken JWT String:", accessTokenJWTString);
      // console.log("IdToken JWT String:", idTokenJWTString);

      dispatch(accessToken(accessTokenJWTString));
      setUser(currentUser);
      dispatch(login(true));
    } catch (error) {
      console.error("Error fetching current user:", error);
      dispatch(login(false));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    curentautthuser();
  }, [authState]);

  const handleStateChange = (authState, data) => {
    setAuthState(authState);
    if (authState === "signIn") {
      setShowSignUpForm(false);
    }
  };

  const handleToggleSignUpForm = () => {
    setShowSignUpForm(!showSignUpForm);
  };
  console.log(
    "process.env.AMPLIFY_USERPOOL_ID",
    process.env.AMPLIFY_USERPOOL_ID
  );
  return (
    <>
      {location.pathname === "/terms-and-conditions" ? (
        <>
          <Routes>
            <Route
              path="/terms-and-conditions"
              element={<TermsandCondition />}
            />
          </Routes>
        </>
      ) : (
        <>
          {route === "authenticated" ? (
            <Provider store={store}>
              <Main currentAuthenticatedUser={curentautthuser} />
              <Toaster position="bottom-right" className="tw-mr-4" />
            </Provider>
          ) : (
            <div style={{ display: "flex", height: "100vh", width: "100%" }}>
              <div
                className="loginbgpic"
                style={{
                  flex: "1 1 50%",
                  backgroundImage:
                    'url("https://res.cloudinary.com/dvdlyjgf2/image/upload/v1703661128/FA45B89F-CE87-4057-8537-AB9024E6358B_1_201_a_v2m6zi.jpg")',
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "80px", height: "80px" }}
                />
                <h1 style={{ color: "white" }}>eWALLS</h1>
              </div>

              <div
                style={{
                  flex: "1 1 50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="sing-up-res"
                  style={{
                    display: "flex",
                    paddingTop: showSignUpForm ? "177px" : "",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                    margin: "0 auto",
                    overflow: "auto",
                  }}
                >
                  <Authenticator
                    formFields={formFields}
                    theme={myTheme}
                    components={{
                      SignIn: {
                        Header() {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "red",
                              }}
                            >
                              {errorMessage ? errorMessage : ""}
                            </div>
                          );
                        },
                        Footer() {
                          const { toSignUp } = useAuthenticator();
                          const { toForgotPassword } = useAuthenticator();
                          return (
                            <View textAlign="center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  padding: "0 32px",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: "-25px",
                                    color: "purple",
                                  }}
                                >
                                  <CheckboxField
                                    value="yes"
                                    label="Remember me"
                                  />
                                </div>
                                <p
                                  style={{
                                    marginTop: "-10px",
                                    color: "purple",
                                  }}
                                  onClick={toForgotPassword}
                                >
                                  Forget Password ?
                                </p>
                                {/* <Button
                            style={{ marginBottom: "10px"}}
                            fontWeight="normal"
                            className="amplify-button--fullwidth bgbtn"
                            onClick={toForgotPassword}
                            variation="link"
                          >
                            Forgett Password
                          </Button> */}
                              </div>

                              <Button
                                fontWeight="normal"
                                onClick={() => {
                                  toSignUp();
                                  handleToggleSignUpForm();
                                }}
                                border="none"
                                // textDecoration={'underline'}
                                maxWidth="88%"
                                color="purple"
                                className="amplify-button--fullwidth bgbtn"
                                variation="link"
                              >
                                Don't have an account?
                                <span
                                  style={{
                                    color: "orange",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {" "}
                                  Sign Up
                                </span>
                              </Button>
                            </View>
                          );
                        },
                        formSection: {
                          style: fieldsetStyles,
                        },
                      },
                      SignUp: {
                        FormFields() {
                          const { validationErrors } = useAuthenticator();

                          return (
                            <>
                              <Authenticator.SignUp.FormFields />

                              <CheckboxField
                                errorMessage={validationErrors.acknowledgement}
                                hasError={!!validationErrors.acknowledgement}
                                name="acknowledgement"
                                value="yes"
                                label={
                                  <span>
                                    I agree to the{" "}
                                    <a
                                      href="terms-and-conditions"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Terms & Conditions
                                    </a>
                                    .
                                  </span>
                                }
                              />
                            </>
                          );
                        },
                        Footer() {
                          const { toSignIn } = useAuthenticator();
                          const { validationErrors } = useAuthenticator();
                          return (
                            <View textAlign="center">
                              <Button
                                fontWeight="normal"
                                onClick={() => {
                                  toSignIn();
                                  handleToggleSignUpForm();
                                }}
                                border="none"
                                maxWidth="88%"
                                className="amplify-button--fullwidth bgbtn"
                                variation="link"
                              >
                                Already have an account ?{" "}
                                <span
                                  style={{
                                    color: "orange",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Sign In
                                </span>
                              </Button>
                            </View>
                          );
                        },
                        Header() {
                          const { tokens } = useTheme();
                          return <></>;
                        },
                        formSection: {
                          style: fieldsetStyles,
                        },
                      },
                    }}
                    services={{
                      async validateCustomSignUp(formData) {
                        if (!formData.acknowledgement) {
                          return {
                            acknowledgement:
                              "You must agree to the Terms & Conditions",
                          };
                        }
                        const passwordRegex =
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!%*?&]{8,}$/;

                        if (!passwordRegex.test(formData.password)) {
                          return {
                            password:
                              "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, one number, and one symbol (@$!%*?&)",
                          };
                        }
                      },
                    }}
                    onStateChange={handleStateChange}
                    hideSignUp={!showSignUpForm}
                    initialState={stateauth}
                  >
                    {() => {
                      return loading ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Bars
                            height="40"
                            width="40"
                            color="#48cae4"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                      ) : null;
                    }}
                  </Authenticator>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default App;
