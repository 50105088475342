import React from "react";
import { Button } from "react-bootstrap";

const CustomButton = ({
  disabledButton,
  btnType,
  BtnClassName,
  ClickEvent,
  BtnTxt,
  color,
}) => {
  const buttonStyle = {
    fontFamily: "Poppins, sans-serif",
    color: color,
  };

  return (
    <Button
      disabled={disabledButton}
      type={btnType}
      className={BtnClassName}
      onClick={ClickEvent}
      style={buttonStyle}
    >
      {BtnTxt}
    </Button>
  );
};

export default CustomButton;
