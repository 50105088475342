import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import TextBox from "../../components/TextBox";
import CustomButton from "../../components/Button";
import { apiClient } from "../../components/apiClient";
import { connect } from "react-redux";
import { Toaster,toast } from "sonner";

const AddStore = ({ showAddStore, handleCloseAddStore, sellerId, state }) => {
  const logUserId = state.userProfile.userId;
  let [location, setLocation] = useState({
    name: "",
    address: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  let [errorList, setErrorList] = useState({
    name: "",
    address: "",
  });
  const [reqErr, setReqErr] = useState(false);
  const handleChange = (e) => {
    setLocation({ ...location, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let hasError = false;
    for (const key in location) {
      if (location[key].trim() == "") {
        errorList[key] = key + " is Required";
        hasError = true;
        setReqErr(true);
      } else {
        setErrorList({ ...errorList, [key]: false });
      }
    }
    if (!hasError) {
      try {
        setReqErr(false);
        let params = {
          name: location.name,
          address: location.address,
          userId: logUserId,
        };
        await apiClient(state.accessToken)
          .post(`store/register/${sellerId}`, params)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Location Added Successfully");
            }
            setIsLoading(false);
            resetField(true);
          })
          .catch((err) => {
            toast.error("Something went wrong");
          });
      } catch (err) {
        toast.error("Something went wrong");
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };
  const resetField = (val = null) => {
    setLocation({ name: "", address: "" });
    val ? handleCloseAddStore(true) : handleCloseAddStore(false);
  };
  return (
    <>
      <Modal
        show={showAddStore}
        onHide={resetField}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div style={{ width: "100%" }}>
          <Modal.Title style={{ fontFamily: "Poppins", fontWeight: "600" }}>
          Add Location
        </Modal.Title>
          </div>
        </Modal.Header>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <TextBox
              txtBoxLabel={"Name"}
              txtBoxType={"text"}
              txtBoxPH={"Name"}
              txtBoxValue={location.name}
              changeEvent={handleChange}
              txtBoxName={"name"}
            />
            {errorList.name && <span className="error">{errorList.name}</span>}
            <TextBox
              txtBoxLabel={"Address"}
              txtAs={"textarea"}
              txtBoxType={"textarea"}
              txtBoxPH={"Address"}
              txtBoxValue={location.address}
              changeEvent={handleChange}
              txtBoxName={"address"}
            />
            {errorList.address && (
              <span className="error">{errorList.address}</span>
            )}
          </Modal.Body>
          <Modal.Footer style={{background:"#fff"}}>
            <CustomButton
              disabledButton={isLoading}
              btnType={"submit"}
              BtnTxt={isLoading ? "Progress" : "Save"}
              BtnClassName="Filter-button"
            />
          </Modal.Footer>
          <Toaster position="bottom-right" className="tw-mr-4"  />
        </form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sellerId: state.sellerIdReducer,
    state: state,
  };
};

export default connect(mapStateToProps)(AddStore);
