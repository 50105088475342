import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import { PiClockCountdownBold } from "react-icons/pi";
import { apiClient } from "../../components/apiClient";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const SaveLayoutModal = React.memo(
  ({ show, onHide, droppedItems, layoutId, accessToken }) => {
    console.log("droppedItems", droppedItems);
    const [loading, setLoading] = useState(false);
    const [lastSavedDuration, setLastSavedDuration] = useState(null);
    const [durations, setDurations] = useState({});
    const [itemDurations, setItemDurations] = useState(() => {
      const initialItemDurations = {};
      droppedItems.forEach((item, index) => {
        const initialMinutes = item.duration
          ? Math.floor(item.duration / 60)
              .toString()
              .padStart(2, "0")
          : "00";
        const initialSeconds = item.duration
          ? (item.duration % 60).toString().padStart(2, "0")
          : "00";
        // Use a unique identifier for each item in the durations state
        initialItemDurations[`${item.id}_${index}`] = {
          minutes: initialMinutes,
          seconds: initialSeconds,
        };
      });
      return initialItemDurations;
    });

    useEffect(() => {
      setDurations(itemDurations);
    }, [itemDurations]);

    const handleDurationChange = (itemId, key, value) => {
      setDurations((prevDurations) => ({
        ...prevDurations,
        [itemId]: {
          ...prevDurations[itemId],
          [key]: value,
        },
      }));
    };
    const handleSave = async () => {
      try {
        setLoading(true);

        const hasInvalidDuration = droppedItems.some((item) => {
          return (
            durations[
              `${item.id}_${droppedItems.findIndex((el) => el.id === item.id)}`
            ].minutes === "00" &&
            durations[
              `${item.id}_${droppedItems.findIndex((el) => el.id === item.id)}`
            ].seconds === "00"
          );
        });

        if (hasInvalidDuration) {
          console.error("Duration should be required");
          toast.error("Duration should be required");
          return;
        }

        const libraryIds = droppedItems.map((item) => {
          const index = droppedItems.findIndex((el) => el.id === item.id);
          const minutes =
            parseInt(durations[`${item.id}_${index}`].minutes, 10) || 0;
          const seconds =
            parseInt(durations[`${item.id}_${index}`].seconds, 10) || 0;

          return {
            id: item.id,
            duration: minutes * 60 + seconds,
            fileUrl: item.fileUrl,
            mediaType: item.mediaType,
            isMuted: item.muted,
          };
        });

        const client = apiClient(accessToken);

        const response = await client.post("layoutModule/register", {
          layoutId,
          libraryIds,
        });

        if (response.status === 200) {
          toast.success("Layout saved successfully");
          window.location.reload();
          setLastSavedDuration(JSON.stringify(itemDurations));
          onHide();
        } else {
          toast.error("Error saving layout. Please try again.");
        }
      } catch (error) {
        console.error("Error saving layout:", error);
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        show={show}
        onHide={() => {
          setLoading(false);
          onHide();
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontFamily: "Poppins", fontWeight: "600" }}>
            Save Layout
          </Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body className="p-4 d-flex justify-content-center flex-wrap">
          {droppedItems.map((item, index) => (
            <Row key={item.id} lg={2} className="mb-3 border p-4">
              <Col className="w-100">
                {item.mediaType === "video" ? (
                  <video
                    className="media-item"
                    width="100%"
                    height="100%"
                    controls
                    muted={item.muted}
                    onLoadedMetadata={(event) => {
                      const duration = Math.round(event.target.duration);
                      const formattedMinutes = Math.floor(duration / 60)
                        .toString()
                        .padStart(2, "0");
                      const formattedSeconds = (duration % 60)
                        .toString()
                        .padStart(2, "0");

                      setItemDurations((prevItemDurations) => ({
                        ...prevItemDurations,
                        [`${item.id}_${index}`]: {
                          minutes: formattedMinutes,
                          seconds: formattedSeconds,
                        },
                      }));
                    }}
                  >
                    <source
                      src={item.fileUrl}
                      alt={item.fileName}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    className="media-item"
                    src={item.fileUrl}
                    alt={item.name}
                  />
                )}
                <div>
                  <PiClockCountdownBold />
                  {`${durations[`${item.id}_${index}`]?.minutes ?? "00"}:${
                    durations[`${item.id}_${index}`]?.seconds ?? "00"
                  }`}

                  {item.mediaType !== "video" && (
                    <Form.Group controlId={`durationInput-${item.id}`}>
                      <Form.Label>Time Duration:</Form.Label>
                      <Row>
                        <Col>
                          <Form.Label>Minutes:</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Minutes"
                            min="0"
                            max="59"
                            value={
                              durations[`${item.id}_${index}`]?.minutes ?? "00"
                            }
                            onChange={(e) => {
                              const minutes = Math.min(
                                Math.max(0, parseInt(e.target.value) || 0),
                                59
                              );
                              handleDurationChange(
                                `${item.id}_${index}`,
                                "minutes",
                                minutes.toString().padStart(2, "0")
                              );
                            }}
                          />
                        </Col>
                        <Col>
                          <Form.Label>Seconds:</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Seconds"
                            min="0"
                            max="59"
                            value={
                              durations[`${item.id}_${index}`]?.seconds ?? "00"
                            }
                            onChange={(e) => {
                              const seconds = Math.min(
                                Math.max(0, parseInt(e.target.value) || 0),
                                59
                              );
                              handleDurationChange(
                                `${item.id}_${index}`,
                                "seconds",
                                seconds.toString().padStart(2, "0")
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  )}
                </div>
              </Col>
            </Row>
          ))}
          {lastSavedDuration && (
            <div className="mb-3">
              <strong>Last Saved Duration:</strong> {lastSavedDuration}
            </div>
          )}
          {loading && (
            <div className="overlay">
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ background: "#fff" }}>
          <Button
            variant="light"
            className="cancell-button"
            onClick={() => {
              setLoading(false);
              onHide();
            }}
            style={{
              fontFamily: "Poppins",
              borderRadius: "5px",
              backgroundColor: "#939393",
              color: "#F5F7F8",
            }}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="add-layout-button"
            onClick={handleSave}
            style={{ fontFamily: "Poppins", borderRadius: "5px" }}
          >
            Save Layout
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default SaveLayoutModal;
