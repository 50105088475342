import React from "react";

export default function DisplaysNegativeScreen() {
  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-[75vh] tw-w-full tw-overflow-hidden">
      <img
        src="/NegativeScreens/Displays.png"
        alt="please check your network!"
        className="tw-w-[30rem]"
      />

      <a
        href="https://play.google.com/store/apps/details?id=com.p8io.iristv&hl=en_IN"
        target="_blank"
        className="tw-mt-8"
      >
        <img
          src="/NegativeScreens/Playstore.svg"
          alt="please check your network!"
        />
      </a>
    </div>
  );
}
