import React, { useState, useEffect } from "react";
import { Button, Drawer, Avatar, List, Space } from "antd";
import { PiBellBold } from "react-icons/pi";
import DOMPurify from "dompurify";
import { UserapiClient } from "../../components/userManagementApiclient";
import { connect } from "react-redux";
import { accessToken } from "../../redux/action";
import { toast } from "sonner";

const Notification = ({ userProfile, currentBusiness, accessToken }) => {
  console.log("accessToken", accessToken);
  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (currentBusiness) {
      fetchNotifications();
    }
  }, [currentBusiness]);

  const fetchNotifications = async () => {
    const UserClient = UserapiClient(accessToken);
    if (!currentBusiness) {
      console.error("currentBusiness is null");
      return;
    }
    const { businessId, sellerId, userId } = currentBusiness;
    console.log("Fetching notifications for:", {
      businessId,
      sellerId,
      userId,
    });

    try {
      const response = await UserClient.get(
        `/notification/${businessId}/${userId}/${sellerId}`
      );
      console.log("API response:", JSON.stringify(response.data, null, 2));

      if (response?.data?.Items && response.data.Items.length > 0) {
        const data = response.data.Items[0].notification.L.map((item) => {
          console.log("Processing item:", JSON.stringify(item, null, 2));
          const id = item.M.id?.S || "Unknown ID";
          const message = item.M.message?.S || "No message available";
          let date = "Unknown date";

          if (item.M.date && item.M.date.S) {
            // Use the date from the API response if available
            date = new Date(item.M.date.S).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
          } else {
            // Fallback to using the ID if date is not available
            try {
              const timestamp = parseInt(id.split("_")[1]);
              if (!isNaN(timestamp)) {
                date = new Date(timestamp).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                });
              }
            } catch (error) {
              console.error("Error parsing date:", error);
            }
          }

          return {
            id,
            message,
            date,
            user: {
              name: item.M.title?.S || "Unknown title",
              avatar:
                "https://s3.amazonaws.com/creativetim_bucket/photos/264729/thumb.jpeg?1620116551",
            },
            isRead: item.M.isRead?.BOOL || false,
          };
        });
        console.log(
          "Processed notifications data:",
          JSON.stringify(data, null, 2)
        );
        setNotifications(data);
      } else {
        console.log("No notifications found in the response");
        setNotifications([]);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      toast.error("Failed to fetch notifications");
    }
  };

  const markAsRead = async (notificationId) => {
    console.log("Marking notification as read:", notificationId);
    const UserClient = UserapiClient(accessToken);
    const { businessId } = currentBusiness;
    try {
      await UserClient.put("/mark-multiple-as-read", {
        businessId,
        notificationIds: [notificationId],
      });
      toast.success("Notification marked as read");
      fetchNotifications(); // Refresh notifications after marking as read
    } catch (error) {
      console.error("Error marking notification as read:", error);
      toast.error("Failed to mark notification as read");
    }
  };
  console.log("notifications", notifications);
  const markAllAsRead = async () => {
    const UserClient = UserapiClient(accessToken);
    const { businessId } = currentBusiness;
    const notificationIds = notifications.map(
      (notification) => notification.id
    );
    try {
      await UserClient.put("/mark-multiple-as-read", {
        businessId,
        notificationIds,
      });
      toast.success("All notifications marked as read");
      fetchNotifications(); // Refresh notifications after marking all as read
      onClose();
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
      toast.error("Failed to mark all notifications as read");
    }
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const parseMessage = (item) => {
    const message = item.message;
    if (!message) {
      console.log("Empty message received");
      return "No message available";
    }
    console.log("Parsing message:", message);
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(message, "text/html");
      const link = doc.querySelector("a");

      if (link) {
        const href = link.getAttribute("href");
        const displayName = link.textContent || "Link";
        const textParts = message.split(link.outerHTML);

        console.log("Parsed message parts:", { href, displayName, textParts });

        return (
          <>
            {textParts[0]}
            <a
              onClick={() => markAsRead(item.id)}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {displayName}
            </a>
            {textParts[1]}
          </>
        );
      }
    } catch (error) {
      console.error("Error parsing message:", error);
    }

    return message;
  };

  useEffect(() => {
    fetchNotifications();
    parseMessage(
      '<a href="https://www.google.com">Google</a> is a search engine'
    );
  }, [userProfile, currentBusiness]);
  return (
    <>
      <button onClick={showDrawer} className="tw-border-none tw-bg-transparent">
        <PiBellBold className="tw-w-6 tw-h-6 tw-text-white tw-transition-colors tw-duration-300 tw-cursor-pointer" />
      </button>
      <Drawer
        title="Notifications"
        onClose={onClose}
        visible={open}
        extra={
          <Space>
            <Button onClick={markAllAsRead}>Clear All</Button>
            {/* <Button type="primary" onClick={onClose}>
            OK
          </Button> */}
          </Space>
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={notifications}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.user.avatar} />}
                title={<a href="#">{item.user.name}</a>}
                description={
                  <>
                    <div>{parseMessage(item)}</div>

                    <div className="tw-flex tw-justify-between">
                      <div
                        style={{
                          marginTop: "8px",
                          color: "rgba(0, 0, 0, 0.45)",
                        }}
                      >
                        {item?.date}
                      </div>
                      <div
                        style={{
                          marginTop: "8px",
                          color: "rgba(0, 0, 0, 0.45)",
                        }}
                        className="tw-border tw-border-black px-2 tw-cursor-pointer"
                        onClick={() => markAsRead(item.id)}
                      >
                        Clear
                      </div>
                    </div>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.userProfile,
  currentBusiness: state.currentBusiness,
  accessToken: state.accessToken,
  ...state,
});
export default connect(mapStateToProps)(Notification);
