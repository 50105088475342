import React from "react";
import Modal from "react-bootstrap/Modal";

import { DateFormat } from "../../components/DateFormat";
import {  BsFillPencilFill } from "react-icons/bs";
import { connect } from "react-redux";
import {

  AiFillPlusCircle,

} from "react-icons/ai";
import CustomButton from "../../components/Button";
import { PiSuitcaseLight } from "react-icons/pi";
import { RiDeleteBin2Line } from "react-icons/ri";

const ViewDisply = ({
  data,
  disableField,
  showViewDisplay,
  handleCloseViewDisplay,
  state,
  handleShow,
}) => {
  const { appVersion, id, screenShotPath, createdAt } = data;

  const resetField = (val = null) => {
    val ? handleCloseViewDisplay(true) : handleCloseViewDisplay();
  };

  return (
    <Modal
      className="custom-modal-size"
      centered
      show={showViewDisplay}
      onHide={resetField}
    >
      {/* <Modal.Header >
          <Modal.Title class="">

            </Modal.Title>
        </Modal.Header> */}
      <Modal.Body className="p-4">
        <div className="cards-image-display">
          <div key={id} class="card" style={{ width: "100%" }}>
            <img
              className="image-display"
              src={
                !screenShotPath || screenShotPath.trim() === ""
                  ? "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.MGuLxSE-p-AKN_nhBa6QBQHaEW%26pid%3DApi&f=1&ipt=87aec32c6f248f5072647bf9b0899743a5fb8ad2086a168d980b692a2f66e0a6&ipo=images"
                  : screenShotPath
              }
              alt="..."
            />
            <div class="card-body">
              <div className="top-avatar-line">
                <img
                  className="avatar-display"
                  src={
                    "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.qEUnrJmoPAGeWGM2wDcjGQHaGX%26pid%3DApi&f=1&ipt=576a10d035424f8e01aaaacd17b3fcdefc81a2767ae6a2790361bc6f62397d2a&ipo=images"
                  }
                  alt=".."
                />
                <div>
                  <p class="fs-7 fw-bold">
                    {"Hamilton Screen 4 | Version"} <span>{appVersion}</span>
                  </p>
                  <p class="fs-7">{DateFormat(createdAt)}</p>
                </div>
              </div>
              <div>
                <p class="text-body-secondary two-line-clamp ">
                  {"The display is located at  "}{" "}
                  <a href={"#"} class=" text-decoration-underline highlight">
                    {"Texas Chicken Hamilton"}
                  </a>{" "}
                  {"and the last check-in took place about 20 minutes ago."}
                </p>
              </div>

              <p className="staus-tag">N/A</p>
              <hr />
              <div className="information-food">
                <h4 className="below-line-card-body">
                  Authorized <br />{" "}
                  <span className="light-color">Display state</span>
                </h4>
                <h4 className="below-line-card-body colorred">
                  Healthy <br />{" "}
                  <span className="light-color">Display Health</span>
                </h4>
                <h4 className="below-line-card-body">
                  Enabled <br />{" "}
                  <span className="light-color">Display Connectivity</span>
                </h4>
              </div>
              <div className="last-line">
                <div className="iconsbottom">
                  <BsFillPencilFill className="pencil" />
                  <PiSuitcaseLight className="suitcase" />
                  <RiDeleteBin2Line className="deletee" />
                </div>
                <CustomButton
                  disabledButton={false}
                  btnType="button"
                  BtnClassName="Assign-button"
                  ClickEvent={handleShow}
                  BtnTxt={<>ASSIGNMENTS</>}
                />
                <div>
                  <CustomButton
                    disabledButton={false}
                    BtnTxt={
                      <>
                        <AiFillPlusCircle class="plus-button" />{" "}
                      </>
                    }
                    BtnClassName="plus-btn"
                    btnType="button"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
         
        </Modal.Footer> */}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(ViewDisply);
