import { AiOutlineEdit } from "react-icons/ai";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Toaster,toast } from "sonner";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useState } from "react";
import { UserapiClient } from "../../components/userManagementApiclient";
import CustomButton from "../../components/Button";
// import { accessToken } from "../../redux/action";
const NewBusiness = ({ userProfile,  currentBusiness ,accessToken ,state}) => {
  const navigate = useNavigate();
 // console.log("userProfile", userProfile);
  const sellerId = userProfile?.["custom:sellerId"];
  const businessId = userProfile?.sub;
  const userId = userProfile?.sub;
  // const [previewUrls, setPreviewUrls] = useState([]);
  //console.log("sellerId", sellerId);
  const [fileUrl, setFilesUrl] = useState(null);

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileLocation, setFileLocation] = useState("");
  //console.log("filelocation", fileLocation);
  //console.log("llsss", file);
  const [formData, setFormData] = useState({
    businessName: "",
    businessType: "",
    email: currentBusiness.email || "",
    phone: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    abnNumber: "",
  });

  const handleCancel = () => {
    navigate(-1);
    
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const storedBusinessData = localStorage.getItem("getAllBusiness");
  const parsedBusinessData = storedBusinessData
  ? JSON.parse(storedBusinessData)
  : [];
  const existingBusiness = parsedBusinessData.find(
    (business) => business?.businessName === formData.businessName
  );
  console.log("storedBusinessData",existingBusiness)
  const handleSaveBusiness = async (e) => {
    e.preventDefault();
    if (!formData?.businessName || !formData?.email) {
      console.error("Required fields are missing.");
      setLoading(false);
      return;
    }
    const storedBusinesses = JSON.parse(localStorage.getItem('getAllBusiness')) || [];
      // Check if business name already exists
      const existingBusiness = storedBusinesses.find(business => business.businessName === formData.businessName);
      if (existingBusiness) {
        toast.error(`Business name "${formData.businessName}" already exists.`);
        setLoading(false);
        return;
      }
  

    try {
   

    
    
      const UserClient = UserapiClient(accessToken);
      let metadata = {};

      if (file) {
        const res = await UserClient.post("/generatePresignurl", {
          businessId: businessId,
          userId: userId,
          fileName: file.name,
          fileType: file.type,
        });

        //console.log("rrrr", res);
        if (res.status === 200) {
          const presignedUrls = res?.data;
          //console.log(file, "fffff");

          const formDatas = new FormData();
          const key = presignedUrls?.fields?.key;
          const filse = file;

          formDatas.append("key", key);
          formDatas.append("file", filse);

          const uploadResponse = await fetch(presignedUrls?.url, {
            method: "POST",
            body: formDatas,
          });

         // console.log("pree", uploadResponse);
          if (uploadResponse.status === 204) {
            metadata = {
              fileDetails: [
                {
                  fileName: file?.name,
                  fileType: file?.type,
                  fileSize: file?.size,
                  fileLocation: `${presignedUrls.url}/${encodeURIComponent(
                    key
                  )}`,
                },
              ],
            };

            setFileLocation(metadata.fileDetails[0].fileLocation);
          }
        }
      }

      const response = await UserClient.post("/registerBusiness", {
        sellerId: sellerId,
        fileLocation: metadata.fileDetails
          ? metadata.fileDetails[0].fileLocation
          : "",
        userId: userId,
        ...formData,
      });
      toast.success("Business created successfully");
      const updatedBusinesses = [...storedBusinesses, { ...formData, fileLocation }];
      localStorage.setItem('getAllBusiness', JSON.stringify(updatedBusinesses));
      //console.log("Business updated successfully!", response.data);
      navigate("/");
      window.location.reload();
    } catch (error) {
      toast.error("Error creating business");
      console.error("Error updating business:", error);
    }
  };

 // console.log("fileeee", file);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    //console.log("selecttt", selectedFile);
    if (selectedFile) {
      setFile(selectedFile);
      setFilesUrl(URL.createObjectURL(selectedFile));
    }
  };

  return (
    <div className=" tw-w-full  tw-overflow-y-scroll tw-py-3 tw-justify-center tw-items-center tw-bg-[#f3f3f3f0]">
      <div className="tw-w-full  tw-mt-2 tw-p-10 tw-space-y-8 ">
        <form
          onSubmit={handleSaveBusiness}
          className="tw-bg-white tw-rounded-xl tw-shadow-lg tw-w-full tw-h-4/5"
        >
          <div
            className="tw-px-16 tw-py-2 tw-xl:py-6 tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-bg-gradient-to-b tw-bg-blue-500 tw-relative"
            style={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}
          >
            <div>
              <p className="tw-text-2xl tw-font-semibold tw-text-white">
                Business Information
              </p>
              <p className="tw-text-sm tw-font-semibold tw-text-white">
                Details about your Business
              </p>
            </div>

            {/* <div className="tw-absolute tw-top-1/2 tw-mt-[-15] tw-right-0">
              <div className="tw-w-24 tw-h-24 tw-border-dashed tw-border-2 tw-flex tw-items-center tw-justify-center tw-border-[#E2E2E2] tw-rounded-md tw-bg-white tw-mr-6">
                <div className=" tw-flex tw-flex-col tw-items-center tw-justify-center">
                  <IoCloudUploadOutline className=" tw-text-center" />
                  <p className=" tw-text-xs"> Upload Image</p>
                </div>
              </div>
              <button className="tw-absolute tw-top-[-10px] tw-right-4 tw-p-1 tw-bg-white tw-rounded-full">
                <AiOutlineEdit className="tw-text-gray-600" />
              </button>
            </div> */}
            <div className="tw-absolute tw-top-1/2 tw-mt-[-15] tw-right-0">
              {/* <div className="tw-w-24 tw-h-24 tw-border-dashed tw-border-2 tw-flex tw-items-center tw-justify-center tw-border-[#E2E2E2] tw-rounded-md tw-bg-white tw-mr-6">
                <label
                  htmlFor="fileInput"
                  className="tw-flex tw-flex-col tw-items-center tw-justify-center"
                >
                  <IoCloudUploadOutline className="tw-text-center" />
                  <p className="tw-text-xs" style={{ cursor: "pointer" }}>
                    {" "}
                    Upload Image
                  </p>
                </label>
                <input
                  id="fileInput"
                  type="file"
                  // required
                  accept="image/*"
                  className="tw-hidden"
                  style={{ cursor: "pointer" }}
                  onChange={handleFileChange}
                />
              </div> */}
              { fileUrl !== null ? (
                <div
                  className="tw-w-24 tw-h-24 tw-border-solid tw-border-2 tw-flex tw-items-center tw-justify-center tw-border-[#ccc] tw-rounded-md tw-bg-white tw-mr-6"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${fileUrl})`,
                    backgroundSize: "cover",
                    backgroundBlendMode: "multiply",
                    color: "#fff",
                  }}
                >
                  <label
                    htmlFor="fileInput"
                    className="tw-flex tw-flex-col tw-items-center tw-justify-center"
                  >
                    <IoCloudUploadOutline className="tw-text-center" />
                    <p className="tw-text-xs" style={{ cursor: "pointer" }}>
                      {" "}
                      Upload Image
                    </p>
                  </label>

                  <input
                    id="fileInput"
                    type="file"
                    // required
                    accept="image/*"
                    className="tw-hidden"
                    style={{ cursor: "pointer" }}
                    onChange={(e) => handleFileChange(e)}
                  />
                </div>
              ) : (
                <div className="tw-w-24 tw-h-24 tw-border-dashed tw-border-2 tw-flex tw-items-center tw-justify-center tw-border-[#E2E2E2] tw-rounded-md tw-bg-white tw-mr-6">
                  <label
                    htmlFor="fileInput"
                    className="tw-flex tw-flex-col tw-items-center tw-justify-center"
                  >
                    <IoCloudUploadOutline className="tw-text-center" />
                    <p className="tw-text-xs" style={{ cursor: "pointer" }}>
                      {" "}
                      Upload Image
                    </p>
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    // required
                    accept="image/*"
                    className="tw-hidden"
                    style={{ cursor: "pointer" }}
                    onChange={(e) => handleFileChange(e)}
                  />
                </div>
              )}
              {/* <button className="tw-absolute tw-top-[-10px] tw-right-4 tw-p-1 tw-bg-white tw-rounded-full">
                <AiOutlineEdit className="tw-text-gray-600" />
              </button> */}
              {/* <p style={{ maxWidth: "200px" }}>{file?.fileName}</p> */}
            </div>
          </div>
          <div className=" ">
            <div className="tw-px-16 tw-py-2 tw-flex w-full">
              <div className="tw-flex tw-justify-start tw-w-1/3 tw-mr-20">
                <div className="tw-w-full tw-flex tw-flex-col tw-mt-6 tw-gap-4 tw-justify-between">
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Business Name
                    </label>
                    <input
                      type="text"
                      required
                      name="businessName"
                      value={formData.businessName}
                      onChange={handleChange}
                      className="tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Email Id
                    </label>
                    <input
                      type="email"
                      name="email"
                      // required
                      readOnly
                      value={formData.email}
                      // onChange={handleChange}
                      // style={{ border: "1px solid #CED4DA" }}
                      className="tw-mt-1 tw-px-2 input-outline   tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Address 1
                    </label>
                    <input
                      type="text"
                      required
                      name="address1"
                      value={formData.address1}
                      onChange={handleChange}
                      className="tw-mt-1 tw-px-2 input-outline tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Country
                    </label>
                    <input
                      type="text"
                      name="country"
                      required
                      value={formData.country}
                      onChange={handleChange}
                      className="tw-mt-1 tw-px-2 input-outline tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      required
                      value={formData.city}
                      onChange={handleChange}
                      className="tw-mt-1 tw-px-2 input-outline tw-bg-white tw-border tw-border-[#CED4DA]   tw-w-full  tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                </div>
              </div>

              <div className="tw-flex tw-justify-start tw-w-1/3">
                <div className="tw-w-full tw-flex tw-flex-col tw-mt-6 tw-gap-4 tw-justify-between">
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Business Type
                    </label>
                    <input
                      type="text"
                      // required
                      name="businessType"
                      value={formData.businessType}
                      onChange={handleChange}
                      className="tw-mt-1 tw-px-2 input-outline tw-border tw-bg-white tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Phone
                    </label>
                    <input
                      type="number"
                      name="phone"
                      // required
                      value={formData.phone}
                      onChange={handleChange}
                      className="tw-mt-1 tw-px-2 input-outline tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Address 2
                    </label>
                    <input
                      type="text"
                      name="address2"
                      // required
                      value={formData.address2}
                      onChange={handleChange}
                      className="tw-mt-1 tw-px-2 input-outline tw-border tw-bg-white tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      State
                    </label>
                    <input
                      type="text"
                      name="state"
                      // required
                      value={formData.state}
                      onChange={handleChange}
                      className="tw-mt-1 tw-px-2 input-outline tw-border tw-bg-white tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Postal Code
                    </label>
                    <input
                      type="text"
                      name="postalCode"
                      // required
                      value={formData.postalCode}
                      onChange={handleChange}
                      className="tw-mt-1 tw-px-2 input-outline tw-border tw-bg-white tw-border-[#CED4DA]   tw-w-full  tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            <hr />
            <div className=" tw-px-16 tw-py-2  w-full">
              <p className="tw-text-xl tw-font-semibold tw-text-black">
                Business Identification Number
              </p>
              <div className=" tw-w-1/3 tw-justify-start">
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    Australian Business No(ABN)
                  </label>
                  <input
                    type="text"
                    name="abnNumber"
                    value={formData.abnNumber}
                    onChange={handleChange}
                    className="tw-mt-1 tw-px-2 input-outline tw-border tw-bg-white tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-px-16 tw-py-3 tw-flex tw-justify-end tw-border-t tw-border-gray-200">
            <button
              className="tw-bg-white tw-text-black tw-px-4 tw-py-2 tw-rounded tw-border tw-border-[#CED4DA] tw-mr-2"
              onClick={handleCancel}
              
            >
              Cancel
            </button>

            {/* <button
              className="tw-bg-[#0d6efd] tw-text-white tw-px-4 tw-py-2 tw-rounded tw-border tw-border-[#0d6efd]"
              type="submit"
            >
           {loading ? "saving..." : "Save Changes"}  
            </button> */}
            <CustomButton
              disabledButton={loading}
              btnType={"submit"}
              BtnTxt={loading ? "saving..." : "Save Changes"}
              // BtnClassName="Filter-button"
              className="tw-bg-[#0d6efd] tw-text-white tw-px-4 tw-py-2 tw-rounded tw-border tw-border-[#0d6efd]"
            />
          </div>
          <Toaster position="bottom-right" className="tw-mr-4"  />
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.userProfile,
  currentBusiness: state.currentBusiness,
  accessToken: state.accessToken,
  ...state,
});
export default connect(mapStateToProps)(NewBusiness);
