

  const setupBeforeUnloadListener = (dispatch, logoutAction) => {
    let timeoutId;
  
    const handleAutomaticLogout = () => {
      dispatch(logoutAction());
      localStorage.removeItem("state");
    };
  
    const startLogoutTimer = () => {
      timeoutId = setTimeout(handleAutomaticLogout, 60 * 60 * 1000); // 1 hour in milliseconds
    };
  
    const resetLogoutTimer = () => {
      clearTimeout(timeoutId);
      startLogoutTimer();
    };
  
    // Start the logout timer when the user logs in
    startLogoutTimer();
  
    // Reset the timer on user activity (e.g., user interactions)
    window.addEventListener("mousemove", resetLogoutTimer);
    window.addEventListener("keypress", resetLogoutTimer);
  
    return () => {
      window.removeEventListener("mousemove", resetLogoutTimer);
      window.removeEventListener("keypress", resetLogoutTimer);
      clearTimeout(timeoutId);
    };
  };
  
  export default setupBeforeUnloadListener;
  