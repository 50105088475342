import * as React from "react";
import { useState, useEffect, useCallback, useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MdOutlineAddBox } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  setSellerId,
  setImageFiles,
  setVideoFiles,
  setCurrentBusiness,
} from "../../redux/action";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import defaultBusinessImg from "../../assets/default business.jpeg";

const ControlledOpenSelect = ({
  menuItem,
  firstBusiness,
  currentBusiness,
  userId,
  isAdmin,
}) => {
  console.log("isAdmin", isAdmin);
  console.log("firstBusiness", firstBusiness);
  const [businessData, setBusinessData] = useState(null);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const setInitialBusinessData = () => {
      if (currentBusiness) {
        setBusinessData(currentBusiness);
      } else if (Array.isArray(menuItem) && menuItem.length > 0) {
        setBusinessData(menuItem[0]);
      }
    };

    setInitialBusinessData();
  }, [currentBusiness, menuItem]);

  const handleChange = useCallback(
    (event) => {
      const selectedBusiness = menuItem.find(
        (item) => item.businessName === event.target.value
      );
      setBusinessData(selectedBusiness);
    },
    [menuItem]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setView(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
    setTimeout(() => {
      setView(true);
    }, 100);
  }, []);

  const handleClickToBack = useCallback(
    (sellerId, businessDetails) => {
      if (businessDetails?.sellerId !== businessData?.sellerId) {
        // Clear the table data here by dispatching a reset action
        dispatch(setSellerId(null)); // Optionally reset the sellerId
        dispatch(setImageFiles(null)); // Reset image files
        dispatch(setVideoFiles(null)); // Reset video files
        dispatch(setCurrentBusiness(null)); // Reset current business
        // Any other reset actions specific to the table data

        // Then proceed with setting the new business details
        localStorage.setItem("businesssName", businessDetails.businessName);
        localStorage.setItem("currentbusinesssid", businessDetails.businessId);
        dispatch(setSellerId(sellerId));
        dispatch(setCurrentBusiness(businessDetails));
        Cookies.set(`${userId}lastusedbusiness`, businessDetails.businessName, {
          expires: 365,
        });
        Cookies.set(`${userId}lastusedsellerId`, sellerId, { expires: 365 });
        Cookies.set(`${userId}lastusedbusinessDetails`, businessDetails, {
          expires: 365,
        });
        dispatch(setImageFiles(null));
        dispatch(setVideoFiles(null));
      }
      navigate("/dashboard");
      window.location.reload();
    },
    [businessData, dispatch, navigate, userId]
  );

  const clickToEditBusiness = useCallback(
    (event) => {
      event.stopPropagation(); // Prevent event from bubbling up to MenuItem
      handleClose(); // Close the Select
      navigate("/editBusiness");
    },
    [navigate, handleClose]
  );

  const handleClick = useCallback(() => {
    navigate("/newBusiness");
  }, [navigate]);

  const reorderedMenuItems = useMemo(() => {
    const items = [...menuItem];
    const selectedIndex = items.findIndex(
      (item) => item.businessId === businessData?.businessId
    );
    if (selectedIndex !== -1) {
      const selectedBusiness = items.splice(selectedIndex, 1)[0];
      items.unshift(selectedBusiness);
    }
    return items;
  }, [menuItem, businessData]);

  return (
    <>
      <FormControl sx={{ minWidth: "100%" }}>
        <Select
          open={open}
          value={businessData ? businessData.businessName : ""}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={handleChange}
          style={{ borderTop: "1px solid #ccc", borderRadius: "0px" }}
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
          }}
          multiline
        >
          {reorderedMenuItems.map((option) => (
            <MenuItem
              key={option.businessId}
              value={option.businessName}
              style={{ height: "54px", gap: "8px" }}
              onClick={() => {
                handleClickToBack(option.sellerId, option);
              }}
            >
              <div className="tw-flex tw-flex-row tw-items-center tw-m-0 gap-2">
                <img
                  src={option.fileLocation || defaultBusinessImg}
                  alt="Business"
                  style={{
                    marginRight: 10,
                    width: 40,
                    height: 40,
                    borderRadius: "10px",
                  }}
                />
                <div>
                  <p className="tw-mt-0 tw-text-lg tw-mb-0">
                    {option.businessName}
                  </p>
                  {view &&
                    businessData?.businessName === option.businessName && (
                      <button
                        className="tw-m-0 tw-cursor-pointer"
                        onClick={clickToEditBusiness}
                      >
                        <span className="tw-underline">Edit Business</span>
                      </button>
                    )}
                </div>
              </div>
            </MenuItem>
          ))}
          {isAdmin && (
            <MenuItem onClick={handleClick} key={"add"} value={"Add Business"}>
              <div className="tw-flex tw-items-center">
                <MdOutlineAddBox
                  className="tw-w-6 tw-h-6  tw-mr-4"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>
              Add Business
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
};

export default ControlledOpenSelect;
