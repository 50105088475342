import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import CustomButton from "../../components/Button";
import { Toaster, toast } from "sonner";

import "./userProfile.css";
import { UserapiClient } from "../../components/userManagementApiclient";

const AddUserModal = ({
  accessToken,
  showAddUserModal,
  handleCloseAddUserModal,
  setRefresh,
  navigate,
  userProfile,
  business,
  sellerId,
  userId,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    role: false,
  });
  // console.log("userProf  fdv     ile", userProfile);
  console.log("busines   jsgdhjs", business);
  // console.log("sellffseerId", sellerId);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   // Populate form data from business object when the component mounts or business prop changes
  //   if (business) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       given_name: business.businessName || "",
  //       address: business.city
  //       || "",
  //       zoneinfo: business.country
  //       || "",
  //     }));
  //   }
  // }, [business]);
  useEffect(() => {
    const isFormValid = Object.values(formErrors).every((error) => !error);
    const isFormDataComplete = Object.values(formData).every(
      (field) => field.trim() !== ""
    );

    setIsButtonDisabled(!isFormValid || !isFormDataComplete);
  }, [formData, formErrors]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "",
    }));
  };

  const handleSaveUser = async () => {
    const { name, email, role } = formData;
    const errors = {
      name: name.trim() === "",
      email: !validateEmail(email),
      role: role.trim() === "",
    };
    setFormErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return;
    }

    setIsLoading(true); // Set loading state to true

    try {
      const UserClient = UserapiClient(accessToken);
      const response = await UserClient.post("/register/User/v2", {
        name,
        email,
        role,
        businessId: business.businessId,
        businessName: business.businessName,
        sellerId: sellerId,
        given_name: business.businessName || "",
        address: business.city || "",
        zoneinfo: business.country || "",
        userId: userId,
      });
      console.log(response);
      toast.success("User added successfully");
      handleCloseAddUserModal();
      setRefresh(true);
      navigate("/users");
    } catch (error) {
      toast.error(error.response.data.error);
      console.error(error.response.data.error);
    } finally {
      setIsLoading(false); // Reset loading state regardless of success or failure
      setFormData({
        name: "",
        email: "",
        role: "",
      });
    }
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <Modal
      show={showAddUserModal}
      onHide={handleCloseAddUserModal}
      backdrop="static"
    >
      <Modal.Header closeButton className="justify-content-center">
        <Modal.Title className="w-100">
          <h4>Invite People to Collaborate</h4>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className=" tw-flex tw-flex-col gap-3 tw-p-3">
          <input
            className={`tw-mt-1 input-outline tw-px-2  tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm   tw-h-11 tw-rounded-md ${
              formErrors.name ? "is-invalid" : ""
            }`}
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleInputChange}
          />
          {formErrors.name && (
            <p className="text-danger" style={{ textAlign: "start" }}>
              Please enter a name.
            </p>
          )}

          <input
            className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
              formErrors.email ? "is-invalid" : ""
            }`}
            type="text"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
          />
          {formErrors.email && (
            <p className="text-danger" style={{ textAlign: "start" }}>
              Please enter a valid email.
            </p>
          )}
          <select
            className="tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm tw-h-11 tw-rounded-md"
            name="role"
            value={formData.role}
            onChange={handleInputChange}
          >
            <option value="" className="text-gray-500">
              Select Role
            </option>
            {/* <option value="Admin">Admin</option> */}
            <option value="User">User</option>
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ background: "#fff" }}>
        <CustomButton
          disabledButton={isLoading}
          btnType="button"
          BtnClassName={`Filter-button ${isButtonDisabled ? "disabled" : ""}`}
          ClickEvent={handleSaveUser}
          BtnTxt={isLoading ? "Progress" : "Invite"}
          disabled={isButtonDisabled}
        />
      </Modal.Footer>
      <Toaster position="bottom-right" className="tw-mr-4" />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.userProfile,
  business: state.currentBusiness,
  sellerId: state.sellerIdReducer,
  ...state,
});

export default connect(mapStateToProps)(AddUserModal);
