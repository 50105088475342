export const DateFormat = (input) => {
  if (
    input === "0000-00-00" ||
    input === "0000-00-00 00:00:00" ||
    input === null
  ) {
    return "";
  } else {
    const date = new Date(input);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let format =
      (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()) +
      "-" +
      months[date.getMonth()] +
      "-" +
      date.getFullYear();

    return format;
  }
};
