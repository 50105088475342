export const login = (login) => {
 // console.log("Dispatching LOGIN action with login status:", login);
  return {
    type: "LOGIN",
    login,
  };
};
export const logoutAction = () => ({
  type: "LOGOUT",
});
export const accessToken = (accessToken) => ({
  type: "ACCESSTOKEN",
  accessToken,
});
export const setSellerId = (sellerId) => ({
  type: "SET_SELLER_ID",
  sellerId,
});
export const setCurrentBusiness = (business) => ({
  type: "SET_CURRENT_BUSINESS",
  business,
});
export const setAllTokens = (AllTokens) => ({
  type: "SET_ALLTOKEN",
  AllTokens,
});
export const userProfile = (userProfile) => ({
  type: "USERPROFILE",
  userProfile,
});
export const profileInfo = (profileInfo) => ({
  type: "PROFILEINFO",
  profileInfo,
});

export const RefreshToken = (RefreshToken) => ({
  type: "REFRESHTOKEN",
  RefreshToken,
});

export const userRoutes = (userRoutes) => ({
  type: "USERROUTES",
  userRoutes,
});

export const setImageFiles = (files) => ({
  type: "SET_IMAGE_FILES",
  payload: files,
});
export const refreshImageData = () => ({
  type: "REFRESH_IMAGE_DATA",
});

export const setVideoFiles = (files) => ({
  type: "SET_VIDEO_FILES",
  payload: files,
});
export const setCurrentPage = (currentPage) => ({
  type: "SET_CURRENT_PAGE",
  payload: currentPage,
});
