import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import TextBox from "../../components/TextBox";
import CustomButton from "../../components/Button";
import { apiClient } from "../../components/apiClient";
import { connect } from "react-redux";

import { Toaster,toast } from "sonner";
import "./Layouts.css";

const AddLayout = ({
  showAddLayout,
  handleCloseAddLayout,
  sellerId,
  state,
}) => {
  const logUserId = state.userProfile.userId;
  let [layout, setLayout] = useState({
    name: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  let [errorList, setErrorList] = useState({
    name: false,
  });
  const [reqErr, setReqErr] = useState(false);

  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    setLayout({ ...layout, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let hasError = false;
    const storedLayouts = localStorage.getItem('layoutList');
    const layouts = storedLayouts ? JSON.parse(storedLayouts) : [];
    const isLayoutExist = layouts.some(l => l.name.toLowerCase() === layout.name.toLowerCase());

  if (isLayoutExist) {
    toast.error("Layout name already exists. Please use a different name.");
    setIsLoading(false);
    return; // Stop further processing if the layout name already exists
  }
    for (const key in layout) {
      if (layout[key].trim() === "") {
        setErrorList({ ...errorList, [key]: true });
        hasError = true;
        setReqErr(true);
      } else {
        setErrorList({ ...errorList, [key]: false });
      }
    }

    if (!hasError) {
      try {
        setReqErr(false);
        let formData = new FormData();
        formData.append("name", layout.name);
        formData.append("userId", logUserId);
        formData.append("file", selectedFile);

        await apiClient(state.accessToken)
          .post(`layout/register/${sellerId}`, formData)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Layout Added Successfully");
            }
            setIsLoading(false);
            resetField(true);
          })
          .catch((err) => {
            toast.err("Something went wrong");
          });
      } catch (err) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const resetField = (val = null) => {
    setLayout({ name: "" });
    setSelectedFile(null);
    setImagePreview(null);
    setLayout({ name: "" });
    val ? handleCloseAddLayout(true) : handleCloseAddLayout(false);
  };

  return (
    <>
      <Modal
        show={showAddLayout}
        onHide={resetField}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div style={{ width: "100%" }}>
          <Modal.Title style={{ fontFamily: "Poppins", fontWeight: "600" }}>
          Add Layout
        </Modal.Title>
          </div>
        </Modal.Header>

        <form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body className="modal-layout">
            {/*
            <div className="d-flex justify-content-center">
              <img
                style={{
                  //border: "1px solid #ccc",
                  borderRadius: "50%",
                  width: "6rem",
                  height: "6rem",
                }}
                src={imagePreview || "logo192.png"}
                alt="profile"
              />
            </div>
            
            <input
              className="mt-3 form-control"
              type="file"
              name="profile"
              onChange={handleFileChange}
            />
              */}

            <TextBox
              txtBoxType={"text"}
              txtBoxPH={"Name"}
              txtBoxValue={layout.name}
              changeEvent={handleChange}
              txtBoxName={"name"}
            />
          </Modal.Body>
          <Modal.Footer style={{background:"#fff"}}>
            {reqErr && (
              <span className="error">Please Fill Required Fields</span>
            )}
            <CustomButton
              disabledButton={isLoading}
              btnType={"submit"}
              BtnTxt={isLoading ? "Progress" : "Save"}
              BtnClassName="Filter-button"
            />
          </Modal.Footer>
          <Toaster position="bottom-right" className="tw-mr-4"  />
        </form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sellerId: state.sellerIdReducer,
    state: state,
  };
};

export default connect(mapStateToProps)(AddLayout);
