import { Checkbox, Tooltip } from "antd";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IoSettingsOutline } from "react-icons/io5";
import { toast } from "sonner";
import { apiClient } from "../../apiClient";
import CustomButton from "../../Button";

const DisplaySettings = ({
  notificationStatus,
  setNotificationStatus,
  displayId,
  accessToken,
}) => {
  const [showSettingsModal, setShowSettingsModal] = useState(false);
console.log("Notification Status:", notificationStatus);
  const handleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  const updateNotificationStatus = async () => {
    try {
      setNotificationStatus(!notificationStatus);
      const status = !notificationStatus;

      const response = await apiClient(accessToken).put(
        `display/update-notification-status/${displayId}`,
        { notificationPreference: status }
      );

      if (response.status === 200) {
        toast.success("Notification status updated successfully");
      }
    } catch (error) {
      console.log(error);toast.error("Failed to update notification status");
    }
  };

  return (
    <>
      <Tooltip title="Settings" color="#0071ca">
        <div className="button-container mb-0">
          <CustomButton
            disabledButton={false}
            btnType="button"
            BtnClassName="custom-tv-btn"
            ClickEvent={handleSettingsModal}
            BtnTxt={<IoSettingsOutline />}
          />
        </div>
      </Tooltip>
      <Modal show={showSettingsModal} onHide={handleSettingsModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Display Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-pb-4 tw-px-2">
            <div className="tw-text-sm">Alert Via email for health check failures</div>
            <div>
              <Checkbox
                checked={notificationStatus}
                onChange={updateNotificationStatus}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DisplaySettings;
