import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { apiClient } from "../../components/apiClient";
import { useDispatch } from "react-redux";
import { IoCloudUploadOutline } from "react-icons/io5";
import { Button } from "antd";
import { Toaster, toast } from "sonner";
import { refreshImageData } from "../../redux/action";
import CustomButton from "../../components/Button";
import { useQueryClient } from "@tanstack/react-query";

const AddmoreImages = ({
  showImageModal,
  handleCloseImageModal,
  accessToken,
  userProfile,
  sellerId,
}) => {
  const queryClient = useQueryClient();
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  // console.log("fffffssds", files);

  useEffect(() => {
    if (!showImageModal) {
      setFiles([]);
      setFileNames([]);
      setPreviewUrls([]);
      setError(null);
    }
  }, [showImageModal]);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const fileArray = Array.from(selectedFiles);

      const imageFiles = fileArray.filter((file) =>
        file.type.startsWith("image/")
      );
      if (imageFiles.length === 0) {
        setError("Please select at least one image file.");
        return;
      }
      setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
      const nameArray = imageFiles.map((file) => file.name);
      setFileNames((prevNames) => [...prevNames, ...nameArray]);
      processFiles([...files, ...imageFiles]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFiles = e.dataTransfer.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const fileArray = Array.from(selectedFiles);

      // Filter out non-image files
      const imageFiles = fileArray.filter((file) =>
        file.type.startsWith("image/")
      );
      if (imageFiles.length === 0) {
        setError("Please drop at least one image file.");
        return;
      }

      setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
      const nameArray = imageFiles.map((file) => file.name);
      setFileNames((prevNames) => [...prevNames, ...nameArray]);
      processFiles([...files, ...imageFiles]);
    }
  };
  const processFiles = (selectedFiles) => {
    const newPreviewUrls = selectedFiles.map((file) =>
      URL.createObjectURL(file)
    );

    setPreviewUrls(newPreviewUrls);
    setError(null);
  };

  const handleSave = async () => {
    try {
      setError(null);
      if (files.length === 0) {
        setError("Please select at least one file.");
        return;
      }
      const maxImageLimit = 15;

      if (files.length > maxImageLimit) {
        setError(`Exceeded the maximum limit of ${maxImageLimit} images.`);
        return;
      }

      const client = apiClient(accessToken);
      setLoading(true);

      const payload = {
        files: files.map((file) => ({
          fileName: file.name,
          fileType: file.type,
        })),
      };

      const response = await client.post(
        `library/presignedurls/${sellerId}`,
        payload
      );

      if (response.status === 200) {
        const presignedUrls = response.data.presignedUrls;

        const uploadPromises = presignedUrls.map(
          async (presignedUrl, index) => {
            try {
              const key = presignedUrl.fields.key;
              const file = files[index];

              const formData = new FormData();
              formData.append("key", key);
              formData.append("file", file);

              const uploadResponse = await fetch(presignedUrl.url, {
                method: "POST",
                body: formData,
              });

              if (uploadResponse.status === 204) {
                const metadata = {
                  fileDetails: [
                    {
                      fileName: file.name,
                      fileType: file.type,
                      fileSize: file.size,
                      fileLocation: `${presignedUrl.url}/${encodeURIComponent(
                        key
                      )}`,
                    },
                  ],
                };

                await storeMetadata(accessToken, metadata);
                toast.success(`File ${index + 1} uploaded successfully.`);
              } else {
                console.error(
                  `Error uploading file ${index + 1}. Status: ${
                    uploadResponse.status
                  }`
                );
                throw new Error(`Error uploading file ${index + 1}`);
              }
            } catch (error) {
              console.error(`Error uploading file ${index + 1}:`, error);
              throw error;
            }
          }
        );

        await Promise.all(uploadPromises); // Wait for all uploads to complete

        handleModalClose();
        dispatch(refreshImageData());
        toast.success("All files uploaded successfully.");
        await queryClient.invalidateQueries();
      } else {
        toast.error("Error getting presigned URLs. Please try again.");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error uploading media:", error);
      toast.error("Error uploading media. Please try again.");
    }
  };

  const storeMetadata = async (accessToken, fileDetails) => {
    try {
      const client = apiClient(accessToken);

      const response = await client.post(
        `library/storemetadata/${sellerId}`,
        fileDetails
      );

      if (response.status === 200) {
        console.log("Media uploaded successfully");
      } else {
        console.error("Error storing metadata. Please try again.");
      }

      return response;
    } catch (error) {
      console.error("Error storing metadata:", error);
      throw error;
    }
  };

  const handleModalClose = () => {
    setFiles([]);
    setFileNames([]);
    setPreviewUrls([]);
    setError(null);
    handleCloseImageModal();
  };

  const handleImageClose = (index) => {
    const updatedFiles = files.filter((_, idx) => idx !== index);
    const updatedFileNames = fileNames.filter((_, idx) => idx !== index);
    setFiles(updatedFiles);
    setFileNames(updatedFileNames);

    const updatedPreviewUrls = previewUrls.filter((_, idx) => idx !== index);
    setPreviewUrls(updatedPreviewUrls);
  };

  const checkIsOneStyle =
    previewUrls.length === 1
      ? "single-image-preview-container"
      : "image-preview-container";

  const handleUploadAreaClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal
      show={showImageModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title style={{ fontFamily: "Poppins", fontWeight: "600" }}>
          Add Images to Library
        </Modal.Title>
      </Modal.Header>
      <hr />
      <Modal.Body
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className="d-flex justify-content-center"
      >
        <div
          className="text-center custom-modal-content"
          style={{ width: "100%" }}
        >
          <div
            className="select d-flex flex-column align-items-center justify-content-center mb-4"
            onClick={handleUploadAreaClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{
              minHeight: "150px",
              height: "auto",
              border: "2px dashed #D5D5D5",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              multiple
            />
            <IoCloudUploadOutline
              style={{ marginBottom: "8px", fontSize: "20px" }}
            />
            <span>Click or drag file to this area to Upload</span>
          </div>
          <div
            className="p-1 d-flex justify-content-flex-center"
            style={{
              color: "#9D9D9D",
            }}
          >
            JPEG, PNG and JPG formats upto 5MB - 50MB
          </div>
          <hr />
          <div
            className="p-1 d-flex justify-content-start"
            style={{
              minHeight: "150px",
              height: "auto",
              borderRadius: "8px",
              overflowY: "auto",
            }}
          >
            <div className={`${checkIsOneStyle}`}>
              {previewUrls.map((url, index) => (
                <div className="image-preview-container" key={index}>
                  <button
                    style={{ borderRadius: "10px" }}
                    type="button"
                    className="close-button"
                    onClick={() => handleImageClose(index)}
                  >
                    <label>&times;</label>
                  </button>
                  <img
                    className="image-preview"
                    src={url}
                    title={fileNames[index]}
                    alt={`preview-${index}`}
                  />
                  <div
                    className="filename-wrapper"
                    style={{
                      fontSize: "13px",
                      fontFamily: "Poppins",
                      maxWidth: "100px",
                    }}
                  >
                    {fileNames[index]}
                  </div>
                </div>
              ))}
              {/* <hr /> */}
            </div>
          </div>
          {error && <div className="error">{error}</div>}
        </div>
        {loading && (
          <div className="overlay">
            <ThreeDots
              height="40"
              width="40"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}
      </Modal.Body>
      <hr />
      <Modal.Footer style={{ background: "#fff" }}>
        <Button
          disabled={false}
          // type="button"
          size="large"
          className="default tw-flex tw-items-center poppins-font"
          onClick={handleModalClose}
          // style={{ backgroundColor: "white", color: "black" }}
        >
          Cancel
        </Button>

        {/* <CustomButton
    disabledButton={false}
    btnType="button"
    BtnClassName="add-layout-buttons"
    BtnTxt={<>Cancel</>}
    ClickEvent={handleModalClose}
  /> */}
        <Button
          disabled={false}
          size="large"
          // type="primary"
          className="tw-bg-primary tw-text-white tw-flex tw-items-center poppins-font"
          onClick={handleSave}
        >
          Save
        </Button>
      </Modal.Footer>

      <Toaster position="bottom-right" className="tw-mr-4" />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  userProfile: state.userProfile,
  sellerId: state.sellerIdReducer,
});

export default connect(mapStateToProps)(AddmoreImages);
