import React, { useState ,useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { useBeforeRender } from "./usebeforerender";
import "./Layoutview.css";

const PopUpcarosal = ({
  handleClosepopupcaro,
  handleShowpopupcaro,
  layoutNameSelect,
  data,
  show,
}) => {
  //console.log("data: ", data);
  useBeforeRender(() => {
    window.addEventListener("error", (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr)
          resizeObserverErr.className = "hide-resize-observer";
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = "hide-resize-observer";
      }
    });
  }, []);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const libraryIds = data && data.libraryIds ? JSON.parse(data.libraryIds) : [];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) =>
        prevIndex === libraryIds.length - 1 ? 0 : prevIndex + 1
      );
    }, libraryIds[index]?.duration * 1000 || 5000); // Default duration: 5 seconds

    return () => clearInterval(interval);
  }, [index, libraryIds]);
  const renderMediaContent = (library) => {
    //console.log("library: ", library);
    if (library.mediaType === "image") {
      return (
        <img src={library.fileUrl} alt="Ima//" className="img-fluid" style={{ width: "65%"}} />
      );
    } else if (library.mediaType === "video") {
      return (
        <video autoPlay controls className="carousel-item-video">
          <source src={library.fileUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return null;
  };

  return (
    <div>
    <Modal
        show={show}
        onHide={handleClosepopupcaro}
        size="lg"
        fullscreen
        className="custom-modal-display"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Layout - {layoutNameSelect}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {libraryIds.length > 0 ? (
            <Carousel activeIndex={index} onSelect={handleSelect}>
              {libraryIds.map((library, index) => (
                <Carousel.Item key={index} className="media-content">
                  {renderMediaContent(library)}
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <h6 className="text-center">
              Please Upload files to view layout display.
            </h6>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PopUpcarosal;
