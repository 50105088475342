import { Button, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import {  AiOutlineCheck, AiOutlineClose, AiOutlineSearch,} from "react-icons/ai";
import { useParams } from "react-router-dom";
import { BsHeartPulse, BsPencilSquare } from "react-icons/bs";
import { IoMdPower } from "react-icons/io";

import {
  MdDelete,
  MdEditDocument,
  MdLocationOn,
  MdOutlineCameraAlt,
  MdOutlineRefresh,
} from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { apiClient } from "../../components/apiClient";
import CustomButton from "../../components/Button";
import "./Displays.css";
import { useDispatch } from "react-redux";
import { Toaster, toast } from "sonner";
import CustomDataTable from "../../components/DataTable";
import DisplaySettings from "../../components/DisplayPreview/DisplaySettings/DisplaySettings";
const displaydata = ["a", "ef", "eg"];



const useDisplayId = () => {
  const { id } = useParams();
  return id;
};
const DisplayPreview = ({
  AllTokens,
  state,
  idToken,
  accessToken,
  userId,
  sellerId,
  currentBusiness,
}) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const filteredDisplayData = displaydata.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [isEditingName, setIsEditingName] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [layoutArr, setLayoutArr] = useState([]);
  const [showPlusModal, setPlusModal] = useState(false);
  const [indexes, setIndex] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [layoutsId, setLayoutId] = useState("");
  const [libraryIds, setLibraryIds] = useState([]);
  const [deactiveShowModal, setDeactiveShowModal] = useState(false);
  const [assignmentContainers, setAssignmentContainers] = useState([]);
  const [autoRefreshSettings, setAutoRefreshSettings] = useState({});
  const [showAssignments, setShowAssignments] = useState(false);
  const [contain, setContainer] = useState([]);
  const [assignedlayout, setAssignedlayout] = useState([]);
  const [displayLogs, setDisplayLogs] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(true);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [healthCheckData, setHealthCheckData] = useState(null);
  const [screenshotImage, setScreenshotImage] = useState(null);
  const [iscreenshotImageLoading, setScreenshotImageLoading] = useState(true);
  const [buttonColor, setButtonColor] = useState("");
    const [isImageLoading, setIsImageLoading] = useState(true);

  const handleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  const days = ["S", "M", "T", "W", "T", "F", "S"];

  const [selectedDays, setSelectedDays] = useState(
    days.map((_, index) => index)
  );
  const monitorContainerRef = useRef(null);
  const imageUrl = require("../../assets/defaultDisplay.png");
  const handleDeleteLayout = async (layoutsId, displayId, index) => {
    console.log("Index at deletion:", index);
    console.log("Preparing to send:", { layoutsId, displayId, index });
    try {
      //console.log("last", layoutsId);
      const response = await apiClient(accessToken).post(
        `display/deactivate/layout`,
        {
          layoutId: layoutsId,
          displayId: displayId,
          index: index,
        }
      );
      // console.log("assssisgned layouts", assignedlayout);
      const updatedLayouts = assignedlayout.filter(
        (_layout, idx) => idx !== index
      );
      //  console.log("updated", updatedLayouts);
      setAssignedlayout(updatedLayouts);
      toast.success("Layout delete successfully");
      //  console.log(response);
    } catch (error) {
      console.log(error.message);
      toast.error("Error deleting Layout:", error.message);
    }
    // console.log("zxsd", assignedlayout);
  };
  const locations = useLocation();
  const [data, setData] = useState({});
  const fetchDisplayDetailsById = async () => {
    try {
      setLoading(true);
      const response = await apiClient(accessToken).get(
        `display/details/${displayId}`
      );
      console.log("response", response);
      const currentBusiness = await apiClient(accessToken).get(`display/${displayId}/preview/currentBusiness`);
      console.log("currentBusinesscurrentBusiness", currentBusiness.data.businessId);
      localStorage.setItem("currentbusinesssid", currentBusiness.data.businessId);
      setData(response.data.data);
      console.log("data:", data);
      setNewDisplayName(response.data.data.displayName);
      setScreenshotImage(response.data.data.screenShotPath);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching display details:", error);
      setLoading(false);
    }
  };
  const displayId = useDisplayId();

  useEffect(() => {
    if (displayId) {
      fetchDisplayDetailsById();
    }
  }, [displayId, accessToken]);

  const {
    appVersion,
    id,
    screenShotPath,
    createdAt,
    location,
    fcmToken,
    displayName,
  } = data;
  console.log("data", data);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [newDisplayName, setNewDisplayName] = useState(displayName || "");
  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await apiClient(accessToken).get(
        `/display/getActiveLayout/${sellerId}`
      );

      setLayoutArr(data.data.data);

      const addedlayouts = await apiClient(accessToken).get(
        `display/getdisplay/layouts/${displayId}`
      );
      setStartTime(addedlayouts.data.data.data.displayLayouts[0].startTime);
      setEndTime(addedlayouts.data.data.data.displayLayouts[0].endTime);
      const layoutsWithDays = addedlayouts.data.data.data.displayLayouts.map(
        (layout) => {
          const dayNameToIndex = {
            sunday: 0,
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6,
          };
          const selectedDays = layout.assignedDays.map(
            (day) => dayNameToIndex[day.toLowerCase()]
          );
          return { ...layout, selectedDays };
        }
      );

      setAssignedlayout(layoutsWithDays);
      //console.log("displaydetails", addedlayouts.data.data);
      console.log("addedlayouts", addedlayouts.data.data.data.displayLayouts);
      const mappedLibraryIds = addedlayouts.data.data.data.displayLayouts.map(
        (eachlayout) => JSON.parse(eachlayout.libraryIds)
      );
      console.log("mappedLibraryIds", mappedLibraryIds);
      setLibraryIds(mappedLibraryIds);
      setLoading(false);
      localStorage.setItem(
        "addedlayouts",
        JSON.stringify(addedlayouts.data.data.data.displayLayouts)
      );
      // console.log(mappedLibraryIds);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, [accessToken]);

  const handleDayClickForAssigned = (layoutIndex, dayIndex) => {
    setAssignedlayout((prevLayouts) => {
      const newLayouts = [...prevLayouts];
      const currentDays = newLayouts[layoutIndex].selectedDays;
      if (currentDays.includes(dayIndex)) {
        newLayouts[layoutIndex].selectedDays = currentDays.filter(
          (d) => d !== dayIndex
        );
      } else {
        newLayouts[layoutIndex].selectedDays = [...currentDays, dayIndex];
      }
      return newLayouts;
    });
  };

  const handlePlusClose = () => setPlusModal(false);
  const [deletionIndex, setDeletionIndex] = useState(null);
  const handlePlusModalShow = (layId, idx) => {
    console.log("Modal show index:", idx);
    setLayoutId(layId);
    setDeletionIndex(idx);
    setPlusModal(true);
  };

  const clickToindex = (i) => {
    setIndex(i);
    // console.log(i);
  };
  const handleSecPlusModalShow = (assignmentContain) => {
    setContainer(assignmentContain);
    setPlusModal(true);
  };

  const handleDeactiveModal = () => {
    setDeactiveShowModal(true);
  };

  const addAssignmentContainer = () => {
    setAssignmentContainers((prevContainers) => [
      ...prevContainers,
      {
        id: uuid(),
        layoutId: "",
        name: "",
        selectedStartTime: "00:00",
        selectedEndTime: "23:59",
        step: "3600",
        autoRefresh: false,
        selectedDays: [0, 1, 2, 3, 4, 5, 6], // All days selected by default
      },
    ]);
    setAutoRefreshSettings((prevSettings) => ({
      ...prevSettings,
      [uuid()]: false,
    }));
    setShowAssignments(true);
  };

  const clickToDeactive = async () => {
    try {
      const postData = {
        displayId: displayId,
        userId: userId,
      };
      const response = await apiClient(accessToken).post(
        `display/deactive`,
        postData
      );
      //  console.log(response);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const clickToSendNotification = async (title) => {
    try {
      const tokenResponse = await apiClient(accessToken).get(
        "/display/accessToken"
      );
      if (tokenResponse.status !== 200) {
        throw new Error("Failed to fetch access token");
      }

      const fcmAccessToken = tokenResponse.data.token;
      const notificationData = {
        message: {
          token: fcmToken,
          notification: {
            title: title,
            body: JSON.stringify({
              refreshToken: "",
            }),
          },
        },
      };

      console.log("Notification data:", notificationData);

      const projectId = process.env.REACT_APP_PROJECT_ID;
      const notificationResponse = await fetch(
        `https://fcm.googleapis.com/v1/projects/${projectId}/messages:send`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${fcmAccessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(notificationData),
        }
      );

      if (!notificationResponse.ok) {
        throw new Error("Failed to send notification");
      }

      const notification = await notificationResponse.json();
      console.log("Notification response:", notification);

      if (title.toLowerCase() === "restart") {
        toast.success("Restart command sent successfully");
      } else if (title.toLowerCase() === "refresh") {
        toast.success("Refresh command sent successfully");
      }

      await fetchScreenshotImage();
      await fetchDisplayLogs();
    } catch (error) {
      console.error("Error in clickToSendNotification:", error);
      toast.error(
        `Error sending ${title.toLowerCase()} command: ${error.message}`
      );
      // You might want to handle the error here, e.g., show a user-friendly message
    }
  };

  const handleEditNameStart = () => {
    setIsEditingName(true);
  };

  const handleNameCancel = () => {
    setNewDisplayName(displayName);
    setIsEditingName(false);
  };

  const handleUpdateName = async () => {
    try {
      const response = await apiClient(accessToken).put(
        `/display/update-item`,
        {
          id: id,
          updates: {
            displayName: newDisplayName,
          },
        }
      );

      if (response.status === 200) {
        setIsEditingName(false);
        toast.success("Display name updated successfully");
      } else {
        throw new Error(`Update failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error updating display name:", error);
      toast.error("Error updating display name: " + error.message);
    }
  };

  const [showrefreshmodal, setShowrefreshmodal] = useState(false);
  const handlerefreshmodal = () => {
    setShowrefreshmodal(true);
  };
  const [error, setError] = useState("");
  const handleSaveDisplay = async () => {
    try {
      setLoading(true);
      const hasMissingLayoutOrTime = assignmentContainers.some(
        (container) =>
          !container.layoutId ||
          !container.selectedStartTime ||
          !container.selectedEndTime
      );

      if (hasMissingLayoutOrTime) {
        setLoading(false);
        toast.error(
          "Please select layout and time for all assignments before saving."
        );
      } else {
        const updatedAssignmentContainers = assignmentContainers.map(
          (container) => {
            const selectedLayout = layoutArr.find(
              (layout) => layout.id === container.layoutId
            );
            return {
              ...container,
              name: selectedLayout.name,
              autoRefresh: autoRefreshSettings[container.id] || false,
            };
          }
        );

        const dayNames = [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ];

        const postData = {
          DisplayLayout: [
            ...assignedlayout.map((layout) => ({
              id: layout.layoutId,
              name: layout.name,
              startTime: Array.isArray(layout.startTime)
                ? layout.startTime[0]
                : layout.startTime,
              endTime: Array.isArray(layout.endTime)
                ? layout.endTime[0]
                : layout.endTime,
              assignedDays: layout.selectedDays.map((index) => dayNames[index]),
              autoRefresh: false,
            })),
            ...updatedAssignmentContainers.map((container) => ({
              id: container.layoutId,
              name: container.name,
              startTime: container.selectedStartTime,
              endTime: container.selectedEndTime,
              assignedDays: container.selectedDays.map(
                (index) => dayNames[index]
              ),
              autoRefresh: container.autoRefresh,
            })),
          ],
        };

        // Send request to save display layout
        const response = await apiClient(accessToken).post(
          `display/assign/layout/${displayId}`,
          postData
        );

        if (response) {
          setShowrefreshmodal(true);
        }
        setShowModal(false);
        toast.success("Layout added successfully");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error saving display:", error.message);
      toast.error("Error saving display:", error.message);
    }
  };
  const getTimeInMinutes = (timeString) => {
    console.log("timeString", timeString);
    if (Array.isArray(timeString)) {
      timeString = timeString[0]; // Take the first element if it's an array
    }

    if (typeof timeString !== "string") {
      throw new Error("Invalid time format: expected string");
    }

    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }
  const columns = [
    {
      name: "Log Event",
      selector: (param) => param.data,
      sortable: true,
    },

    {
      name: "Log Time",
      selector: (param) => formatTime(param.time),
      sortable: true,
      defaultSortField: true,
    },
  ];

  const fetchDisplayLogs = async () => {
    try {
      console.clear();
      console.log("useeffect calleld");
      const response = await apiClient(accessToken).get(
        `display/logs/${[[displayId]]}`
      );
      console.log("response", response);
      setDisplayLogs(response.data);
      setLoadingLogs(false);
    } catch (error) {
      console.error("Error fetching display logs:", error.message);
      setLoadingLogs(false);
    }
  };

  useEffect(() => {
    fetchDisplayLogs();
  }, [accessToken]);

  const getLocationList = async () => {
    try {
      setIsLoadingLocations(true);
      const res = await apiClient(accessToken).get(
        `store/storelist/${sellerId}`
      );
      setIsLoadingLocations(false);

      const updatedLocationData = res.data.data;
      console.log("UPDATED LOCATION", updatedLocationData);

      // Filter oout the selected location from the list
      const filteredLocationData = updatedLocationData.filter(
        (location) => location.id !== selectedLocation
      );

      // Update state
      setLocationData(filteredLocationData);
      console.log("filter", filteredLocationData);
    } catch (err) {
      console.log(err);
      setIsLoadingLocations(false);
    }
  };
  const [getLocation, setGetLocation] = useState([]);

  const [notificationStatus, setNotificationStatus] = useState(false);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await apiClient(accessToken).get(
          `/display/list/${sellerId}`
        );
        const notificationStatusResponse =
          response?.data?.data[0].notificationPreference;
        setNotificationStatus(notificationStatusResponse);

        if (response?.data?.data?.length > 0) {
          const newData = response?.data?.data?.filter(
            (item, i) => item?.id === displayId
          );
          setSelectedLocation(
            newData?.length > 0 ? newData?.[0]?.location : ""
          );
          console.log("newData: ", newData, { displayId });
        }
        setGetLocation(response?.data?.data);
        // const filteredData = selectedLocation
        //   ? response.data.data.filter(param => param.location === selectedLocation)
        //   : response.data.data;

        // setDisplayArr(filteredData);
      } catch (error) {
        console.error("Error fetching display", error);
      }
    };

    fetchLocation();
  }, [accessToken]);

  useEffect(() => {
    getLocationList();
  }, [showLocationModal]);

  const handleLocationChange = (locationId) => {
    setSelectedLocation(locationId);
  };

  const handleSaveLocation = async () => {
    await updateDisplay(selectedLocation);

    setShowLocationModal(false);
  };

  console.log(selectedLocation);
  const updateDisplay = async (location) => {
    try {
      const idToken = accessToken;
      const client = apiClient(idToken);

      const response = await client.put(
        `display/update/${sellerId}/${displayId}`,
        {
          location,
        }
      );

      if (response.status === 200) {
        console.log("res", response);
        toast.success("Location updated successfully");
      } else {
        console.log("Failed to update location");
        toast.error("Failed to update location");
      }
    } catch (error) {
      console.error("Error updating location", error);
      toast.error("Error updating location");
    }
  };

  const fetchScreenshotImage = async () => {
    try {
      setScreenshotImageLoading(true);
      const response = await fetch(screenShotPath);
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setScreenshotImage(url);
        setError(null);
        setScreenshotImageLoading(false);
      } else {
        throw new Error(
          `HTTP Error: ${response.status} - Unable to access the image`
        );
      }
    } catch (error) {
      setError(error.message);
      setScreenshotImageLoading(false);
    }
  };

  useEffect(() => {
    fetchScreenshotImage();
  }, [screenShotPath]);
  const handleRefresh = async () => {
    try {
      await fetchData();
      await clickToSendNotification("Refresh");
    } catch (error) {
      console.log(error);
    } finally {
      window.location.reload();
    }
  };

  const handleDayClick = (index) => {
    if (selectedDays.includes(index)) {
      setSelectedDays(selectedDays.filter((i) => i !== index));
    } else {
      setSelectedDays([...selectedDays, index]);
    }
  };

  const performHealthCheck = async () => {
    try {
      const response = await apiClient(accessToken).post(
        "/display/healthCheck",
        {
          businessId: currentBusiness.id,
          sellerId: sellerId,
          userId: userId,
          displayIds: [{ id: displayId, displayName: newDisplayName }],
        }
      );

      const healthData = response.data.healthCheckData[0];
      setHealthCheckData(healthData);

      const lastCheckIn = new Date(healthData.lastCheckIn);
      const currentTime = new Date();
      const timeDifference = currentTime - lastCheckIn;
      const oneHourInMillis = 60 * 60 * 1000;

      setButtonColor(timeDifference <= oneHourInMillis ? "green" : "red");
    } catch (error) {
      console.error("Error performing health check:", error);
      setButtonColor("");
    }
  };

  useEffect(() => {
    performHealthCheck();
    const intervalId = setInterval(performHealthCheck, 60 * 60 * 1000); // Check every hour

    return () => clearInterval(intervalId);
  }, [displayId, accessToken]);

  return (
    <>
      <div className="displays-bg-container">
        <Row className="p-2 align-items-center">
          <Col
            md={6}
            className="d-flex align-items-center gap-2"
            style={{ width: "auto", height: "50px" }}
          >
            {isEditingName ? (
              <>
                <input
                  type="text"
                  value={newDisplayName}
                  onChange={(e) => setNewDisplayName(e.target.value)}
                  className="px-2 py-[3px] mr-2 border tw-rounded-sm"
                  style={{ width: "auto", outline: "2px solid #0D6EFD" }}
                  autoFocus
                />
                <Button
                  onClick={handleUpdateName}
                  icon={<AiOutlineCheck />}
                  size="small"
                  className="mr-1"
                  title="Save"
                ></Button>
                <Button
                  onClick={handleNameCancel}
                  icon={<AiOutlineClose />}
                  size="small"
                  title="Cancel"
                ></Button>
              </>
            ) : (
              <>
                <h6 className="mb-0 mr-2">
                  Display Details - {newDisplayName || ""}
                </h6>
                <Button
                  onClick={handleEditNameStart}
                  icon={<BsPencilSquare />}
                  size="small"
                  title="Edit"
                ></Button>
              </>
            )}
          </Col>
        </Row>

        <div>
          <div className="mt-4 image-content-container ">
            <div className="cards-image-display">
              <div key={displayId} style={{ width: "100%" }}>
                <div className="tv-btn-box">
                  <div className="monitor-image-container">
                    {screenshotImage && !error ? (
                      <>
                        {isImageLoading && <div></div>}
                        <img
                          className="image-display"
                          src={screenshotImage}
                          alt=""
                          style={{ display: isImageLoading ? "none" : "block" }}
                          onLoad={() => setIsImageLoading(false)}
                        />
                      </>
                    ) : null}
                  </div>

                  <div className="all-button-container">
                    {/* Restart Button */}
                    <Tooltip title="Restart" color="#0071ca">
                      <div
                        className="button-container mb-0"
                        style={{ marginTop: "5px" }}
                      >
                        <CustomButton
                          disabledButton={false}
                          btnType="button"
                          BtnClassName={`custom-tv-btn $-btn`}
                          ClickEvent={(e) => clickToSendNotification("Restart")}
                          BtnTxt={<IoMdPower />}
                        />
                      </div>
                    </Tooltip>

                    {/* Screenshot Button */}
                    <Tooltip title="Take Screenshot" color="#0071ca">
                      <div className="button-container ">
                        <CustomButton
                          disabledButton={false}
                          btnType="button"
                          BtnClassName="custom-tv-btn"
                          ClickEvent={(e) =>
                            clickToSendNotification("screenshot")
                          }
                          BtnTxt={
                            <>
                              <MdOutlineCameraAlt />{" "}
                            </>
                          }
                        />
                      </div>
                    </Tooltip>

                    {/* Health Button */}
                    <Tooltip title="Health" color="#0071ca">
                      <div className="button-container ">
                        <CustomButton
                          disabledButton={false}
                          btnType="button"
                          BtnClassName="custom-tv-btn"
                          ClickEvent={(e) => clickToSendNotification("Checkin")}
                          BtnTxt={
                            <>
                              <BsHeartPulse />{" "}
                            </>
                          }
                          color={buttonColor}
                        />
                      </div>
                    </Tooltip>

                    {/* Edit Button */}
                    <Tooltip title="Edit" color="#0071ca">
                      <div className="button-container mb-0">
                        <CustomButton
                          disabledButton={false}
                          btnType="button"
                          BtnClassName="custom-tv-btn "
                          ClickEvent={handleShow}
                          BtnTxt={
                            <>
                              <MdEditDocument />{" "}
                            </>
                          }
                        />
                      </div>
                    </Tooltip>

                    {/* Refresh Button */}
                    <Tooltip title="Refresh" color="#0071ca">
                      <div className="button-container mb-0">
                        <CustomButton
                          disabledButton={false}
                          btnType="button"
                          BtnClassName="custom-tv-btn "
                          ClickEvent={(e) => clickToSendNotification("Refresh")}
                          BtnTxt={
                            <>
                              <MdOutlineRefresh />{" "}
                            </>
                          }
                        />
                      </div>
                    </Tooltip>

                    {/* Delete Button */}
                    <Tooltip title="Delete" color="#0071ca">
                      <div className="button-container mb-0">
                        <CustomButton
                          disabledButton={false}
                          btnType="button"
                          BtnClassName="custom-tv-btn"
                          //ClickEvent={handleDeactiveModal}
                          BtnTxt={
                            <>
                              <MdDelete />{" "}
                            </>
                          }
                        />
                      </div>
                    </Tooltip>

                    {/* Edit Location Button */}
                    <Tooltip title="Location" color="#0071ca">
                      <div className="button-container mb-0">
                        <CustomButton
                          disabledButton={false}
                          btnType="button"
                          BtnClassName="custom-tv-btn "
                          BtnTxt={
                            <>
                              <MdLocationOn />{" "}
                            </>
                          }
                          ClickEvent={() => setShowLocationModal(true)}
                        />
                      </div>
                    </Tooltip>
                    <Modal
                      show={showLocationModal}
                      onHide={() => setShowLocationModal(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Select Location</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Select
                          value={selectedLocation}
                          onChange={(e) => handleLocationChange(e.target.value)}
                        >
                          <option value="">Select Location</option>
                          {locationData
                            .filter(
                              (location) => location.id !== selectedLocation
                            )
                            .map((location) => (
                              <option key={location.id} value={location.name}>
                                {location.name}
                              </option>
                            ))}
                        </Form.Select>
                      </Modal.Body>
                      <Modal.Footer style={{ background: "#fff" }}>
                        <Button
                          variant="secondary"
                          onClick={() => setShowLocationModal(false)}
                        >
                          Close
                        </Button>
                        <Button variant="primary" onClick={handleSaveLocation}>
                          Save Location
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {/* Setting */}
                    <DisplaySettings
                      notificationStatus={notificationStatus}
                      setNotificationStatus={setNotificationStatus}
                      displayId={displayId}
                      accessToken={accessToken}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="preview-add-layout-search-container">
          <div className="preview-search-input-container-lay ">
            <AiOutlineSearch style={{ fontSize: "23px" }} />
            <input
              type="text"
              className="search-input"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-3 mb-3">
          {loadingLogs ? (
            <Skeleton count={5} height={40} />
          ) : (
            <CustomDataTable
              columns={columns}
              data={displayLogs}
              tableKey="displaysTable"
            />
          )}
        </div>
        <>
          <Modal
            className="custom-modal-size"
            centered
            show={showModal}
            backdrop="static"
            onHide={handleClose}
          >
            <Modal.Header
              closeButton
              style={{
                border: " 1px solid #D9D9D9",
              }}
            >
              <Modal.Title class="text-center">
                <div className="display-assignment-modl ">
                  <h6 className="headingmodal">
                    Update Assignments for {location} Display
                  </h6>
                  <p className="paramodal">
                    Please update assignments below and click 'Save Display'
                  </p>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="body-modal">
                <CustomButton
                  disabledButton={false}
                  btnType="button"
                  BtnClassName="add-assignment-buttons"
                  BtnTxt={<>Add Assignments</>}
                  ClickEvent={addAssignmentContainer}
                />

                {assignedlayout && assignedlayout.length > 0
                  ? assignedlayout.map((assignment, layoutIndex) => (
                      <div>
                        {" "}
                        {layoutIndex < assignedlayout.length && (
                          <button
                            className="xbtn"
                            onClick={() => {
                              handlePlusModalShow(
                                assignment.layoutId,
                                layoutIndex
                              );
                            }}
                          >
                            x
                          </button>
                        )}{" "}
                        <div
                          className="display-pop-container"
                          key={assignment.id || `newAssignment_${layoutIndex}`}
                        >
                          <div>
                            {libraryIds &&
                            libraryIds.length >= layoutIndex + 1 &&
                            libraryIds[layoutIndex].length > 0 &&
                            libraryIds[layoutIndex].some(
                              (item) => item.mediaType === "image"
                            ) ? (
                              (
                                <img
                                  className="modalpic"
                                  src={
                                    libraryIds[layoutIndex].find(
                                      (item) =>
                                        item.mediaType === "image" &&
                                        /\.(jpg|jpeg|png|webp|avif|gif|bmp|tiff|ico)$/.test(
                                          item.fileUrl
                                        )
                                    )?.fileUrl
                                  }
                                  alt=".."
                                />
                              ) || <Skeleton />
                            ) : (
                              <img
                                className="modalpic"
                                src={imageUrl}
                                alt=".."
                              />
                            )}
                          </div>
                          <div>
                            <div className="dropdown-container mt-2">
                              <label
                                htmlFor={`layoutName${layoutIndex}`}
                                className="dropdown-label"
                              >
                                Layout Name
                              </label>
                              <select
                                id={`layoutName${layoutIndex}`}
                                name={`layoutName${layoutIndex}`}
                                className="dropdown-select"
                                value={assignment.layoutId}
                                onChange={(e) => {
                                  const updatedAssignments = [
                                    ...assignedlayout,
                                  ];
                                  updatedAssignments[layoutIndex].layoutId =
                                    e.target.value;
                                  updatedAssignments[layoutIndex].name =
                                    e.target.options[
                                      e.target.selectedIndex
                                    ].text;
                                  setAssignedlayout(updatedAssignments);
                                  setError("");
                                }}
                              >
                                <option>{assignment.name}</option>
                                {layoutArr
                                  .filter(
                                    (eachItem) =>
                                      eachItem.name !== assignment.name
                                  )
                                  .map((filteredItem) => (
                                    <option
                                      key={filteredItem.id}
                                      value={filteredItem.id}
                                    >
                                      {filteredItem.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <br />
                            <div>
                              <label htmlFor="start-time">
                                Set time limit to play this layout
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "40px",
                                  margin: "10px",
                                }}
                              >
                                <input
                                  id={`start-time-${layoutIndex}`}
                                  type="time"
                                  value={assignment.startTime}
                                  onChange={(e) => {
                                    const updatedAssignments = [
                                      ...assignedlayout,
                                    ];
                                    updatedAssignments[layoutIndex].startTime =
                                      e.target.value;
                                    setAssignedlayout(updatedAssignments);
                                    setError("");
                                  }}
                                  min="00:00"
                                  max="23:59"
                                  step="3600"
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                                <input
                                  id={`end-time-${layoutIndex}`}
                                  type="time"
                                  value={assignment.endTime}
                                  onChange={(e) => {
                                    const updatedAssignments = [
                                      ...assignedlayout,
                                    ];
                                    updatedAssignments[layoutIndex].endTime =
                                      e.target.value;
                                    setAssignedlayout(updatedAssignments);
                                    setError("");
                                  }}
                                  min="00:00"
                                  max="23:59"
                                  step="3600"
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              </div>
                              <div className="mb-2">
                                Choose days of the week
                              </div>
                              <div className="d-flex tw-justify-between mb-2">
                                {days.map((day, dayIndex) => (
                                  <button
                                    key={dayIndex}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                    }}
                                    className={`tw-w-7 tw-h-7 tw-text-sm rounded ${
                                      assignment.selectedDays.includes(dayIndex)
                                        ? "bg-primary text-white border border-primary"
                                        : "tw-bg-gray-100 text-primary border border-primary unselected"
                                    }`}
                                    onClick={() =>
                                      handleDayClickForAssigned(
                                        layoutIndex,
                                        dayIndex
                                      )
                                    }
                                  >
                                    {day}
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}

                {assignmentContainers.map((assignmentContainer, index) => (
                  <div>
                    <button
                      className="xbtn"
                      onClick={() => {
                        const updatedAssignments = assignmentContainers.filter(
                          (_, i) => i !== index
                        );
                        setAssignmentContainers(updatedAssignments);
                      }}
                    >
                      X
                    </button>

                    <div
                      className="display-pop-container"
                      key={assignmentContainer.id}
                    >
                      <div>
                        <img className="modalpic" src={imageUrl} alt=".." />
                      </div>
                      <div>
                        <div className="dropdown-container mt-2">
                          <label
                            htmlFor={`layoutName${index}`}
                            className="dropdown-label"
                          >
                            Layout Name
                          </label>
                          <select
                            id={`layoutName${index}`}
                            name={`layoutName${index}`}
                            className="dropdown-select"
                            value={assignmentContainer.layoutId}
                            onChange={(e) => {
                              const updatedContainers = [
                                ...assignmentContainers,
                              ];
                              updatedContainers[index].layoutId =
                                e.target.value;
                              setAssignmentContainers(updatedContainers);
                            }}
                          >
                            <option value="">Select Layout</option>
                            {layoutArr.length > 0 &&
                              layoutArr.map((layout) => (
                                <option key={layout.id} value={layout.id}>
                                  {layout.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <br />
                        <div>
                          <label htmlFor="start-time">
                            Set time limit to play this layout
                          </label>
                          <div
                            style={{
                              display: "flex",
                              gap: "40px",
                              margin: "10px",
                            }}
                          >
                            <input
                              id={`start-time-${index}`}
                              type="time"
                              value={assignmentContainer.selectedStartTime}
                              onChange={(e) => {
                                const updatedContainers = [
                                  ...assignmentContainers,
                                ];
                                updatedContainers[index].selectedStartTime =
                                  e.target.value;
                                setAssignmentContainers(updatedContainers);
                                setError("");
                              }}
                              min="00:00" // Set minimum time to 00:00
                              max="23:59" // Set maximum time to 23:59
                              step="3600"
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            />
                            <input
                              id={`end-time-${index}`}
                              type="time"
                              value={assignmentContainer.selectedEndTime}
                              onChange={(e) => {
                                const updatedContainers = [
                                  ...assignmentContainers,
                                ];
                                updatedContainers[index].selectedEndTime =
                                  e.target.value;
                                setAssignmentContainers(updatedContainers);
                                setError("");
                              }}
                              min="00:00" // Set minimum time to 00:00
                              max="23:59" // Set maximum time to 23:59
                              step="3600"
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            />
                          </div>
                          <div className="mb-2">Choose days of the week</div>
                          <div className="d-flex tw-justify-between mb-2">
                            {days.map((day, dayIndex) => (
                              <button
                                key={dayIndex}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                                className={`tw-w-7 tw-h-7 tw-text-sm rounded ${
                                  assignmentContainer.selectedDays.includes(
                                    dayIndex
                                  )
                                    ? "bg-primary text-white border border-primary"
                                    : "tw-bg-gray-100 text-primary border border-primary unselected"
                                }`}
                                onClick={() => {
                                  const updatedContainers = [
                                    ...assignmentContainers,
                                  ];
                                  const currentDays =
                                    updatedContainers[index].selectedDays;
                                  if (currentDays.includes(dayIndex)) {
                                    updatedContainers[index].selectedDays =
                                      currentDays.filter((d) => d !== dayIndex);
                                  } else {
                                    updatedContainers[index].selectedDays = [
                                      ...currentDays,
                                      dayIndex,
                                    ];
                                  }
                                  setAssignmentContainers(updatedContainers);
                                }}
                              >
                                {day}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* {error && <p className="text-danger">{error}</p>} */}
              </div>
            </Modal.Body>
            <Modal.Footer style={{ background: "#fff" }}>
              <div className="savebtn-contaier">
                <CustomButton
                  disabledButton={false}
                  btnType="button"
                  BtnClassName="add-layout-buttons"
                  BtnTxt={<>Save Display</>}
                  ClickEvent={handleSaveDisplay}
                />
              </div>
            </Modal.Footer>
          </Modal>
          <Modal
            size="sm"
            animation
            show={showPlusModal}
            onHide={handlePlusClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Header>
              <div style={{ width: "100%" }}>
                <h4 style={{ fontSize: "16px" }}>Do you want to delete it ?</h4>
              </div>
            </Modal.Header>
            <Modal.Body>
              <Row className="">
                <Col
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <CustomButton
                    BtnTxt={<>Yes</>}
                    BtnClassName="yes-modal-btn"
                    ClickEvent={() => {
                      handleDeleteLayout(layoutsId, displayId, deletionIndex);
                      setPlusModal(false);
                    }}
                  />
                  <CustomButton
                    BtnTxt={<>No</>}
                    BtnClassName="yes-modal-btn"
                    ClickEvent={() => setPlusModal(false)}
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>

          <Modal
            size="sm"
            animation
            show={deactiveShowModal}
            onHide={handleDeactiveModal}
            // centered
            backdrop="static"
          >
            <Modal.Header>
              <div style={{ width: "100%" }}>
                <h4 style={{ fontSize: "16px", textAlign: "center" }}>
                  This is a active Display. Are you sure you want to deactiatte
                  it ?
                </h4>
              </div>
            </Modal.Header>
            <Modal.Body>
              <Row className="">
                <Col
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <CustomButton
                    BtnTxt={<>Yes</>}
                    BtnClassName="yes-modal-btn"
                    ClickEvent={() => {
                      clickToDeactive();
                      setDeactiveShowModal(false);
                    }}
                  />
                  <CustomButton
                    BtnTxt={<>No</>}
                    BtnClassName="yes-modal-btn"
                    ClickEvent={() => setDeactiveShowModal(false)}
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
          <Modal
            size="sm"
            animation
            show={showrefreshmodal}
            onHide={handlerefreshmodal}
            // centered
            backdrop="static"
          >
            <Modal.Header>
              <div style={{ width: "100%" }}>
                <h4 style={{ fontSize: "16px", textAlign: "center" }}>
                  Do you want to refresh the display ?
                </h4>
              </div>
            </Modal.Header>
            <Modal.Body>
              <Row className="">
                <Col
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <CustomButton
                    BtnTxt={<>Yes</>}
                    BtnClassName="yes-modal-btn"
                    ClickEvent={() => {
                      handleRefresh();
                      setShowrefreshmodal(false);
                    }}
                  />
                  <CustomButton
                    BtnTxt={<>No</>}
                    BtnClassName="yes-modal-btn"
                    ClickEvent={() => setShowrefreshmodal(false)}
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
          <Toaster position="bottom-right" className="tw-mr-4" />
        </>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  userId: state.userProfile.userId,
  sellerId: state.sellerIdReducer,
  currentBusiness: state.currentBusiness,
  ...state,
});

export default connect(mapStateToProps)(DisplayPreview);
