import React from "react";
import CustomButton from "../../Button";

export default function ImageNegativeScreen({ handleAddMoreClick }) {
  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-[75vh] tw-w-full tw-overflow-hidden">
      <img src="/NegativeScreens/Library.png" alt="" className="tw-w-[30rem]" />

      {/* Add More btn */}
      <div className="tw-mt-8">
        <CustomButton
          disabledButton={false}
          btnType="button"
          BtnClassName="add-layout-button"
          BtnTxt={<>Add</>}
          ClickEvent={handleAddMoreClick}
        />
      </div>
    </div>
  );
}
