import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import TextBox from "../../components/TextBox";
import { apiClient } from "../../components/apiClient";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import CustomButton from "../../components/Button";
import {  toast } from "react-toastify";

const ViewStore = ({
  data,
  disableField,
  showViewStore,
  handleCloseViewStore,
  sellerId,
  state,
}) => {
  const [locationData, setLocationData] = useState({});
  const logUserId = state.userProfile.userId;
  let [errorList, setErrorList] = useState({
    name: false,
    address: false,
    status: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [reqErr, setReqErr] = useState(false);

  useEffect(() => {
    if (data && data.id) {
      apiClient(state.accessToken)
        .get("/store/storedetails/" + data.id)
        .then((res) => {
          setLocationData(res.data.data);
        })
        .catch(() => {});
    }
  }, [data, state.accessToken]);
  
  const resetField = (val = null) => {
    setLocationData({});
    setErrorList({ name: "", address: "", status: "" });
    val ? handleCloseViewStore(true) : handleCloseViewStore();
  };

  const handleChange = (e) => {
    setLocationData({ ...locationData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let hasError = false;
    for (const key in locationData) {
      if (locationData[key].trim() == "") {
        errorList[key] = key + " is Required";
        hasError = true;
        setReqErr(true);
      } else {
        setErrorList({ ...errorList, [key]: false });
      }
    }
    if (!hasError) {
      try {
        setReqErr(false);
        let params = {
          id: locationData.id,
          name: locationData.name,
          address: locationData.address,
          status: locationData.status,
          userId: logUserId,
        };
        await apiClient(state.accessToken)
          .put(`store/update/${sellerId}`, params)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Location Updated Successfully");
            }
            setIsLoading(false);
            resetField(true);
          })
          .catch((err) => {
            toast.err("Something went wrong");
          });
      } catch (err) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={showViewStore}
        onHide={resetField}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div style={{ width: "100%" }}>
            <h6 style={{ margin: 0, alignItems: "center" }}>
              {disableField ? "View Location" : "Edit Location"}
            </h6>
          </div>
        </Modal.Header>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <TextBox
              txtBoxLabel={"Name"}
              txtBoxType={"text"}
              txtBoxPH={"Name"}
              txtBoxValue={locationData?.name}
              disabled={disableField}
              changeEvent={handleChange}
              txtBoxName={"name"}
            />
            {errorList.name && <span className="error">{errorList.name}</span>}
            <TextBox
              txtBoxLabel={"Address"}
              txtAs={"textarea"}
              txtBoxType={"textarea"}
              txtBoxPH={"Address"}
              txtBoxValue={locationData?.address}
              disabled={disableField}
              changeEvent={handleChange}
              txtBoxName={"address"}
            />

            {errorList.address && (
              <span className="error">{errorList.address}</span>
            )}
            <Form.Label>Status</Form.Label>
            <br />
            <Form.Select
              name="status"
              value={locationData?.status}
              disabled={disableField}
              onChange={handleChange}
            >
              <option value={1}>Active</option>
              <option value={2}>InActive</option>
            </Form.Select>
            {errorList.status && (
              <span className="error">{errorList.status}</span>
            )}
          </Modal.Body>

          {!disableField && (
            <>
              <Modal.Footer style={{background:"#fff"}}>
                {reqErr && (
                  <span className="error">Please Fill Required Fields</span>
                )}
                <CustomButton
                  disabledButton={isLoading}
                  btnType={"submit"}
                  BtnTxt={isLoading ? "Progress" : "Save"}
                />
              </Modal.Footer>
            </>
          )}
        </form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sellerId: state.sellerIdReducer,
    state: state,
  };
};

export default connect(mapStateToProps)(ViewStore);
