import { createStore } from "redux";
import rootReducer from "./authReducer";

const EXPIRATION_TIME = 3600 * 1000; // 1 hour in milliseconds

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }

    const { state, expiration } = JSON.parse(serializedState);

    // Check if the state has expired
    if (expiration && Date.now() > expiration) {
      localStorage.removeItem("state"); // Clear expired state
      return undefined;
    }

    return state;
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify({
      state,
      expiration: Date.now() + EXPIRATION_TIME,
    });
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // Handle write errors (if needed)
  }
};

const store = createStore(rootReducer, loadState());

console.log("Initial State:", store.getState());

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
