import React, { useState, useEffect } from "react";

// import ReactPlayer from "react-player";
import { connect } from "react-redux";
import "./Imageselection.css";
import ReactPaginate from "react-paginate";
// import LayoutsView from '../LayoutsViews'
// import TextBox from "../../../components/TextBox";
import { Bars } from "react-loader-spinner";
import { DateFormat } from "../../../components/DateFormat";
import { Card, Avatar, Dropdown, Menu } from "antd";
import CustomDataTable from "../../../components/DataTable";
import { BsThreeDotsVertical } from "react-icons/bs";

const { Meta } = Card;
const ImagesSelection = ({
  accessToken,
  onDragStart,
  imagesfiles,
  state,
  view,
  userProfile,
  Profileinfo,
  searchTerm,
  setSearchTerm,
  onAddToOverlay,
}) => {
  // console.log("currentView", view);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const imagesPerPage = 6;
  const defaultUser = {
    userName: Profileinfo?.name,
    userEmail: Profileinfo?.email,
    userRole: "Admin",
    userProfile:
      Profileinfo?.fileLocation ||
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png",
  };
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const handleAddToLayout = (media) => {
    if (onAddToOverlay) {
      onAddToOverlay(media);
    }
    setOpenDropdownId(null);
  };

  const columns = [
    {
      name: "Display Image",
      selector: (param) => (
        <div draggable onDragStart={(e) => handleDragStart(e, param)}>
          <Avatar src={param.fileUrl} />
        </div>
      ),
      sortable: true,
    },
    {
      name: "File Name",
      selector: (param) => param.fileName,
      sortable: true,
    },
    {
      name: "Uploaded By ",
      selector: () => userProfile?.name,
      sortable: true,
    },
    {
      name: "Date of Upload",
      selector: (param) => DateFormat(param.createdAt),
      sortable: true,
    },
    {
      name: "File Size",
      selector: (param) => `${param.size} MB`,
      sortable: true,
    },
  ];

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    fetchImages();
  }, []);

  const imageMedia = imagesfiles;
  const mediaType = "image";

  const indexOfLastImage = (currentPage + 1) * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = imageMedia.slice(indexOfFirstImage, indexOfLastImage);

  let nextId = 1;

  const handleDragStart = (e, media) => {
    const { id, fileUrl } = media;
    const newItem = { id, fileUrl, mediaType, uniqueId: nextId++ };
    e.dataTransfer.setData(
      "application/reactflow",
      JSON.stringify({ item: newItem, index: -1 })
    );
    e.dataTransfer.effectAllowed = "move";
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        setTimeout(async () => {
          if (searchTerm === "") {
            setSearchResults(imagesfiles); // If search term is empty, show all images
          } else {
            // Filter images based on search term
            const filteredImages = imagesfiles.filter((image) =>
              image.fileName.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchResults(filteredImages);
          }
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error("Error fetching images:", error.message);
        setLoading(false);
      }
    };
    fetchImages();
  }, [searchTerm, imagesfiles]);
  //console.log("dataSource", currentImages);
  const [isFullName, setIsFullName] = useState(false);

  const getDisplayName = (fileName) => {
    const words = fileName.split(" ");
    if (isFullName || words.length <= 2) {
      return fileName;
    }
    return `${words.slice(0, 2).join(" ")}...`;
  };

  const handleNameClick = () => {
    setIsFullName(!isFullName);
  };
  return (
    <>
      {/* <LayoutsView /> */}
      <div className="paddingtop"></div>
      {loading ? (
        <div className="loading-container">
          <Bars
            height="40"
            width="40"
            color="#48cae4"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          {view === "list" ? (
            <CustomDataTable
              columns={columns}
              data={searchTerm ? searchResults : imagesfiles}
              pagination={false}
            />
          ) : (
            <>
              {searchTerm ? (
                <div
                  className="d-flex flex-wrap example-parent"
                  style={{ marginTop: "-45px" }}
                >
                  {searchResults.map((media) => (
                    <div
                      key={media.id}
                      className="p-1 col-lg-4"
                      style={{
                        backgroundColor: " #ffffff",
                        border: "0",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Card
                        className="bdr"
                        style={{
                          width: 180, // Adjust width as per your requirement
                          height: 220, // Adjust height as per your requirement
                          margin: 0,
                          display: "contents",
                        }}
                        cover={
                          <div>
                            <span
                              style={{
                                fontWeight: "500",
                                zIndex: "1",
                                fontFamily: "Poppins",
                              }}
                            >
                              {media.fileName.length < 0
                                ? media.fileName
                                : media.fileName.substring(0)}
                            </span>
                            <div className="aspect-ratio-container">
                              <img
                                src={media.fileUrl}
                                className="cardd-img-top img-fluid"
                                alt={media.fileName}
                              />
                            </div>
                          </div>
                        }
                        draggable
                        onDragStart={(e) => handleDragStart(e, media)}
                      >
                        <Meta
                          avatar={<Avatar src={defaultUser.userProfile} />}
                          description={
                            <div>
                              <p
                                className="creste-size-desc"
                                style={{
                                  fontSize: "9px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                <strong> {userProfile?.name}</strong>
                              </p>
                              <p
                                className="creste-size-desc"
                                style={{
                                  fontSize: "9px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {DateFormat(media.createdAt)}, Size:{" "}
                                {media.size} MB
                              </p>
                            </div>
                          }
                        />
                      </Card>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className="d-flex example-parent"
                  style={{ marginTop: "-45px" }}
                >
                  {currentImages.map((media) => (
                    <div
                      key={media.id}
                      className="p-1 col-lg-4"
                      style={{
                        backgroundColor: " #ffffff",
                        border: "0",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        margin: "0 10px 20px 0",
                        flex: "0 0 calc(33.33% - 10px)",
                      }}
                      draggable
                      onDragStart={(e) => handleDragStart(e, media)}
                    >
                      <Card
                        style={{
                          margin: 0,
                          display: "contents",
                        }}
                        cover={
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                key={media.id}
                                className={!isFullName ? "ellipsis" : ""}
                                style={{
                                  fontWeight: "500",
                                  zIndex: "1",
                                  fontFamily: "Poppins",
                                  display: "flex",
                                }}
                                onClick={handleNameClick}
                              >
                                {getDisplayName(media.fileName)}
                              </span>
                              <Dropdown
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                      key="1"
                                      onClick={() => handleAddToLayout(media)}
                                    >
                                      Add Layout
                                    </Menu.Item>
                                  </Menu>
                                }
                                trigger={["click"]}
                                visible={openDropdownId === media.id}
                                onVisibleChange={(visible) => {
                                  if (visible) {
                                    setOpenDropdownId(media.id);
                                  } else {
                                    setOpenDropdownId(null);
                                  }
                                }}
                                overlayClassName="image-custom-dropdown"
                              >
                                <span onClick={(e) => e.preventDefault()}>
                                  <BsThreeDotsVertical />
                                </span>
                              </Dropdown>
                            </div>

                            <div className="aspect-ratio-container">
                              <img
                                src={media.fileUrl}
                                className="cardd-img-top img-fluid"
                                alt={media.fileName}
                              />
                            </div>
                          </div>
                        }
                       
                      >
                        <Meta
                          avatar={<Avatar src={defaultUser.userProfile} />}
                          description={
                            <div>
                              <p
                                className="creste-size-desc"
                                style={{
                                  fontSize: "9px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                <strong> {userProfile?.name}</strong>
                                <br />
                                {DateFormat(media.createdAt)}, Size:{" "}
                                {media.size} MB
                              </p>
                            </div>
                          }
                        />
                      </Card>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {view !== "list" && (
            <div className="mt-3">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={Math.ceil(imageMedia.length / imagesPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  ...state,
});

export default connect(mapStateToProps)(ImagesSelection);
