const ChangePassword = () => {
  return (
    <>
      <div className="tw-flex   tw-items-start tw-w-full tw-justify-center tw-h-screen tw-bg-[#f3f3f3f0]">
        <div className="tw-w-full tw-max-w-5xl tw-px-4 tw-mt-20">
          <div className="tw-rounded-xl tw-shadow-lg tw-w-full tw-bg-white tw-p-4">
            <div className="tw-px-8 tw-py-8 tw-flex tw-items-center tw-border-b tw-border-gray-200 tw-border-l-4 tw-ml-[-18px] tw-border-l-[#0052CC] tw-relative">
              <div className="absolute left-0 top-0 bottom-0 bg-ln-blue w-2 h-20"></div>
              <div className="tw-pl-2">
                <p className="tw-text-2xl tw-font-semibold tw-text-black">
                  Change Password
                </p>
              </div>
            </div>

            <div className="tw-px-8 tw-py-8 tw-flex">
              <div className="tw-flex tw-justify-start tw-w-full">
                <div className="tw-w-1/2 tw-mr-20 tw-flex tw-flex-col tw-gap-6">
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Current Password
                    </label>
                    <input
                      type="text"
                      className="tw-mt-1 tw-px-2 input-outline tw-bg-white tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      Re-type Password
                    </label>
                    <input
                      type="text"
                      className="tw-mt-1 tw-px-2 input-outline tw-bg-white tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                </div>
                <div className="tw-w-1/2">
                  <div>
                    <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                      New Password
                    </label>
                    <input
                      type="text"
                      className="tw-mt-1 tw-px-2 input-outline tw-bg-white tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-px-8 tw-py-8 tw-flex tw-justify-end tw-border-t tw-border-gray-200">
              <button
                className="tw-bg-white tw-text-black tw-px-4 tw-py-2 tw-rounded tw-border tw-border-[#CED4DA] tw-mr-2"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Cancel
              </button>

              <button
                className="tw-bg-[#0d6efd] tw-text-white tw-px-4 tw-py-2 tw-rounded tw-border tw-border-[#0d6efd]"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
