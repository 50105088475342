import React, { useState, } from "react";
import Modal from "react-bootstrap/Modal";
import TextBox from "../../components/TextBox";
import { apiClient } from "../../components/apiClient";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import CustomButton from "../../components/Button";

import { Toaster,toast } from "sonner";
import './Layouts.css'
const ViewLayout = ({
  data,
  disableField,
  showViewLayout,
  handleCloseViewLayout,
  state,
}) => {
  const [layoutData, setLayoutData] = useState({});
  const logUserId = state.userProfile.userId;
  let [errorList, setErrorList] = useState({
    name: false,
    status: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [reqErr, setReqErr] = useState(false);

  // useEffect(() => {
  //   if (data && data.id) {
  //     apiClient(state.accessToken)
  //       .get(`/layout/layoutdetails/${data.id}`)
  //       .then((res) => {
  //         if (res && res.data && res.data.data) {
  //           setLayoutData(res.data.data);
  //         } else {
  //           console.error("Error: No data received from API response");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching layout details:", error);
  //       });
  //   } else {
  //     console.error("Error: Invalid data or data.id is missing");
  //   }
  // }, [data]);
  
  const resetField = (val = null) => {
    setLayoutData({});
    val ? handleCloseViewLayout(true) : handleCloseViewLayout();
  };

  const handleChange = (e) => {
    setLayoutData({ ...layoutData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   // console.log("Form submitted"); 
    setIsLoading(true);
    let hasError = false;
    for (const key in layoutData) {
      if (layoutData[key].trim() === "") {
        setErrorList({ ...errorList, [key]: false });
        hasError = true;
        setReqErr(true);
      } else {
        setErrorList({ ...errorList, [key]: false });
      }
    }
    if (!hasError) {
      try {
        setReqErr(false);
        let params = {
          id: layoutData.id,
          name: layoutData.name,
          status: layoutData.status,
          userId: logUserId,
        };
        await apiClient(state.accessToken)
          .put("layout/update", params) 
          .then((res) => {
            if(res.status === 200) {
              toast.success("Layout Updated Successfully");
            }
            setIsLoading(false);
            resetField(true);
          })
          .catch((err) => {
            toast.error("Something went wrong");
          });
      } catch (err) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

//  console.log("isLoading:", isLoading);

  return (
    <>
      <Modal
        show={showViewLayout}
        onHide={resetField}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div style={{ width: "100%" }}>
            <h6 style={{ margin: 0, alignItems: "center" }}>View Layout</h6>
          </div>
        </Modal.Header>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
          <div className="d-flex justify-content-center" >
              <img style={{ border: '1px solid #ccc', borderRadius: '50%', width: '6rem', height: '6rem' }} src={  "logo192.png"} alt="profile" />
            </div>
            <TextBox
              txtBoxLabel={"Name"}
              txtBoxType={"text"}
              txtBoxPH={"Name"}
              txtBoxValue={layoutData?.name}
              disabled={disableField}
              changeEvent={handleChange}
              txtBoxName={"name"}
            />
            
            <Form.Label>Status</Form.Label>
            <Form.Select
              name="status"
              value={layoutData?.status}
              disabled={disableField}
              onChange={handleChange}
              className="custom-select"
            >
              <option value={1}>Active</option>
              <option value={2}>InActive</option>
            </Form.Select>
          </Modal.Body>

          {!disableField && (
            <>
              <Modal.Footer>
                {reqErr && (
                  <span className="error">Please Fill Required Fields</span>
                )}
                <CustomButton
                  disabledButton={isLoading}
                  btnType={"submit"}
                  BtnTxt={isLoading ? "Progress" : "SAVE"}
                />
              </Modal.Footer>
              <Toaster position="bottom-right" className="tw-mr-4"  />
            </>
          )}
        </form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(ViewLayout);
