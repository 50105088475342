import React, { useState, useEffect, useCallback } from "react";
import HomePage from "./pages/HomePage";
import { apiClient } from "./components/apiClient";
import {
  userRoutes,
  accessToken,
  userProfile,
  login,
  RefreshToken,
  setAllTokens,
} from "./redux/action";
import { useDispatch } from "react-redux";
import { Bars } from "react-loader-spinner";

import Cookies from "js-cookie";
import { UserapiClient } from "./components/userManagementApiclient";

const Main = ({ currentAuthenticatedUser }) => {
  const [loading, setLoading] = useState(true);
  const [tokens, setTokens] = useState({});
  const [mountedTime, setMountedTime] = useState(null);
  const [isUserActive, setIsUserActive] = useState(false);

  const ERROR_MESSAGE = "You don't have access to this page.";

  const dispatch = useDispatch();

  const retrieveAllCookies = () => {
    const allCookies = Cookies.get();

    const decodedCookies = Object.fromEntries(
      Object.entries(allCookies).map(([key, value]) => [
        key,
        decodeURIComponent(value),
      ])
    );

    // console.log("All cookies:", decodedCookies);

    return decodedCookies;
  };

  const clearAllCookies = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
    window.location.href = `/?autherror=${encodeURIComponent(ERROR_MESSAGE)}`;
  };

  const fetchBusiness = useCallback(
    async (userId , idToken) => {
      try {
        
        const userClient = UserapiClient(idToken);
        const response = await userClient.get(`/businesses/${userId}`);
        console.log("Business response:", response);

        if (response.status === 200) {
          if (response.data.businesses.length === 0) {
            // User doesn't have access to any businesses
            console.log("User doesn't have access to any businesses");
            clearAllCookies();
            return;
          }

          const business = response.data.businesses[0];

          // Check if the current user is an admin
          const currentUser = business.users.find(
            (user) => user.userId === userId
          );

          if (currentUser && currentUser.role === "admin") {
            // User is an admin, allow access to HomePage without checking isActive
            setIsUserActive(true);
          } else if (currentUser && currentUser.isActive) {
            // Non-admin user is active, allow access to HomePage
            setIsUserActive(true);
          } else {
            // User is not active or not found, clear cookies and reload the page
            console.log("User is not active or not found");
            clearAllCookies();
          }
        } else {
          console.log("Error fetching business information");
        }
      } catch (error) {
        console.error("Error fetching business:", error);
      }
    },
    [tokens.idToken]
  );

  const getuserRoutes = useCallback(async (idToken) => {
    const client = apiClient(idToken);

    try {
      const geruserroutes = await client.get("/user/getroutes");

      if (geruserroutes.status === 200) {
        dispatch(userRoutes(geruserroutes.data.userRoutes));
        dispatch(userProfile(geruserroutes.data.user));

        // Get the userId from the response
        const userId = geruserroutes.data.user.userId;

        // Fetch the business information
        await fetchBusiness(userId , idToken);
      } else {
        console.log("User routes not taken");
      }
    } catch (error) {
      console.error("Error fetching user routes:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const retrieveTokens = useCallback(async () => {
    const tokenTypes = ["idToken", "accessToken", "refreshToken"];
    const extractedTokens = {};

    try {
      dispatch(login(true));
      const result = await retrieveAllCookies();
      //console.log("result", result);

      for (const tokenType of tokenTypes) {
        const cookieRegex = new RegExp(
          `CognitoIdentityServiceProvider\\.([^\\.]+)\\.([^\\.]+)\\.${tokenType}$`
        );
        const token = Object.keys(Cookies.get()).find((cookieName) =>
          cookieRegex.test(cookieName)
        );
        //console.log("toeknns", token);
        if (token) {
          const [, userPoolId, uuid] = cookieRegex.exec(token);
          const tokenValue = Cookies.get(token);
          // document.cookie = `${tokenType}=${tokenValue}; domain=localhost; path=/; secure; SameSite=None`;

          extractedTokens[tokenType] = Cookies.get(token);
        }
      }

      //console.log("extracted tokens", extractedTokens);
      if (extractedTokens.idToken) {
        dispatch(accessToken(extractedTokens.idToken));
        // console.log("extracted tokens", extractedTokens);
        dispatch(setAllTokens(extractedTokens));
        dispatch(RefreshToken(extractedTokens.refreshToken));
        setTokens(extractedTokens);
        refreshTokensPeriodically(extractedTokens.refreshToken);
      }
    } catch (error) {
      console.error("Error retrieving tokens:", error);
    }
  }, [dispatch, getuserRoutes]);

  useEffect(() => {
    const currentTime = new Date().getTime();
    // console.log("current time", currentTime);
    setMountedTime(currentTime);
    retrieveTokens();
    retrieveAllCookies();
  }, [retrieveTokens]);

  const refreshTokens = async (refreshToken) => {
    const CognitoIdentityServiceProvider = require("aws-sdk/clients/cognitoidentityserviceprovider");
    const cognitoIdentity = new CognitoIdentityServiceProvider({
      region: "ap-southeast-2",
    });
    const clientId = process.env.REACT_APP_AMPLIFY_WEBCLIENT_ID;
    const userSub = "userSub";
    const params = {
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: clientId,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };

    try {
      const response = await cognitoIdentity.initiateAuth(params).promise();

      const newAccessToken = response.AuthenticationResult.AccessToken;
      const newIdToken = response.AuthenticationResult.IdToken;

      const cookieDomain = "yourdomain.com"; // Replace with your actual domain
      const accessTokenCookie = `CognitoIdentityServiceProvider.${clientId}.${userSub}.accessToken=${newAccessToken}; Domain=${cookieDomain}; Path=/;`;
      const idTokenCookie = `CognitoIdentityServiceProvider.${clientId}.${userSub}.idToken=${newIdToken}; Domain=${cookieDomain}; Path=/;`;

      document.cookie = accessTokenCookie;
      document.cookie = idTokenCookie;
      dispatch(login(true));
      dispatch(accessToken(newIdToken));
      dispatch(
        setAllTokens({
          ...tokens,
          idToken: newIdToken,
          accessToken: newAccessToken,
        })
      );
    } catch (error) {
      console.log("Error Response:", error.response);
      throw error;
    }
  };

  const refreshTokensPeriodically = useCallback(
    async (refreshToken) => {
      while (true) {
        try {
          //console.log("refresh token value", refreshToken);

          await refreshTokens(refreshToken);

          await delay(50 * 60 * 1000); // 50 minutes
        } catch (error) {
          console.error("Error refreshing tokens:", error);
        }
      }
    },
    [refreshTokens]
  );

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (tokens.idToken && mountedTime) {
      const timeDifference = new Date().getTime() - mountedTime;
      getuserRoutes(tokens.idToken);
      const remainingTime =
        50 * 60 * 1000 - (timeDifference % (50 * 60 * 1000));

      const initialTimeout = setTimeout(() => {
        refreshTokensPeriodically(tokens.refreshToken);

        const intervalId = setInterval(() => {
          console.log("refreshing token", tokens.refreshToken);
          refreshTokensPeriodically(tokens.refreshToken);
        }, 50 * 60 * 1000);

        return () => clearInterval(intervalId); // Clear interval
      }, remainingTime);

      return () => {
        clearTimeout(initialTimeout); // Clear the initial timeout
      };
    }
  }, [tokens.idToken, mountedTime, refreshTokensPeriodically, getuserRoutes]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          <Bars
            height={40}
            width={40}
            color="#48cae4"
            ariaLabel="bars-loading"
            visible={true}
          />
        </div>
      ) : (
        isUserActive && <HomePage />
      )}
    </>
  );
};

export default Main;
