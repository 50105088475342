import React, { useState, useEffect } from "react";

import "./dashboard.css";
import { Button } from "antd";
import "react-circular-progressbar/dist/styles.css";
import logo from "../../assets/logo192.png";
import { Menu, Card } from "antd";
import { IoImageOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { apiClient } from "../../components/apiClient";
import CustomDataTable from "../../components/DataTable";
import firstImage from "../../assets/image 49.svg";
//import { FaArrowRightLong } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
//import { AiOutlineLoading3Quarters } from "react-icons/ai";
import secondImage from "../../assets/image 51.svg";
import thirdImage from "../../assets/image 48.svg";
import lastImage from "../../assets/image 52.svg";
import { Col, Row } from "react-bootstrap";
import CustomDropDown from "../../components/CustomDropDown";
const imageUrl = require("../../assets/defaultDisplay.png");

const Dashboard = ({ accessToken, display, userProfile, sellerId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [layoutCount, setLayoutCount] = useState(null);
  const [Imagesandvideoscount, setImagesandvideoscount] = useState(null);
  const [Displaycount, setDisplaycount] = useState(null);
  const [searchFilter, setSearchFilter] = useState([]);
  const [MostUsedLayouts, setMostUsedLayouts] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [screenshotImages, setScreenshotImages] = useState([]);

  useEffect(() => {
    const getDashboardData = () => {
      setIsLoading(true);
      apiClient(accessToken)
        .get(`dashboard/mediasize/${sellerId}`)
        .then((res) => {
          setIsLoading(false);
          console.log("Dashboard Media Data:", res.data);
          setDashboardData(res.data);
        })
        .catch((err) => {
          console.error("Error fetching dashboard data:", err);
          setIsLoading(false);
        });
    };

    const getDisplaycount = () => {
      setIsLoading(true);
      apiClient(accessToken)
        .get(`dashboard/devicecount/${sellerId}`)
        .then((res) => {
          setIsLoading(false);
          setDisplaycount(res.data);
        })
        .catch((err) => {
          console.error("Error fetching devicecount data:", err);
          setIsLoading(false);
        });
    };

    const getLayoutCount = () => {
      setIsLoading(true);
      apiClient(accessToken)
        .get(`dashboard/layoutcount/${sellerId}`)
        .then((res) => {
          setIsLoading(false);
          console.log("Most Usedjtty Data:", res.data);
          setLayoutCount(res.data);
        })
        .catch((err) => {
          console.error("Error fetching layout count data:", err);
          setIsLoading(false);
        });
    };

    const getImagesvideoscount = () => {
      setIsLoading(true);
      apiClient(accessToken)
        .get(`dashboard/library/${sellerId}`)
        .then((res) => {
          setIsLoading(false);
          // console.log('Most Used Layouts Data:', res.data);
          setImagesandvideoscount(res.data);
        })
        .catch((err) => {
          console.error("Error fetching additional data:", err);
          setIsLoading(false);
        });
    };

    const getLocationList = () => {
      setIsLoading(true);
      apiClient(accessToken)
        .get(`dashboard/locationlist/${sellerId}`)
        .then((res) => {
          setIsLoading(false);
          console.log("Most Used Layouts Data[dgsf]:", res.data);
          setFilterData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    };
    const getMostUsedLayouts = () => {
      setIsLoading(true);
      apiClient(accessToken)
        .get(`dashboard/mostLayoutlist/${sellerId}`)
        .then((res) => {
          setIsLoading(false);
          console.log("Most Used Layouts Data:", res.data);
          setMostUsedLayouts(res.data);
        })
        .catch((err) => {
          console.error("Error fetching most used layouts data:", err);
          setIsLoading(false);
        });
    };
    const getDisplayScreenshot = () => {
      setIsLoading(true);
      apiClient(accessToken)
        .get(`dashboard/screenshotpath/${sellerId}`)
        .then((res) => {
          setIsLoading(false);
          console.log("Screenshot paths:", res.data);
          const paths = res.data.filter((path) => !!path); // Filter out any null paths
          loadScreenshotImages(paths);
        })
        .catch((err) => {
          console.error("Error fetching screenshot paths:", err);
          setIsLoading(false);
        });
    };

    const loadScreenshotImages = (paths) => {
      const promises = paths.map((path) => {
        return fetch(path)
          .then((response) =>
            response.blob().then((blob) => {
              if (response.ok) {
                const url = URL.createObjectURL(blob);
                return { url };
              } else {
                throw new Error("Forbidden: Unable to access the image");
              }
            })
          )
          .catch((error) => {
            console.error("Error loading screenshot image:", error);
            return { url: null };
          });
      });

      Promise.all(promises)
        .then((images) => {
          setScreenshotImages(images.filter((image) => image.url)); // Filter out any null urls
          console.log("Screenshot images:", images); // Add this console.log statement
        })
        .catch((error) => {
          console.error("Error loading screenshot images:", error);
        });
    };

    getDashboardData();
    getLayoutCount();
    getImagesvideoscount();
    getDisplaycount();
    getMostUsedLayouts();
    getDisplayScreenshot();
    getLocationList();
  }, [accessToken, sellerId]);

  const handleLocationFilter = (location) => {
    const selectedLocationString = selectedLocation
      ? selectedLocation.toString()
      : null;
    const locationString = location.toString();
    setSelectedLocation(
      locationString === selectedLocationString ? null : locationString
    );
  };
  const handleStatusFilter = (status) => {
    const selectedStatusString = selectedStatus
      ? selectedStatus.toString()
      : null;
    const statusString = status.toString();

    setSelectedStatus(
      statusString === selectedStatusString ? null : statusString
    );
  };

  const clearFilters = () => {
    setSelectedLocation(null);
    setSelectedStatus(null);
  };
  const filteredData = filterData.filter((item) => {
    return (
      (!selectedLocation || item.name === selectedLocation) &&
      (!selectedStatus || item.status.toString() === selectedStatus)
    );
  });

  const items = filterData.map((eachLocation, index) => {
    return {
      key: `${index}`,
      label: (
        <Button
          key={index}
          style={{
            width: "100%",
            textAlign: "left",
            padding: "8px 12px",
            backgroundColor:
              selectedLocation === eachLocation.name
                ? "#1890ff"
                : "transparent",
            color: selectedLocation === eachLocation.name ? "#fff" : "#000",
            border: "0px solid #d9d9d9",
          }}
          onClick={() => handleLocationFilter(eachLocation.name)}
        >
          {eachLocation.name}
        </Button>
      ),
    };
  });
  const StatusFilterMenu = (
    <Menu onClick={({ key }) => handleStatusFilter(key)}>
      <Menu.Item key="1">Active</Menu.Item>
      <Menu.Item key="2">Inactive</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      name: "Name",
      selector: (param) => param.name,
      sortable: true,
    },
    {
      name: "Address",
      selector: (param) => param.address,
      sortable: true,
    },

    {
      name: "No.Displays",
      selector: (param) => param.DisplayLocationCount,
      sortable: true,
    },
    {
      name: "Status",
      selector: (param) => param.status,
      format: (param) => {
        if (param.status == 1) {
          return <span className="statusTab activve">Active</span>;
        } else {
          return <span className="statusTab deactive">In Active</span>;
        }
      },
      sortable: true,
    },
  ];
  const createDateOnly = (createdAt) => {
    const date = new Date(createdAt);
    // Get the date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
    const day = String(date.getDate()).padStart(2, "0");
    // Construct the formatted date string (YYYY-MM-DD)
    return `${day}-${month}-${year}`;
  };

  const formatTimeAgo = (createdAt) => {
    const date = new Date(createdAt);
    const now = new Date();
    const diffMs = now - date;
    const diffSeconds = Math.round(diffMs / 1000);
    const diffMinutes = Math.round(diffSeconds / 60);
    const diffHours = Math.round(diffMinutes / 60);
    const diffDays = Math.round(diffHours / 24);

    if (diffSeconds < 60) {
      return "just now";
    } else if (diffMinutes < 60) {
      return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
    } else if (diffHours < 24) {
      return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
    } else {
      return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    }
  };

  return (
    <div className="dashboard-bg-container contents">
      <div className="displays-bg-container">
        <Row className="p-2">
          <Col md={6}>
            <h6>Dashboard</h6>
          </Col>
        </Row>
        <div className="tw-flex tw-justify-between">
          <div className="search-input-dropdown-containe tw-flex tw-gap-3 tw-items-center">
            {/* <Dropdown
            menu={{
              items,
            }}
            trigger="click"
            placement="bottom"
            arrow
          >
            <Button className={`status-filter-btn`} onClick={clickToFlip}>
              Location <IoIosArrowDown />
            </Button>
          </Dropdown> */}
            <CustomDropDown
              text={selectedLocation || "Location"}
              items={items}
            />
            <CustomDropDown
              filterMenu={StatusFilterMenu}
              selectedStatus={selectedStatus}
              text={
                selectedStatus === "1"
                  ? "Active"
                  : selectedStatus === "2"
                  ? "In Active"
                  : "Status"
              }
            />

            {/* <Dropdown trigger="click" overlay={StatusFilterMenu} placement="bottomLeft" arrow>
            <Button className="status-filter-btn">
              Status {selectedStatus ? <FaFilter /> : null}
            </Button>
          </Dropdown> */}

            {selectedLocation || selectedStatus ? (
              <Button className="clear-filter-btn" onClick={clearFilters}>
                X
              </Button>
            ) : null}
          </div>
          <Col align="" md={6} className="add-layout-search-container">
            {/* <div className="search-input-container-lay">
              <AiOutlineSearch style={{ fontSize: "23px", marginTop: "2px" }} />
              <input
                type="search"
                className="search-input"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div> */}
            {/* <CustomButton
              disabledButton={false}
              btnType="button"
              BtnClassName="add-layout-buttons"
              BtnTxt={<>Add</>}
              // ClickEvent={handleShowAddStore}
            /> */}
          </Col>
        </div>
        <ul className="tw-flex tw-flex-wrap tw-gap-6 tw-w-full tw-mt-10 tw-px-7 tw-justify-between">
          <li
            className=" tw-shadow-2xl tw-rounded-md tw-bg-[#fff] tw-p-3 tw-flex tw-justify-center tw-items-center"
            style={{ width: "220px" }}
          >
            <div className="tw-flex tw-justify-between tw-gap-4">
              <div className=" tw-max-w-32">
                <h4 className="tw-font-sans tw-font-semibold tw-text-[#4c4c4c] tw-text-[18px]">
                  Total Images and videos
                </h4>
                {Imagesandvideoscount && (
                  <h4 className="tw-font-bold tw-font-sans tw-text-[30px]">
                    {Imagesandvideoscount.totalImagesvideos}
                  </h4>
                )}
              </div>
              <div className=" tw-bg-[#dfdeff] tw-w-12 tw-h-12  tw-flex tw-items-center tw-justify-center tw-rounded-md">
                <IoImageOutline
                  style={{ fontSize: "30px", color: "#8280FF" }}
                />
              </div>
            </div>
          </li>
          <li
            className=" tw-shadow-2xl tw-rounded-md tw-bg-[#fff] tw-p-3 tw-flex tw-justify-center tw-items-center"
            style={{ width: "220px" }}
          >
            <div className="tw-flex tw-justify-between tw-gap-4">
              <div className=" tw-max-w-32">
                <h4 className="tw-font-sans tw-font-semibold tw-text-[#4c4c4c] tw-text-[18px]">
                  Total Devices
                </h4>
                {Displaycount && (
                  <h4 className="tw-font-bold tw-font-sans tw-text-[30px]">
                    {Displaycount.count}
                  </h4>
                )}
              </div>
              <div className=" tw-bg-[#dfdeff] tw-w-12 tw-h-12  tw-flex tw-items-center tw-justify-center tw-rounded-xl">
                <FaUserFriends style={{ fontSize: "30px", color: "#8280FF" }} />
              </div>
            </div>
          </li>
          <li
            className=" tw-shadow-2xl tw-rounded-md tw-bg-[#fff] tw-p-3 tw-flex tw-justify-center tw-items-center"
            style={{ width: "220px" }}
          >
            <div className="tw-flex tw-justify-between tw-gap-4">
              <div className=" tw-max-w-32">
                <h4 className="tw-font-sans tw-font-semibold tw-text-[#4c4c4c] tw-text-[18px]">
                  Total Layouts
                </h4>
                {layoutCount && (
                  <h4 className="tw-font-bold tw-font-sans tw-text-[30px]">
                    {layoutCount.count}
                  </h4>
                )}
              </div>
              <div className=" tw-bg-[#dfdeff] tw-w-12 tw-h-12  tw-flex tw-items-center tw-justify-center tw-rounded-xl">
                <IoImageOutline
                  style={{ fontSize: "30px", color: "#8280FF" }}
                />
              </div>
            </div>
          </li>
          <li
            className=" tw-shadow-2xl tw-rounded-md tw-bg-[#fff] tw-p-3 tw-flex tw-justify-center tw-items-center"
            style={{ width: "220px" }}
          >
            <div className="tw-flex tw-justify-between tw-gap-4">
              <div className=" tw-max-w-32">
                <h4 className="tw-font-sans tw-font-semibold tw-text-[#4c4c4c] tw-text-[18px]">
                  Total storage size used
                </h4>

                {dashboardData && (
                  <h4 className="tw-font-bold tw-font-sans tw-text-[30px]">
                    {parseFloat(dashboardData.totalMediaSize).toFixed(3)}MB
                  </h4>
                )}
              </div>
              <div className=" tw-bg-[#dfdeff] tw-w-12 tw-h-12  tw-flex tw-items-center tw-justify-center tw-rounded-xl">
                <IoImageOutline
                  style={{ fontSize: "30px", color: "#8280FF" }}
                />
              </div>
            </div>
          </li>
        </ul>

        {isLoading ? (
          <Row className="">
            <Col
              md={12}
              align="center"
              className=" tw-flex tw-items-center tw-h-screen"
            >
              <div className="loader "></div>
            </Col>
          </Row>
        ) : (
          <>
            <h6 className="tw-ml-6 tw-mt-8 tw-font-sans">Location</h6>
            <CustomDataTable
              columns={columns}
              data={filteredData}
              tableKey="dashboardTable"
            />
          </>
          // <div className="all-table-container">
          //   <DataTable
          //     title=""
          //     columns={columns}
          //     data={filterData}
          //     pagination
          //     customStyles={tableStyle}
          //     paginationRowsPerPageOptions={[5, 10, 15]}
          //     paginationPerPage={10}
          //   />
          // </div>
        )}
        <h6 className="tw-ml-6 tw-mt-8 tw-font-sans"> Display</h6>
        {!screenshotImages || screenshotImages.length === 0 ? (
          <div
            style={{
              boxSizing: "border-box",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "rgba(0, 0, 0, 0.87)",
              backgroundColor: "#FFFFFF",
            }}
          >
            There are No records
          </div>
        ) : (
          <ul className="tw-flex  tw-gap-4 tw-justify-around ">
            {screenshotImages
              .filter((screenshot) => screenshot.url)
              .map((screenshot, index) => (
                <Card key={index} style={{ width: 290 }}>
                  <img src={screenshot.url} alt={`Screenshot ${index}`} />
                </Card>
              ))}
          </ul>
        )}

        <br />

        <div className="tw-bg-[#fff] tw-p-4">
          <h4 className="tw-font-sans tw-font-semibold">Most Used Layouts </h4>
          <hr style={{ width: "70%" }} />
          {MostUsedLayouts &&
            MostUsedLayouts.data &&
            MostUsedLayouts.data.map((layout, index) => (
              <ul className="tw-pl-0" key={index}>
                <li className="" style={{ borderBottom: "1px solid #ccc" }}>
                  <div className="tw-flex tw-justify-between tw-items-center tw-flex-wrap">
                    <div className="tw-flex tw-items-center tw-gap-10">
                      <div className="tw-flex tw-items-center tw-gap-4">
                        <img
                          src={logo}
                          alt="logo"
                          width={200}
                          height={200}
                          style={{ width: "80px", height: "80px" }}
                        />
                        <div>
                          <h6 className="tw-text-[15px]">{layout.name}</h6>
                          <h6 className="tw-text-[15px]">
                            {createDateOnly(layout.createdAt)}
                          </h6>
                          <h6 className="tw-text-[15px] tw-text-[#4c4c4c]">
                            last updated by {layout.username} |{" "}
                            {formatTimeAgo(layout.createdAt)}
                          </h6>
                        </div>
                      </div>
                      <div>
                        <h6>Layout Duration</h6>
                        <h6 className="tw-text-[#4c4c4c]">
                          {layout.totalDuration}
                        </h6>
                      </div>
                    </div>
                    {/* <div className="tw-flex tw-gap-6 tw-items-center tw-mr-4">
                  <h5 className="tw-text-[#4c4c4c] tw-mb-0">17%</h5>
                  <AiOutlineLoading3Quarters className="tw-text-[28px] tw-text-blue-500 " />
                </div> */}
                  </div>
                </li>
              </ul>
            ))}
          {/* <p
              className=" tw-text-blue-500 tw-flex tw-items-center tw-font-semibold tw-justify-center tw-gap-2 tw-mb-0 tw-mt-2"
              style={{ cursor: "pointer" }}
            >
              See All <FaArrowRightLong />
            </p> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  userProfile: state.userProfile,
  sellerId: state.sellerIdReducer,
  ...state,
});

export default connect(mapStateToProps)(Dashboard);
