import * as React from "react";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Button, Modal } from "react-bootstrap";
import Select from "@mui/material/Select";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
// import { makeStyles } from "@mui/styles";
import "../../index.css";
import { useEffect } from "react";

const ControlledOpenSelect = ({
  menuItem,
  defaultUser,
  userProfile,
  Profileinfo,
  onLogoutClick,
  isAdmin,
}) => {
  // const [profileDetail, setProfileDetail] = useState(
  //   defaultUser ? defaultUser.userName : ""
  // );
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileDetail, setProfileDetail] = useState(
    Profileinfo ? Profileinfo.name : ""
  );

  const [open, setOpen] = React.useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [view, setView] = useState(false);
  const navigate = useNavigate();

  // const handleChange = (event) => {
  //   setProfileDetail(event.target.value);
  // };

  const handleClose = () => {
    setOpen(false);
    setView(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setView(true);
    }, 100);
  };

  const clearCognitoCookies = () => {
    const allCookies = Cookies.get();
    for (const cookieName in allCookies) {
      if (cookieName.startsWith("CognitoIdentityServiceProvider")) {
        Cookies.remove(cookieName);
        localStorage.setItem("businesssName", null);
        navigate("/");
        window.location.reload();
      }
    }
  };

  const handleLogout = () => {
    clearCognitoCookies();
    localStorage.removeItem("selectedMenuItem");
  };

  const handleMenuItemClick = (itemName) => {
    switch (itemName) {
      case "Personal Information":
        navigate("/profileInfo");
        break;
      case "Change Password":
        navigate("/changepassword");
        break;
      case "Logout":
        onLogoutClick();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setProfileDetail(Profileinfo ? Profileinfo.name : "");
  }, [Profileinfo]);

  // useEffect(() => {
  //   setProfileDetail(defaultUser ? defaultUser.userName : "");
  // }, [defaultUser]);

  useEffect(() => {
    if (Profileinfo && Profileinfo.name) {
      setProfileDetail(Profileinfo.name);
    } else {
      setProfileLoading(true);
    }
  }, [Profileinfo]);

  // useEffect(() => {
  //   if (userProfile && userProfile.userName) {
  //     setProfileDetail(userProfile.userName);
  //   }
  // }, [userProfile]);

  const renderEmail = (email) => {
    const maxLength = 20;
    if (email?.length > maxLength) {
      return email.substring(0, maxLength) + "...";
    }
    return email;
  };

  return (
    <>
      <FormControl sx={{ minWidth: "100%" }}>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={profileDetail}
          // onChange={handleChange}
          style={{
            borderTop: "1px solid #ccc",
            borderRadius: "0px",
          }}
          sx={{
            borderRadius: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
          }}
          multiline
        >
          {defaultUser && Profileinfo && (
            <MenuItem
              value={Profileinfo.name}
              style={{ borderBottom: "1px solid #ccc" }}
            >
              {profileLoading ? ( // Render loading indicator when loading is true
                <div>Loading...</div>
              ) : (
                <div className=" tw-flex tw-items-center">
                  <img
                    src={Profileinfo.fileLocation || defaultUser.userProfile}
                    alt=""
                    style={{
                      marginRight: 10,
                      width: 40,
                      height: 40,
                      borderRadius: "10px",
                    }}
                  />
                  <div>
                    <p className="tw-m-0 tw-font-sans tw-font-bold">
                      {view
                        ? Profileinfo.name
                        : Profileinfo.name.length > 15
                        ? Profileinfo.name.substring(0, 11) + "..."
                        : Profileinfo.name}{" "}
                      {}
                    </p>
                    <p className="tw-m-0 tw-font-sans">
                      {view
                        ? renderEmail(Profileinfo.email)
                        : isAdmin
                        ? "Admin"
                        : "User"}
                    </p>
                  </div>
                </div>
              )}
            </MenuItem>
          )}
          {menuItem.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              style={{ height: "51px", gap: "8px" }}
              onClick={() => handleMenuItemClick(option.name)}
            >
              {option.icon}
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to logout? This action will sign you out of your
          account.
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "white" }}>
          <Button
            className=" tw-bg-white"
            onClick={() => setShowLogoutModal(false)}
            style={{ width: "90px" }}
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={handleLogout}
            style={{ width: "90px" }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  userProfile: state.userProfile,
  Profileinfo: state.Profileinfo,
});

export default connect(mapStateToProps)(ControlledOpenSelect);
