import React from 'react';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
const { Meta } = Card;

const Testing = () => (
  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
    {[...Array(6)].map((_, index) => (
      <Card
        key={index}
        style={{
          width: '30%', // 30% width to create 3 columns
          margin: '16px',
          flex: '0 0 auto', // Allow cards to shrink if needed
        }}
        cover={
          <img
            alt="example"
            src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
          />
        }
        actions={[
          <SettingOutlined key="setting" />,
          <EditOutlined key="edit" />,
          <EllipsisOutlined key="ellipsis" />,
        ]}
      >
        <Meta
          avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
          title="Card title"
          description="This is the description"
        />
      </Card>
    ))}
  </div>
);

export default Testing;
