/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-2",
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: process.env.REACT_APP_AMPLIFY_USERPOOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AMPLIFY_WEBCLIENT_ID,
  oauth: {
    scope: ["openid", "email", "custom:sellerId", "custom:updated_at"],
  },

  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [
    "ADDRESS",
    "EMAIL",
    "GIVEN_NAME",
    "NAME",
    "ZONEINFO",
    "custom:sellerID",
    "custom:updated_at",
  ],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
