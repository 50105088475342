import { AiOutlineEdit } from "react-icons/ai";
import { connect } from "react-redux";
import { useState, useEffect } from "react"; // Import useEffect
import { IoCloudUploadOutline } from "react-icons/io5";
import axios from "axios";
import { useDispatch } from "react-redux";
// import { profileInfo } from "../../redux/action";
import { Toaster,toast } from "sonner";
import { MdOutlineCancel } from "react-icons/md";
import { UserapiClient } from "../../components/userManagementApiclient";
const UserInfo = ({ userProfile, state, profileInfo,accessToken }) => {
  //console.log("ashokkk", profileInfo);
  const dispatch = useDispatch();
  const UserClient = UserapiClient(accessToken);
  const [fileLocation, setFileLocation] = useState("");
  const sellerId = userProfile?.["custom:sellerId"];
  const client = UserapiClient(accessToken);
  const userId = userProfile?.sub;
  const [fileUrl, setFilesUrl] = useState(null);

  const [file, setFile] = useState(null);

  const [editProfile, setEditProfile] = useState(false);
  const [formData, setFormData] = useState({});

  // Update form data on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Initialize form data and edit mode when userProfile changes
  useEffect(() => {
    if (userProfile && !editProfile && profileInfo) {
      setFormData({
        name: profileInfo.name,
        email: profileInfo.email,
        address: profileInfo.address,
        zoneinfo: profileInfo.zoneinfo,
        city: profileInfo.city || "",
        lastName: profileInfo.lastName || "",
        phone: profileInfo.phone || "",
        address2: profileInfo.address2 || "",
        state: profileInfo.state || "",
        postalCode: profileInfo.postalCode || "",
      });
    }
  }, [profileInfo, editProfile, userProfile]);

  const toggleEditMode = () => {
    setEditProfile(!editProfile);
  };

  useEffect(() => {
    // Store the initial form data when userProfile or profileInfo changes
    if (userProfile && !editProfile && profileInfo) {
      setFormData({
        name: profileInfo.name,
        email: profileInfo.email,
        address: profileInfo.address,
        zoneinfo: profileInfo.zoneinfo,
        city: profileInfo.city || "",
        lastName: profileInfo.lastName || "",
        phone: profileInfo.phone || "",
        address2: profileInfo.address2 || "",
        state: profileInfo.state || "",
        postalCode: profileInfo.postalCode || "",
      });
      setInitialFormData({
        name: profileInfo.name,
        email: profileInfo.email,
        address: profileInfo.address,
        zoneinfo: profileInfo.zoneinfo,
        city: profileInfo.city || "",
        lastName: profileInfo.lastName || "",
        phone: profileInfo.phone || "",
        address2: profileInfo.address2 || "",
        state: profileInfo.state || "",
        postalCode: profileInfo.postalCode || "",
      });
    }
  }, [profileInfo, editProfile, userProfile]);

  const [initialFormData, setInitialFormData] = useState({});
  const isFormChanged = () => {
    // Compare the current form data with the initial form data
    return JSON.stringify(formData) !== JSON.stringify(initialFormData);
  };

  const saveChanges = async () => {
    try {
      if (!isFormChanged() && !file) {
        toast.info("No changes made.");
        return;
      }
      if (file) {
        const res = await client.post("/profilegeneratePresignurl", {
          userId: userId,
          fileName: file?.name,
          fileType: file?.type,
        });

        if (res.status === 200) {
          const presignedUrls = res?.data;
          const formDatas = new FormData();
          const key = presignedUrls?.fields?.key;
          const filse = file;

          formDatas.append("key", key);
          formDatas.append("file", filse);

          const uploadResponse = await fetch(presignedUrls?.url, {
            method: "POST",
            body: formDatas,
          });

          if (uploadResponse.status === 204) {
            const metadata = {
              fileDetails: [
                {
                  fileName: file?.name,
                  fileType: file?.type,
                  fileSize: file?.size,
                  fileLocation: `${presignedUrls.url}/${encodeURIComponent(
                    key
                  )}`,
                },
              ],
            };

            setFileLocation(metadata.fileDetails[0].fileLocation);
            const response = await UserClient.put(`/updateprofile`, {
              sellerId: sellerId,
              fileLocation: metadata.fileDetails[0].fileLocation,
              userId: userId,
              ...formData,
            });
            toast.success("Profile Updated successfully");
            // console.log("Updated successfully!", response.data);
          }

          window.location.reload();
        }
      } else {
        // If no file is selected, update the profile without uploading a new picture
        const response = await UserClient.put(`/updateprofile`, {
          sellerId: sellerId,
          userId: userId,
          ...formData,
        });
        toast.success("Profile Updated successfully");
        // console.log("Updated successfully!", response.data);
        window.location.reload();
      }
      toggleEditMode();
    } catch (error) {
      toast.error("Error updating profile");
      console.error("Error saving changes:", error);
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFilesUrl(URL.createObjectURL(selectedFile));
    }
  };

  return (
    <div className=" tw-w-full tw-h-screen tw-overflow-y-scroll tw-justify-center tw-items-center tw-bg-[#f3f3f3f0]">
      <div className="tw-w-full tw-p-8 tw-space-y-8">
        <div className="tw-bg-white tw-rounded-xl tw-shadow-lg tw-w-full tw-h-4/5">
          <div
            className="tw-px-16 tw-py-2 tw-xl:py-6 tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-bg-gradient-to-b tw-bg-blue-500 tw-relative"
            style={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}
          >
            <div>
              <p className="tw-text-2xl tw-font-semibold tw-text-white">
                Personal Information
              </p>
              <p className="tw-text-sm tw-font-semibold tw-text-white">
                Details about your Personal information
              </p>
            </div>
            <div className="tw-absolute tw-top-1/2 tw-mt-[-15] tw-right-0">
              {profileInfo.fileLocation || fileLocation !== "" ? (
                <div
                  className="tw-w-24 tw-h-24 tw-border-solid tw-border-2 tw-flex tw-items-center tw-justify-center tw-border-[#ccc] tw-rounded-md tw-bg-white tw-mr-6"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${
                      fileUrl || profileInfo.fileLocation
                    })`,
                    backgroundSize: "cover",
                    backgroundBlendMode: "multiply",
                    color: "#fff",
                  }}
                >
                  {editProfile && (
                    <label
                      htmlFor="fileInput"
                      className="tw-flex tw-flex-col tw-items-center tw-justify-center"
                    >
                      <IoCloudUploadOutline className="tw-text-center" />
                      <p className="tw-text-xs" style={{ cursor: "pointer" }}>
                        {" "}
                        Upload Image
                      </p>
                    </label>
                  )}
                  {editProfile && ( // Only show input when editProfile is true
                    <input
                      id="fileInput"
                      type="file"
                      // required
                      accept="image/*"
                      className="tw-hidden"
                      style={{ cursor: "pointer" }}
                      onChange={(e) => handleFileChange(e)}
                    />
                  )}
                </div>
              ) : (
                <div className="tw-w-24 tw-h-24 tw-border-dashed tw-border-2 tw-flex tw-items-center tw-justify-center tw-border-[#E2E2E2] tw-rounded-md tw-bg-white tw-mr-6">
                  {editProfile && (
                    <label
                      htmlFor="fileInput"
                      className="tw-flex tw-flex-col tw-items-center tw-justify-center"
                    >
                      <IoCloudUploadOutline className="tw-text-center" />
                      <p className="tw-text-xs" style={{ cursor: "pointer" }}>
                        {" "}
                        Upload Image
                      </p>
                    </label>
                  )}
                  {editProfile && (
                    <input
                      id="fileInput"
                      type="file"
                      // required
                      accept="image/*"
                      className="tw-hidden"
                      style={{ cursor: "pointer" }}
                      onChange={(e) => handleFileChange(e)}
                    />
                  )}
                </div>
              )}
            </div>
            <button
              className="tw-absolute tw-top-[5px] tw-right-[5px] tw-p-1 tw-bg-white tw-rounded-full tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-text-[20px]"
              onClick={toggleEditMode}
              // style={
              //   editProfile
              //     ? {
              //         backgroundColor: "#000",
              //       }
              //     : {}
              // }
            >
              {editProfile ? (
                <MdOutlineCancel className="tw-text-gray-600" />
              ) : (
                <AiOutlineEdit
                  className="tw-text-gray-600"
                  // style={
                  //   editProfile
                  //     ? {
                  //         backgroundColor: "#000",
                  //         color: "#fff",
                  //       }
                  //     : {}
                  // }
                />
              )}
            </button>
          </div>

          <div className="tw-px-16 tw-py-2 tw-flex">
            <div className="tw-flex tw-justify-start tw-w-1/3 tw-mr-20">
              <div className="tw-w-full tw-flex tw-flex-col tw-mt-6 tw-gap-4 tw-justify-between">
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    Name *
                  </label>
                  <input
                    type="text"
                    name="name"
                    required
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                      editProfile ? "" : "tw-text-[#b8b5b5]"
                    }`}
                    readOnly={!editProfile}
                    disabled={!editProfile}
                  />
                </div>
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    Last Name *
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName || ""}
                    onChange={handleInputChange}
                    className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                      editProfile ? "" : "tw-text-[#b8b5b5]"
                    }`}
                    readOnly={!editProfile}
                    disabled={!editProfile}
                  />
                </div>
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    Email *
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email || ""}
                    onChange={handleInputChange}
                    className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                      editProfile ? "" : "tw-text-[#b8b5b5]"
                    }`}
                    readOnly={!editProfile}
                    disabled={!editProfile}
                  />
                </div>
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    Phone *
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone || ""}
                    onChange={handleInputChange}
                    className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                      editProfile ? "" : "tw-text-[#b8b5b5]"
                    }`}
                    readOnly={!editProfile}
                    disabled={!editProfile}
                  />
                </div>
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    Address 1 *
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address || ""}
                    onChange={handleInputChange}
                    className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                      editProfile ? "" : "tw-text-[#b8b5b5]"
                    }`}
                    readOnly={!editProfile}
                    disabled={!editProfile}
                  />
                </div>
              </div>
            </div>
            <div className="tw-flex tw-justify-start tw-w-1/3">
              <div className="tw-w-full tw-flex tw-flex-col tw-mt-6 tw-gap-4 tw-justify-between">
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    Address 2
                  </label>
                  <input
                    type="text"
                    name="address2"
                    value={formData.address2 || ""}
                    onChange={handleInputChange}
                    className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                      editProfile ? "" : "tw-text-[#b8b5b5]"
                    }`}
                    readOnly={!editProfile}
                    disabled={!editProfile}
                  />
                </div>
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city || ""}
                    onChange={handleInputChange}
                    className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                      editProfile ? "" : "tw-text-[#b8b5b5]"
                    }`}
                    readOnly={!editProfile}
                    disabled={!editProfile}
                  />
                </div>
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    State
                  </label>
                  <input
                    type="text"
                    name="state"
                    value={formData.state || ""}
                    onChange={handleInputChange}
                    className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                      editProfile ? "" : "tw-text-[#b8b5b5]"
                    }`}
                    readOnly={!editProfile}
                    disabled={!editProfile}
                  />
                </div>
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    Postal Code
                  </label>
                  <input
                    type="text"
                    name="postalCode"
                    value={formData.postalCode || ""}
                    onChange={handleInputChange}
                    className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                      editProfile ? "" : "tw-text-[#b8b5b5]"
                    }`}
                    readOnly={!editProfile}
                    disabled={!editProfile}
                  />
                </div>
                <div>
                  <label className="tw-block tw-text-lg tw-font-medium tw-text-black">
                    Country
                  </label>
                  <input
                    type="text"
                    name="zoneinfo"
                    value={formData.zoneinfo || ""}
                    onChange={handleInputChange}
                    className={`tw-mt-1 input-outline tw-px-2 tw-bg-white tw-border tw-border-[#CED4DA] tw-focus:ring-ln-blue tw-focus:border-ln-blue tw-block tw-w-full tw-shadow-sm tw-sm:text-sm  tw-h-11 tw-rounded-md ${
                      editProfile ? "" : "tw-text-[#b8b5b5]"
                    }`}
                    readOnly={!editProfile}
                    disabled={!editProfile} // Adding the disabled attribute
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-px-16 tw-py-3 tw-flex tw-justify-end tw-border-t tw-border-gray-200">
            <button
              className="tw-bg-white tw-text-black tw-px-4 tw-py-2 tw-rounded tw-border tw-border-[#CED4DA] tw-mr-2"
              onClick={toggleEditMode}
            >
              Cancel
            </button>
            <button
              className={`tw-bg-[#0d6efd] tw-text-white tw-px-4 tw-py-2 tw-rounded tw-border tw-border-[#0d6efd]`}
              onClick={saveChanges}
              style={
                !editProfile
                  ? {
                      cursor: "not-allowed",
                      backgroundColor: "#b3caec",
                      border: "1px solid #b3caec",
                    }
                  : {}
              }
            >
              Save Changes
            </button>
            {/* <CustomButton
              disabledButton={loading}
              style={
                !editProfile
                  ? {
                      cursor: "not-allowed",
                      backgroundColor: "#b3caec",
                      border: "1px solid #b3caec",
                    }
                  : {}
              }
              onClick={saveChanges}
              BtnTxt={loading ? "saving..." : "Save Changes"}
              // BtnClassName="Filter-button"
              className="tw-bg-[#0d6efd] tw-text-white tw-px-4 tw-py-2 tw-rounded tw-border tw-border-[#0d6efd]"
            /> */}
          </div>
        </div>
        <Toaster position="bottom-right" className="tw-mr-4"  />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.userProfile,
  profileInfo: state.Profileinfo,
  currentBusiness: state.currentBusiness,
  accessToken: state.accessToken,
});

export default connect(mapStateToProps)(UserInfo);
