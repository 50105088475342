import React, { useEffect } from "react";

import { Route, Routes} from "react-router-dom";
import { Routes as appRoutes } from "../routes";
import Dashboard from "./Dashboard/Dashboard";
import NotFound from "./NotFound";
import Sidebar from "./Sidebar";
import "../App.css";
import Users from "./UserProfile/Users";
import Library from "./Library/Library";
import Stores from "./Stores/Stores";
import AuthGuard from "../components/AuthGuard";
import LayoutsViews from "./Layouts/LayoutsViews";
import Displays from "./Displays/Displays";
import Layout from "./Layouts/Layout";
import DisplayPreview from "./Displays/DisplayPreview";

import AddDisplayLayout from "./Displays/AddDisplay";
import { useDispatch } from "react-redux";
import { logoutAction } from "../redux/action";
import setupBeforeUnloadListener from "./Login/Logoutafterclosingwindow";
import Testing from "../components/Testing";
import UserInfo from "./UserProfile/ProfileInformation";
import ChangePassword from "./UserProfile/ChangePassword";
import NewBusiness from "./Business/addBusiness";
import EditBusiness from "./Business/editBusiness";
const HomePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const cleanup = setupBeforeUnloadListener(dispatch, logoutAction);

    return () => {
      cleanup();
    };
  }, [dispatch]);

  return (
    <Routes>
      {/* <Route exact path={appRoutes.Signup} element={<Signup />} /> */}
      {/* <Route exact path={appRoutes.Login} element={<Login />} /> */}
      <Route exact path="/testing" element={<Testing />} />
      <Route
        exact
        path={appRoutes.Dashboard}
        element={
          <AuthGuard path={appRoutes.Dashboard} component={<Dashboard />} />
        }
      />
      <Route
        exact
        path="/"
        element={<AuthGuard path="/" component={<Dashboard />} />}
      />
      <Route
        exact
        path={appRoutes.Library}
        element={<AuthGuard path={appRoutes.Library} component={<Library />} />}
      />

      <Route
        exact
        path={appRoutes.Layouts}
        element={<AuthGuard path={appRoutes.Library} component={<Layout />} />}
      />
      <Route
        exact
        path={appRoutes.Layoutviews}
        element={
          <AuthGuard
            path={appRoutes.Layoutviews}
            component={<LayoutsViews />}
          />
        }
      />
      <Route
        exact
        path={appRoutes.Displays}
        element={
          <AuthGuard path={appRoutes.Library} component={<Displays />} />
        }
      />
      <Route
        exact
        path={appRoutes.UserProfile}
        element={
          <AuthGuard path={appRoutes.UserProfile} component={<Users />} />
        }
      />
      <Route
        exact
        path={appRoutes.Location}
        element={<AuthGuard path={appRoutes.Location} component={<Stores />} />}
      />
      <Route
        exact
        path={appRoutes.AddDisplay}
        element={
          <AuthGuard
            path={appRoutes.AddDisplay}
            component={<AddDisplayLayout />}
          />
        }
      />

      <Route
        exact
        path={appRoutes.DisplayView}
        element={
          <AuthGuard
            path={appRoutes.DisplayView}
            component={<DisplayPreview />}
          />
        }
      />
      <Route
        exact
        path={appRoutes.AddDisplay}
        element={
          <AuthGuard
            path={appRoutes.AddDisplay}
            component={<AddDisplayLayout />}
          />
        }
      />
      <Route
        exact
        path="/profileInfo"
        element={<AuthGuard path="/profileInfo" component={<UserInfo />} />}
      />
      <Route
        exact
        path="/changepassword"
        element={
          <AuthGuard path="/changepassword" component={<ChangePassword />} />
        }
      />
      <Route
        exact
        path="/newBusiness"
        element={<AuthGuard path="/newBusiness" component={<NewBusiness />} />}
      />
      <Route
        exact
        path="/editBusiness"
        element={
          <AuthGuard path="/editBusiness" component={<EditBusiness />} />
        }
      />
      <Route
        exact
        path="*"
        element={
          <>
            <Sidebar />
            <NotFound />
          </>
        }
      />
    </Routes>
  );
};

export default HomePage;
