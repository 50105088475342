import React, { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { connect } from "react-redux";
import { Bars } from "react-loader-spinner";
import { apiClient } from "../../../components/apiClient";
import "./videoselection.css";
import { Card, Avatar, Dropdown, Menu } from "antd";
import ReactPaginate from "react-paginate";
import CustomDataTable from "../../../components/DataTable";
import { DateFormat } from "../../../components/DateFormat";
import { BsThreeDotsVertical } from "react-icons/bs";

const { Meta } = Card;
const profilepic =
  "https://s3.amazonaws.com/creativetim_bucket/photos/264729/thumb.jpeg?1620116551";
const VideoSelection = ({
  videosfiles,
  onDragStart,
  userProfile,
  Profileinfo,
  view,
  searchTerm,
  setSearchTerm,
  onAddToOverlay,
}) => {
  console.log("currentView", view);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [mutedVideos, setMutedVideos] = useState({}); // State to manage muted videos
  const defaultUser = {
    userName: Profileinfo?.name,
    userEmail: Profileinfo?.email,
    userRole: "Admin",
    userProfile:
      Profileinfo?.fileLocation ||
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png",
  };
  const [showFullName, setShowFullName] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const handleAddToLayout = (media) => {
    if (onAddToOverlay) {
      const newItem = {
        id: media.id,
        fileUrl: media.fileUrl,
        mediaType: "video",
        uniqueId: nextId++,
        muted: mutedVideos[media.id] || false,
      };
      onAddToOverlay(newItem);
    }
  };

  const handleClick = () => {
    setShowFullName(!showFullName);
  };
  const columns = [
    {
      name: "Display videos",
      cell: (param) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div draggable onDragStart={(e) => handleDragStart(e, param)}>
            <video
              src={param.fileUrl}
              width="100px"
              height="auto"
              controls
              muted={mutedVideos[param.id] || false} // Apply mute state
            />
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "File Name",
      selector: (param) => param.fileName,
      sortable: true,
    },
    {
      name: "Uploaded By ",
      selector: () => userProfile?.name,
      sortable: true,
    },
    {
      name: "Date of Upload",
      selector: (param) => DateFormat(param.createdAt),
      sortable: true,
    },
    {
      name: "File Size",
      selector: (param) => `${param.size} MB`,
      sortable: true,
    },
  ];

  useEffect(() => {
    const fetchVideos = async () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    fetchVideos();
  }, []);

  const videosPerPage = 6;
  const videoMedia = videosfiles;
  const mediaType = "video";

  const indexOfLastVideo = (currentPage + 1) * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = searchTerm
    ? searchResults.slice(indexOfFirstVideo, indexOfLastVideo)
    : videoMedia.slice(indexOfFirstVideo, indexOfLastVideo);
  let nextId = 1;
  const handleDragStart = (e, media) => {
    const { id, fileUrl } = media;
    const newItem = {
      id,
      fileUrl,
      mediaType: "video",
      uniqueId: nextId++,
      muted: mutedVideos[media.id] || false,
    };
    e.dataTransfer.setData(
      "application/reactflow",
      JSON.stringify({ item: newItem, index: -1 })
    );
    e.dataTransfer.effectAllowed = "move";
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const pageCount = searchTerm
    ? Math.ceil(searchResults.length / videosPerPage)
    : Math.ceil(videosfiles.length / videosPerPage);
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setLoading(true);
        setTimeout(async () => {
          if (searchTerm === "") {
            setSearchResults(videosfiles);
          } else {
            // Filter videos based on search term
            const filteredVideos = videosfiles.filter((video) =>
              video.fileName.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchResults(filteredVideos);
          }
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error("Error fetching videos:", error.message);
        setLoading(false);
      }
    };
    fetchVideos();
  }, [searchTerm, videosfiles]);

  const [isFullName, setIsFullName] = useState(false);

  const getDisplayName = (fileName) => {
    const words = fileName.split(" ");
    if (isFullName || words.length <= 4) {
      return fileName;
    }
    return `${words.slice(0, 4).join(" ")}...`;
  };

  const handleNameClick = () => {
    setIsFullName(!isFullName);
  };

  const toggleMute = (mediaId) => {
    setMutedVideos((prevMutedVideos) => ({
      ...prevMutedVideos,
      [mediaId]: !prevMutedVideos[mediaId],
    }));
  };

  return (
    <>
      <div className="paddingtop"></div>
      {loading ? (
        <div className="loading-container">
          <Bars
            height="40"
            width="40"
            color="#48cae4"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          {view === "list" ? (
            <CustomDataTable
              columns={columns}
              data={searchTerm ? searchResults : videosfiles}
              pagination={false}
            />
          ) : (
            <>
              <div
                className="d-flex flex-wrap example-parent"
                style={{ marginTop: "-45px" }}
              >
                {searchResults.length > 0
                  ? currentVideos.map((media) => (
                      <div
                        key={media.id}
                        className="p-1 col-lg-4"
                        style={{
                          backgroundColor: " #ffffff",
                          border: "0",
                          borderRadius: "10px",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          margin: "0 10px 20px 0",
                          flex: "0 0 calc(33.33% - 10px)",
                        }}
                        draggable
                        onDragStart={(e) => handleDragStart(e, media)}
                      >
                        <Card
                          style={{
                            // Adjust height as needed
                            margin: 0,
                            display: "contents",
                          }}
                        >
                          <div className="d-flex">
                            <span
                              className={!isFullName ? "ellipsis" : ""}
                              style={{
                                fontWeight: "500",
                                zIndex: "1",

                                fontFamily: "Poppins",
                                // Adjust top position as needed
                              }}
                              onClick={handleNameClick}
                            >
                              {getDisplayName(media.fileName)}
                            </span>
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    key="1"
                                    onClick={() => toggleMute(media.id)}
                                  >
                                    {mutedVideos[media.id] ? "Unmute" : "Mute"}
                                  </Menu.Item>
                                  <Menu.Item
                                    key="2"
                                    onClick={() => handleAddToLayout(media)}
                                  >
                                    Add Layout
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={["click"]}
                              visible={openDropdownId === media.id}
                              onVisibleChange={(visible) => {
                                if (visible) {
                                  setOpenDropdownId(media.id);
                                } else {
                                  setOpenDropdownId(null);
                                }
                              }}
                              overlayClassName="custom-dropdown"
                            >
                              <span onClick={(e) => e.preventDefault()}>
                                <BsThreeDotsVertical />
                              </span>
                            </Dropdown>
                          </div>

                          <div className="">
                            <video
                              className="w-full"
                              height="140px"
                              controls
                              title={media.fileName}
                              muted={mutedVideos[media.id] || false}
                            >
                              <source src={media.fileUrl} type="video/mp4" />
                            </video>
                          </div>
                          <div className="tw-mt-2">
                            <Meta
                              avatar={<Avatar src={defaultUser.userProfile} />}
                              description={
                                <div
                                  style={{
                                    marginTop: "2px", // Adjust margin-top as needed
                                  }}
                                >
                                  <p
                                    className="creste-size-desc"
                                    style={{
                                      fontSize: "9px",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <strong>{userProfile?.name}</strong>
                                    <br />
                                    {DateFormat(media.createdAt)}, Size:{" "}
                                    {media.size} MB
                                  </p>
                                </div>
                              }
                            />
                          </div>
                        </Card>
                      </div>
                    ))
                  : currentVideos.map((media) => (
                      <div
                        key={media.id}
                        className="p-1 col-lg-4"
                        style={{
                          backgroundColor: " #ffffff",
                          border: "0",
                          borderRadius: "10px",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                        draggable
                        onDragStart={(e) => handleDragStart(e, media)}
                      >
                        <Card
                          style={{
                            width: 150,
                            height: 200, // Adjust height as needed
                            margin: 0,
                            display: "contents",
                          }}
                          className="bdr"
                        >
                          <div className="react-player-wrapper">
                            <span
                              style={{
                                fontWeight: "500",
                                zIndex: "1",
                                fontFamily: "Poppins",
                                position: "absolute",
                                top: "5px", // Adjust top position as needed
                              }}
                            >
                              {media.fileName.length < 0
                                ? media.fileName
                                : media.fileName.substring(0)}
                            </span>
                            <div className="aspect-ratio-container">
                              <video
                                className="react-player"
                                width="100px"
                                height="100px"
                                controls
                                title={media.fileName}
                                muted={mutedVideos[media.id] || false}
                              >
                                <source src={media.fileUrl} type="video/mp4" />
                              </video>
                            </div>
                          </div>
                          <Meta
                            avatar={<Avatar src={defaultUser.userProfile} />}
                            description={
                              <div
                                style={{
                                  marginTop: "5px", // Adjust margin-top as needed
                                }}
                              >
                                <p
                                  className="creste-size-desc"
                                  style={{
                                    fontSize: "9px",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <strong>{userProfile?.name}</strong>
                                </p>
                                <p
                                  className="creste-size-desc"
                                  style={{
                                    fontSize: "9px",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {DateFormat(media.createdAt)}, Size:{" "}
                                  {media.size} MB
                                </p>
                              </div>
                            }
                          />
                        </Card>
                      </div>
                    ))}
              </div>

              <div className="mt-3">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  userProfile: state.userProfile,
  ...state,
});

export default connect(mapStateToProps)(VideoSelection);
