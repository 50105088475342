import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../pages/Sidebar";

const AuthGuard = ({ component, login, accesstoken }) => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      try {
        //console.log('login',login)

        if (!login) {
                    navigate(`/`);

        } else {
          setStatus(true);
        }
      } catch (error) {
        navigate(`/`);
      }
    };

    checkToken();
  }, [navigate, login]);

  return status ? (
    <React.Fragment>
      <Sidebar />
      {component}
    </React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  login: state.login,
  accesstoken: state.accesstoken,
});

export default connect(mapStateToProps)(AuthGuard);
