import React, { useState, useEffect } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { AiOutlineEye } from "react-icons/ai";
import CustomButton from "../../../components/Button";
import { Modal, Col, Row } from "react-bootstrap";
import { Card, Avatar, Skeleton } from "antd";
import { DateFormat } from "../../../components/DateFormat";
import { connect } from "react-redux";
import CustomDataTable from "../../../components/DataTable";
import { apiClient } from "../../../components/apiClient";
import "./Images.css";
import { Toaster, toast } from "sonner";
import { setImageFiles, setCurrentPage } from "../../../redux/action";
import { useFetchImages } from "../../../hook/useFetchMedia";
import ImageNegativeScreen from "../../../components/NegativeScreens/Library/ImageNegativeScreen";

const { Meta } = Card;

const Images = ({
  accessToken,
  imageFiles,
  currentPage,
  view,
  searchTerm,
  setImageFiles,
  setCurrentPage,
  userProfile,
  sellerId,
  handleAddMoreClick,
}) => {
  const { data, isLoading, error, refetch } = useFetchImages(
    accessToken,
    sellerId,
    searchTerm
  );

  const [showModal, setShowModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const profilepic =
    "https://s3.amazonaws.com/creativetim_bucket/photos/264729/thumb.jpeg?1620116551";
  const itemsPerPage = 6;
  const columns = [
    {
      name: "Display Images",
      selector: (param) => <Avatar src={param.fileUrl} />,
    },
    {
      name: "File Name",
      selector: (param) => param.fileName,
    },
    {
      name: "Uploaded By",
      selector: () => userProfile?.name,
      sortable: true,
    },
    {
      name: "Date of Upload",
      selector: (param) => DateFormat(param.createdAt),
      sortable: true,
    },
    {
      name: "File Size",
      selector: (param) => `${param.size} MB`,
    },
    {
      name: "Action",
      cell: (param) => (
        <>
          {/* Edit Icon */}
          <span
            title="Edit"
            style={{
              cursor: "pointer",
              padding: "5px",
              display: "inline-block",
              border: "1px solid #DFDFDF",
              color: "#666666",
              background: "#F0F0F0",
              borderRadius: "4px",
            }}
            onClick={() => handleView(param)}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineEye style={{ fontSize: "20px", color: "#666666" }} />
            </div>
          </span>

          {/* Delete Icon */}
          <span
            title="Delete"
            style={{
              cursor: "pointer",
              padding: "5px",
              display: "inline-block",
              border: "1px solid #DFDFDF",
              color: "#666666",
              background: "#F0F0F0",
              borderRadius: "4px",
              marginLeft: "5px",
            }}
            onClick={() => handleDeleteClick(param.id)}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineDelete style={{ fontSize: "20px", color: "#666666" }} />
            </div>
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      setImageFiles(data);
    }
  }, [data, setImageFiles]);

  const handleDeleteClick = async (mediaId) => {
    setFileToDelete(mediaId);
    setConfirmDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const idToken = accessToken;
      const client = apiClient(idToken);

      const response = await client.delete(`library/mediafile/${fileToDelete}`);

      if (response.status === 200) {
        toast.success("MediaFile Deleted successfully");
        const updatedFiles = imageFiles.filter(
          (file) => file.id !== fileToDelete
        );
        setImageFiles(updatedFiles);

        const updatedResponse = await client.get(
          `library/mediaretrieveall/${sellerId}`,
          {
            params: {
              fileType: 1,
              filename: searchTerm,
            },
          }
        );

        if (Array.isArray(updatedResponse.data)) {
          setImageFiles(updatedResponse.data);
          setCurrentPage(0);
        } else {
          console.error("Invalid response format:", updatedResponse);
        }
      } else {
        console.error("Error deleting media:", response);
        toast.warning(
          "Media file is assigned to one or more layouts. Unassign them before deleting."
        );
      }
    } catch (error) {
      console.error("Error deleting media:", error);
      toast.warning(
        "Media file is assigned to one or more layouts. Unassign them before deleting."
      );
    } finally {
      setConfirmDeleteModal(false);
      setShowModal(false);
    }
  };

  localStorage.setItem("length", JSON.stringify(imageFiles?.length));
  const handleView = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentImageFiles = imageFiles?.slice(startIndex, endIndex);

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="images-container">
      {imageFiles?.length > 0 && (
        <p className="parabelo-searchh">Library Files[{imageFiles?.length}]</p>
      )}
      {isLoading ? (
        <div className="images-list-container">
          {/* Loading Skeleton */}
          {Array.from({ length: itemsPerPage })?.map((_, index) => (
            <Card key={index} className="card image-card-container">
              <div className="shimmer-loading">
                <Skeleton height={200} className="skeleton-transition" />
              </div>
              <Meta
                title={<Skeleton width={100} className="skeleton-transition" />}
              />
            </Card>
          ))}
        </div>
      ) : imageFiles?.length === 0 ? (
        <ImageNegativeScreen handleAddMoreClick={handleAddMoreClick} />
      ) : (
        <div className="images-list-container">
          {view === "list" ? (
            /* Display data in list view */
            <div style={{ width: "100%" }}>
              <CustomDataTable
                columns={columns}
                data={imageFiles}
                pagination={true}
                tableKey="libraryImagesTable"
              />
            </div>
          ) : (
            /* Display data in grid view */
            currentImageFiles?.map((item, index) => (
              <Card
                key={index}
                className="card image-card-container"
                style={{
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
                cover={
                  <img alt="food" src={item.fileUrl} className="food-image" />
                }
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                {hoveredCard === index && (
                  <div
                    className="delete-button-container"
                    onClick={() => handleDeleteClick(item.id)}
                  >
                    <AiOutlineDelete
                      style={{
                        color: "red",
                        backgroundColor: "#fff",
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        fontSize: "22px",
                      }}
                      onClick={() => handleDeleteClick(item.id)}
                    />
                  </div>
                )}

                <Meta
                  avatar={<Avatar src={profilepic} />}
                  title={
                    <span style={{ fontWeight: "500", fontFamily: "poppins" }}>
                      {item?.fileName?.length < 16
                        ? item.fileName
                        : item.fileName.substring(0)}
                      ... By {userProfile?.name}
                    </span>
                  }
                  description={
                    <div>
                      <p
                        className="creste-size-desc"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Created: {DateFormat(item.createdAt)} | Size:{" "}
                        {item.size} MB
                      </p>
                    </div>
                  }
                />
              </Card>
            ))
          )}
        </div>
      )}

      <div className="mt-3">
        {
          view === "grid" ? (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={Math.ceil(imageFiles?.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          ) : null // or any other component you want to render when view !== grid
        }
      </div>

      <Modal
        size="lg"
        animation={false}
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <h4
              style={{
                fontSize: "16px",
                textAlign: "center",
                margin: 0,
                marginRight: "auto",
              }}
            >
              {selectedImage && selectedImage?.fileName}
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img
              src={selectedImage?.fileUrl}
              alt={selectedImage?.fileName}
              style={{ width: "100%" }}
            />
          )}
        </Modal.Body>
        <Modal.Footer style={{ background: "#fff" }}>
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {/* <CustomButton 
          BtnClassName="yes-modal-btn"
          ClickEvent={() => {
            setShowModal(false);
            setSelectedImage(null);
          }}
        /> */}
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        animation
        show={confirmDeleteModal}
        onHide={() => setConfirmDeleteModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header>
          <div style={{ width: "100%" }}>
            <h4 style={{ fontSize: "16px", textAlign: "center" }}>
              Do you want to delete it ?
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="">
            <Col style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                BtnTxt={<>Yes</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => {
                  handleConfirmDelete();
                }}
              />
              <CustomButton
                BtnTxt={<>No</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => setConfirmDeleteModal(false)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Toaster position="bottom-right" className="tw-mr-4" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  imageFiles: state.imagemedia.imageFiles,
  currentPage: state.imagemedia.currentPage,
  userProfile: state.userProfile,
  sellerId: state.sellerIdReducer,
});

const mapDispatchToProps = {
  setImageFiles,
  setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Images);
