import React, { useState, useEffect } from "react";
import { BsGrid, BsPencilSquare } from "react-icons/bs";
import { Button } from "antd";
import {
  AiOutlineSearch,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";
import { FaImage, FaList } from "react-icons/fa";
import { BiSolidVideos } from "react-icons/bi";
import { PiClockCountdownBold } from "react-icons/pi";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import "./Layoutview.css";
import { useLocation } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import ReactPlayer from "react-player";

import ImagesSelection from "./Draganddrop/Imagesselction";
import VideoSelection from "./Draganddrop/Videosselction";
import CustomButton from "../../components/Button";
import { apiClient } from "../../components/apiClient";
import { ThreeDots } from "react-loader-spinner";
import SaveLayoutmodal from "./Savelayoutmodal";
import { Carousel } from "react-bootstrap";

import { Toaster, toast } from "react-toastify";

let itemIdCounter = 0;

const LayoutsView = ({ state, sellerId, accessToken }) => {
  const location = useLocation();
  const { id, name } = location?.state || {};
  const [popViewLayoutdata, setpopViewLayoutdata] = useState({});
  const [loopIndex, setLoopIndex] = useState(0);
  const [saveLayoutModalVisible, setSaveLayoutModalVisible] = useState(false);

  const [selectedTab, setSelectedTab] = useState("images");
  const [droppedItems, setDroppedItems] = useState([]);

  const [loading, setLoading] = useState();
  const [saveLayoutmodal, setSaveLayoutmodal] = useState(false);
  const [saveditems, setSaveditems] = useState([]);
  const [autoplay, setAutoplay] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(name || "");

  const [view, setView] = useState("grid");
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);

  const fetchImages = async () => {
    try {
      console.log("fetchImages called");
      const client = apiClient(accessToken);

      let endpoint = `library/mediaretrieveall/${sellerId}`;

      if (searchTerm) {
        endpoint = `library/search/${sellerId}`;
      }

      const response = await client.get(endpoint, {
        params: {
          fileType: 1,
          filename: searchTerm,
        },
      });

      if (Array.isArray(response.data)) {
        setImageFiles(response.data);
        console.log("fetchImages successful");
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching media:", error);
    }
  };

  const fetchVideos = async () => {
    try {
      console.log("fetchVideos called");
      const client = apiClient(accessToken);
      setLoading(true);

      let endpoint = `library/mediaretrieveall/${sellerId}`;

      if (searchTerm) {
        endpoint = `library/search/${sellerId}`;
      }

      const response = await client.get(endpoint, {
        params: {
          fileType: 2,
          filename: searchTerm,
        },
      });

      if (Array.isArray(response.data)) {
        setVideoFiles(response.data);
        console.log("fetchVideos successful");
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching media:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
    fetchVideos();
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  const handleListview = () => {
    setView("list");
  };
  const handleGridview = () => {
    setView("grid");
  };
  const handleEditStart = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setNewName(name); // Revert to original name
    setIsEditing(false);
  };
  const handleUpdateName = async () => {
    try {
      const res = await apiClient(accessToken).put("/layout/update-item", {
        id: id,
        updates: {
          name: newName,
        },
      });

      if (res.status === 200) {
        setIsEditing(false);
        toast.success("Layout name updated successfully");
      } else {
        throw new Error(`Update failed with status: ${res.status}`);
      }
    } catch (error) {
      console.error("Error updating layout name:", error);
      toast.error("Error updating layout name: " + error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const result = await apiClient(accessToken).get(
          `/layoutModule/details/${id}`
        );
        console.log("droppedItemsResult:", result.data.data.libraryIds);

        setpopViewLayoutdata(result.data.data);

        const parsedDroppedItems = result.data.data.libraryIds
          ? JSON.parse(result.data.data.libraryIds).map((item) => ({
              id: item.id,
              fileUrl: item.fileUrl,
              mediaType: item.mediaType,
              duration: item.duration,
              muted: item.isMuted,
            }))
          : [];
        //console.log("parsedDroppedItems: ", parsedDroppedItems);
        setSaveditems(parsedDroppedItems);
        setDroppedItems(parsedDroppedItems);
      } catch (error) {
        console.error("Error fetching layout details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const handleSelect = (selectedIndex, e) => {
    setLoopIndex(selectedIndex);
  };

  const formatDuration = (durationInSeconds) => {
    if (isNaN(durationInSeconds) || durationInSeconds === undefined) {
      return "00:00";
    }

    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const renderMediaContent = (item) => {
    if (!item) {
      return null;
    }

    if (item.mediaType === "image") {
      return (
        <div className="layout-img-container">
          <img
            src={item.fileUrl}
            alt="Ima//"
            className=""
            style={{
              backgroundSize: "cover",
              width: "113%",
              borderRadius: "10px",
            }}
          />
        </div>
      );
    } else if (item.mediaType === "video") {
      return (
        <div className="layout-img-container">
          <ReactPlayer
            url={item.fileUrl}
            className=""
            playing={autoplay}
            controls
            muted={item.muted}
          />
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (autoplay) {
        setLoopIndex((prevIndex) => (prevIndex + 1) % droppedItems.length);
      }
    }, droppedItems[loopIndex]?.duration * 1000 || 5000);

    return () => clearInterval(intervalId);
  }, [loopIndex, autoplay, droppedItems]);

  // const imagesfiles = state?.imagemedia?.imageFiles || [];
  // const videosfiles = state?.videomedia?.videoFiles || [];

  const handleSaveLayout = () => {
    setSaveLayoutModalVisible(true);
    setSaveLayoutmodal(true);
  };

  const handleDiscardLayout = () => {
    setDroppedItems(saveditems);
  };

  const closeSaveLayoutmodal = () => {
    setSaveLayoutModalVisible(false);
    setSaveLayoutmodal(false);
  };

  const onDragStart = (e, item) => {
    const itemData = JSON.stringify(item);
    e.dataTransfer.setData("application/reactflow", itemData);
    e.dataTransfer.effectAllowed = "move";
  };

  const onDrop = (e) => {
    e.preventDefault();
    try {
      const data = e.dataTransfer.getData("application/reactflow");
      if (!data) {
        console.error("No data available from drag event.");
        return;
      }

      const { item, index: draggedIndex } = JSON.parse(data);

      if (!item) {
        console.error("Dropped item is undefined");
        return;
      }

      if (draggedIndex === -1) {
        const newItem = {
          ...item,
          uniqueId: itemIdCounter++,
          muted: item.muted, // Preserve mute state
        };
        setDroppedItems((prevItems) => {
          const newItems = [...prevItems.filter(Boolean), newItem];
          return newItems;
        });
      } else {
        const droppedItemsCopy = [...droppedItems];
        const targetIndex = droppedItemsCopy.findIndex(
          (i) => i && i.uniqueeId === item.uniqueeId
        );

        if (targetIndex === draggedIndex) return;

        droppedItemsCopy.splice(draggedIndex, 1);
        droppedItemsCopy.splice(targetIndex, 0, item);

        setDroppedItems(droppedItemsCopy);
      }
    } catch (error) {
      console.error("Error parsing drag data:", error);
    }
  };

  const handleDeleteClick = (itemId, index) => {
    if (itemId === undefined || index === undefined) {
      console.error("Item id or index is undefined.");
      return;
    }

    setDroppedItems((prevItems) => {
      const newItems = prevItems.filter(
        (item, i) => !(item.id === itemId && i === index)
      );
      return newItems;
    });
  };

  const onDragOverImage = (e) => {
    e.preventDefault();
  };

  const onDropImage = (e, targetIndex) => {
    const draggedIndex = e.dataTransfer.getData("draggedIndex");
    if (draggedIndex === targetIndex.toString()) return;

    const droppedItemsCopy = [...droppedItems];
    const draggedItem = droppedItemsCopy[draggedIndex];
    droppedItemsCopy.splice(draggedIndex, 1);
    droppedItemsCopy.splice(targetIndex, 0, draggedItem);

    setDroppedItems(droppedItemsCopy);
  };

  const renderTooltip = (props, item) => {
    if (
      !item ||
      typeof item !== "object" ||
      !item.hasOwnProperty("mediaType")
    ) {
      return null;
    }
    //console.clear();
    return (
      <Tooltip id="button-tooltip" {...props}>
        <div>
          {item.name}
          <PiClockCountdownBold style={{ marginRight: "3px" }} />
          {formatDuration(item.duration)}
        </div>
      </Tooltip>
    );
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };
  //console.clear();

  const handleAddImageToOverlay = (media) => {
    const newItem = {
      id: media.id,
      fileUrl: media.fileUrl,
      mediaType: "image",
      duration: 5, // Default duration for images
      name: media.fileName,
      uniqueId: itemIdCounter++,
    };
    setDroppedItems((prevItems) => [...prevItems, newItem]);
  };

  const handleAddVideoToOverlay = (media) => {
    const newItem = {
      id: media.id,
      fileUrl: media.fileUrl,
      mediaType: "video",
      duration: media.duration || 5, // Use provided duration or default
      name: media.fileName,
      uniqueId: itemIdCounter++,
      muted: media.muted || false, // Use provided mute state or default to false
    };
    setDroppedItems((prevItems) => [...prevItems, newItem]);
  };

  return (
    <>
      <div className="layoutview-container ">
        <Row className="p-2 align-items-center">
          <Col
            md={6}
            className="d-flex align-items-center gap-2"
            style={{ width: "auto", height: "50px" }} // Set fixed width and height
          >
            {isEditing ? (
              <>
                <input
                  type="text"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  className="px-2 py-[3px] tw-rounded-sm mr-2 border"
                  style={{ width: "auto", outline: "2px solid #0D6EFD" }}
                  autoFocus
                />
                <Button
                  onClick={handleUpdateName}
                  icon={<AiOutlineCheck />}
                  size="small"
                  className="mr-1"
                  title="Save"
                />
                <Button
                  onClick={handleCancel}
                  icon={<AiOutlineClose />}
                  size="small"
                  title="Cancel"
                />
              </>
            ) : (
              <>
                <h6 className="mb-0 mr-2">{newName || ""} Layout</h6>
                <Button
                  onClick={handleEditStart}
                  icon={<BsPencilSquare />}
                  size="small"
                  title="Edit"
                />
              </>
            )}
          </Col>
        </Row>
        <div
          className="row"
          style={{
            backgroundColor: "#F4F6F7",
            marginRight: "",
          }}
        >
          <div
            className="large-image-container col w-50"
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              backgroundColor: "white",
              borderRadius: "10px",
            }}
            onDrop={onDrop}
            onDragOver={onDragOver}
          >
            <div
              className="section1 rounded border border-2"
              style={{ height: "400px", overflow: "hidden" }}
            >
              <Carousel
                activeIndex={loopIndex}
                onSelect={handleSelect}
                interval={null}
                controlled
                onMouseEnter={() => setAutoplay(false)}
                onMouseLeave={() => setAutoplay(true)}
                style={{ height: "100%", border: "none" }}
              >
                {droppedItems.map((item, index) => (
                  <Carousel.Item key={index} className="media-content">
                    {renderMediaContent(item)}
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>

            <div className="section2">
              <h6 className="text-center">
                Drag Upload files to view layout display.
              </h6>
              <div className="images-selection">
                {droppedItems.map((item, index) => (
                  <OverlayTrigger
                    key={index}
                    placement="bottom"
                    delay={{ show: 250, hide: 700 }}
                    overlay={(props) => item && renderTooltip(props, item)}
                  >
                    {item.mediaType === "video" ? (
                      <div className="col-md-3 custom-col p-2">
                        <div
                          className="image-container cards bdr"
                          draggable
                          onDragStart={(e) => {
                            e.dataTransfer.setData("draggedIndex", index);
                          }}
                          onDragOver={onDragOverImage}
                          onDrop={(e) => onDropImage(e, index)}
                        >
                          <div>
                            <video
                              className="media-item small-burgers"
                              width="100%"
                              height="100%"
                              controls
                              muted={item.muted}
                            >
                              <source
                                src={item.fileUrl}
                                alt={item.fileName}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                          <CustomButton
                            disabledButton={false}
                            btnType="button"
                            BtnClassName="close-btn"
                            ClickEvent={(e) => {
                              e.stopPropagation();
                              handleDeleteClick(item.id, index);
                            }}
                            BtnTxt={<> X</>}
                          />
                        </div>
                      </div>
                    ) : item.mediaType === "image" ? (
                      <div className="col-md-3 custom-col p-2">
                        <div
                          className="image-container cards bdr"
                          draggable
                          onDragStart={(e) => {
                            e.dataTransfer.setData("draggedIndex", index);
                          }}
                          onDragOver={onDragOverImage}
                          onDrop={(e) => onDropImage(e, index)}
                        >
                          <img
                            className="media-item small-burgers"
                            src={item.fileUrl}
                            alt={item.name}
                          />
                          <CustomButton
                            disabledButton={false}
                            btnType="button"
                            BtnClassName="close-btn"
                            ClickEvent={(e) => {
                              e.stopPropagation();
                              handleDeleteClick(item.id, index);
                            }}
                            BtnTxt={<> X</>}
                          />
                        </div>
                      </div>
                    ) : null}
                  </OverlayTrigger>
                ))}
              </div>
            </div>
          </div>

          <div
            className="layout-content col w-50"
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            <div className="mt-2 flex">
              <Row className="p-2">
                <Col md={6}>
                  <h4>Library </h4>
                </Col>
                <Col align="right">
                  <div className="search-input-container-lay ">
                    <AiOutlineSearch style={{ fontSize: "23px" }} />
                    <input
                      type="search"
                      className="search-input"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <div
                className="image-video"
                style={{ marginTop: "40px", width: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    onClick={() => setSelectedTab("images")}
                    style={{ marginBottom: "10px" }}
                  >
                    <Button
                      className="default tw-flex tw-items-center poppins-font"
                      style={{
                        background:
                          selectedTab === "images" ? "transparent" : "",
                        border:
                          selectedTab === "images" ? "1px solid #4096FF" : "",
                        color: selectedTab === "images" ? "#4096FF" : "",
                      }}
                    >
                      <FaImage size={20} style={{ marginRight: "5px" }} />
                      Images
                    </Button>
                  </div>
                  <div
                    onClick={() => setSelectedTab("videos")}
                    style={{ marginBottom: "10px" }}
                  >
                    <Button
                      className="default tw-flex tw-items-center poppins-font"
                      style={{
                        background:
                          selectedTab === "videos" ? "transparent" : "",
                        border:
                          selectedTab === "videos" ? "1px solid #4096FF" : "",
                        color: selectedTab === "videos" ? "#4096FF" : "",
                      }}
                    >
                      <BiSolidVideos size={20} style={{ marginRight: "5px" }} />
                      Videos
                    </Button>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <Button
                      className="default tw-flex tw-items-center poppins-font"
                      onClick={handleListview}
                      style={{ background: view === "list" ? "#DEEBFF" : "" }}
                    >
                      <FaList style={{ marginRight: "5px" }} /> List View
                    </Button>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <Button
                      className="default tw-flex tw-items-center poppins-font"
                      onClick={handleGridview}
                      style={{ background: view === "grid" ? "#DEEBFF" : "" }}
                    >
                      <BsGrid style={{ marginRight: "5px" }} /> Grid View
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="library-content-in-layouts">
              {selectedTab === "images" && (
                <ImagesSelection
                  imagesfiles={imageFiles}
                  onDragStart={onDragStart}
                  view={view}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  onAddToOverlay={handleAddImageToOverlay}
                />
              )}
              {selectedTab === "videos" && (
                <VideoSelection
                  videosfiles={videoFiles}
                  onDragStart={onDragStart}
                  view={view}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  onAddToOverlay={handleAddVideoToOverlay}
                />
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "20px",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            disabledButton={false}
            btnType="button"
            BtnClassName="discard-btn"
            ClickEvent={handleDiscardLayout}
            BtnTxt={<>Discard</>}
          />

          <CustomButton
            disabledButton={false}
            btnType="button"
            BtnClassName="save-layout-button"
            ClickEvent={handleSaveLayout}
            BtnTxt={<>Next</>}
          />
        </div>
      </div>
      {/* </div> */}
      {saveLayoutModalVisible && (
        <SaveLayoutmodal
          accessToken={accessToken}
          show={saveLayoutModalVisible}
          droppedItems={droppedItems}
          onHide={closeSaveLayoutmodal}
          layoutId={id}
        />
      )}
      {loading && (
        <div className="overlay">
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
  accessToken: state.accessToken,
  sellerId: state.sellerIdReducer,
});

export default connect(mapStateToProps)(LayoutsView);
