import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { Button } from "antd";
import { UserapiClient } from "./userManagementApiclient";

import { Toaster,toast } from "sonner";
import { MdDelete } from "react-icons/md";
import { Row, Col, Modal } from "react-bootstrap";
import CustomButton from "./Button";

const Card = ({ data, className = "", setRefresh ,idToken }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleDeleteClick = async () => {
    try {
      const client = UserapiClient(idToken);
      const res = await client.delete("/inviteduser", {
        data: {
          email: data.email,
        },
      });
      console.log("User deleted successfully", res);
      toast.success("User deleted successfully");
      window.location.reload();
      setRefresh(true);
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting User");
    } finally {
      setModalVisible(false);
    }
  };

  return (
    <div
      className={twMerge(
        "tw-bg-white tw-rounded-lg tw-shadow-md tw-p-2 tw-flex tw-flex-col tw-justify-between",
        className
      )}
      style={{ width: "100%", maxWidth: "350px" }} // Adjust the maximum width as needed
    >
       <Toaster position="bottom-right" className="tw-mr-4"  />
      {/* Card Header */}
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        {/* Status button */}
        {data.status === "Accepted" && (
          <button className="tw-bg-btn-green tw-text-white tw-py-1 tw-px-2 tw-rounded">
            {data.isRegistered ? "Registered" : "Not Registered"}
          </button>
        )}
        {data.status !== "Pending" && (
          <button className="tw-bg-btn-red tw-text-white tw-py-1 tw-px-2 tw-rounded">
            {data.isRegistered ? "Registered" : "Not Registered"}
          </button>
        )}
        {/* Delete button */}
        <Button
          type="danger"
          onClick={() => {
            setModalVisible(true);
            console.log("Modal visible:", modalVisible); // Check if modalVisible is true
          }} // Trigger modal visibility
          className="tw-border-0"
        >
          <MdDelete />
        </Button>
        {/* Modal */}
        <Modal
          size="sm"
          animation={false}
          show={modalVisible} // Use 'visible' instead of 'show'
          onHide={() => setModalVisible(false)} // Use 'onCancel' instead of 'onHide'
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <div style={{ width: "100%" }}>
                <h4 style={{ fontSize: "16px", textAlign: "center" }}>
                  Are you sure you want to delete this user?
                </h4>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <Col className="d-flex justify-content-around">
                <CustomButton
                  BtnTxt="Yes"
                  BtnClassName="yes-modal-btn"
                  ClickEvent={handleDeleteClick}
                />
                <CustomButton
                  BtnTxt="No"
                  BtnClassName="yes-modal-btn"
                  ClickEvent={() => setModalVisible(false)}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
      {/* Avatar and Name */}
      <div className="tw-w-full tw-flex tw-justify-center tw-items-center tw-mb-2">
        <div className="tw-w-20 tw-h-20 tw-sm:w-20 tw-sm:h-20 tw-md:w-24 tw-md:h-24 tw-rounded-full tw-overflow-hidden tw-mx-auto">
          <img
            src={
              data.avatar ||
              "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
            }
            alt="Avatar"
            className="tw-w-full tw-h-full tw-rounded-full tw-object-cover"
          />
        </div>
      </div>
      <div className="tw-text-center tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
        <h2 className="tw-text-lg tw-font-semibold tw-mb-2 tw-text-center">
          {data.name || "Mano"}
        </h2>
        {/* Email and Role */}
        <div>
          <p className="tw-text-gray-600">{data.email}</p>
          <p className="tw-text-gray-600 tw-text-center">{data.role}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
