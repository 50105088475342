import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Button } from "antd";
import CustomButton from "../../components/Button";
import { ThreeDots } from "react-loader-spinner";
import { IoCloudUploadOutline } from "react-icons/io5";
import { apiClient } from "../../components/apiClient";
import { Toaster, toast } from "sonner";
import "./Library.css";
import { useQueryClient } from "@tanstack/react-query";

const AddmoreVideos = ({
  showVideoModal,
  handleCloseVideoModal,
  accessToken,
  sellerId,
}) => {
  const queryClient = useQueryClient();
  const [files, setFiles] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!showVideoModal) {
      setFiles([]);
      setVideoUrls([]);
      setError(null);
    }
  }, [showVideoModal]);

  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      const selectedFiles = e.dataTransfer.files;
      if (selectedFiles && selectedFiles.length > 0) {
        const videoFiles = Array.from(selectedFiles).filter(
          (file) => file.type === "video/mp4"
        );
        if (videoFiles.length === 0) {
          setError("Please drop at least one MP4 video file.");
          return;
        }

        const newFiles = [...files, ...videoFiles];
        const newVideoUrls = videoFiles.map((file) =>
          URL.createObjectURL(file)
        );

        setFiles(newFiles);
        setVideoUrls([...videoUrls, ...newVideoUrls]);
      }
    };

    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", handleDrop);

    return () => {
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", handleDrop);
    };
  }, [files]);
  const handleFileChange = (event) => {
    setError(null);
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const videoFiles = Array.from(selectedFiles).filter(
        (file) => file.type === "video/mp4"
      );
      if (videoFiles.length === 0) {
        setError("Please select at least one MP4 video file.");
        return;
      }
      const newVideoUrls = videoFiles.map((file) => URL.createObjectURL(file));

      setFiles([...files, ...videoFiles]);
      setVideoUrls([...videoUrls, ...newVideoUrls]);
    } else {
      setError("Please select at least one MP4 video file.");
    }
  };

  const handleSave = async () => {
    try {
      setError(null);

      if (files.length === 0) {
        setError("Please select at least one file.");
        return;
      }

      const maxVideoLimit = 10;

      if (files.length > maxVideoLimit) {
        setError(`Exceeded the maximum limit of ${maxVideoLimit} videos.`);
        return;
      }

      const client = apiClient(accessToken);
      setLoading(true);

      const payload = {
        files: Array.from(files).map((file) => ({
          fileName: file.name,
          fileType: file.type,
        })),
      };

      const response = await client.post(
        `library/presignedurls/${sellerId}`,
        payload
      );

      if (response.status === 200) {
        const presignedUrls = response.data.presignedUrls;

        // Create an array to store upload promises
        const uploadPromises = presignedUrls.map(
          async (presignedUrl, index) => {
            try {
              const key = presignedUrl.fields.key;
              const file = files[index];

              const formData = new FormData();
              formData.append("key", key);
              formData.append("file", file);

              const uploadResponse = await fetch(presignedUrl.url, {
                method: "POST",
                body: formData,
              });

              if (uploadResponse.status !== 204) {
                throw new Error(
                  `Error uploading file ${index + 1}. Status: ${
                    uploadResponse.status
                  }`
                );
              }

              const metadata = {
                fileDetails: [
                  {
                    fileName: file.name,
                    fileType: file.type,
                    fileSize: file.size,
                    fileLocation: `${presignedUrl.url}/${encodeURIComponent(
                      key
                    )}`,
                  },
                ],
              };

              await storeMetadata(accessToken, metadata);
              toast.success(`File ${index + 1} uploaded successfully.`);
            } catch (error) {
              console.error(`Error uploading file ${index + 1}:`, error);
              throw error;
            }
          }
        );

        // Wait for all uploads to complete before proceeding
        await Promise.all(uploadPromises);
        await queryClient.invalidateQueries();
      } else {
        toast.error("Error getting presigned URLs. Please try again.");
      }

      handleModalClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error uploading media:", error);
      toast.error("Error uploading media. Please try again.");
    }
  };

  const storeMetadata = async (accessToken, fileDetails) => {
    try {
      const client = apiClient(accessToken);

      const response = await client.post(
        `library/storemetadata/${sellerId}`,
        fileDetails
      );

      if (response.status === 200) {
        console.log("Media uploaded successfully");
      } else {
        console.error("Error storing metadata. Please try again.");
      }

      return response;
    } catch (error) {
      console.error("Error storing metadata:", error);
      throw error;
    }
  };

  // const processFiles = (selectedFiles) => {
  //   setFiles(selectedFiles);
  // };

  const handleModalClose = () => {
    setFiles([]);
    setVideoUrls([]);
    setError(null);
    handleCloseVideoModal();
  };

  const handleVideoClose = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);

    const updatedVideoUrls = [...videoUrls];
    updatedVideoUrls.splice(index, 1);

    setFiles(updatedFiles);
    setVideoUrls(updatedVideoUrls);
  };

  const handleUploadAreaClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal
      show={showVideoModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title style={{ fontFamily: "Poppins", fontWeight: "600" }}>
          Add Videos to Library
        </Modal.Title>
      </Modal.Header>
      <hr />
      <Modal.Body className="d-flex justify-content-center">
        <div
          className="text-center custom-modal-content"
          style={{ width: "100%" }}
        >
          <div
            className="select d-flex flex-column align-items-center justify-content-center mb-4"
            onClick={handleUploadAreaClick}
            style={{
              minHeight: "150px",
              height: "auto",
              border: "2px dashed #D5D5D5",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              multiple
            />
            <IoCloudUploadOutline
              style={{ marginBottom: "8px", fontSize: "20px" }}
            />
            <span>Click or drag file to this area to Upload</span>
          </div>
          <div
            className="p-1 d-flex justify-content-flex-center"
            style={{ color: "#9D9D9D" }}
          >
            MP4 format upto 5MB - 50 MB
          </div>
          <hr />
          <div
            className="p-1 d-flex justify-content-center align-items-center"
            style={{
              minHeight: "150px",
              height: "auto",
              borderRadius: "8px",
              gap: "14px",
              overflowY: "auto",
              flexWrap: "wrap",
            }}
          >
            {videoUrls.map((videoUrl, index) => (
              <div
                key={index}
                className="video-grid-item"
                style={{
                  position: "relative",
                  width: "calc(33.33% - 10px)",
                  marginBottom: "10px",
                }}
              >
                <div
                  className="video-container"
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "auto",
                    cursor: "pointer",
                  }}
                >
                  <button
                    type="button"
                    className="close-button"
                    onClick={() => handleVideoClose(index)}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      border: "none",
                      color: "#000",
                      fontSize: "20px",
                      cursor: "pointer",
                      borderRadius: "10px",
                      zIndex: 1,
                    }}
                  >
                    &times;
                  </button>
                  <div
                    onMouseEnter={(e) => {
                      const closeButton =
                        e.target.parentNode.querySelector(".close-button");
                      if (closeButton) {
                        closeButton.style.display = "block";
                      }
                    }}
                    onMouseLeave={(e) => {
                      const closeButton =
                        e.target.parentNode.querySelector(".close-button");
                      if (closeButton) {
                        closeButton.style.display = "none";
                      }
                    }}
                  >
                    <video
                      width="100%"
                      height="auto"
                      controls
                      onClick={(e) => e.stopPropagation()}
                    >
                      <source src={videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <div className="file-name" style={{ marginTop: "5px" }}>
                  {files[index].name}
                </div>
              </div>
            ))}
          </div>
          {error && <div className="error">{error}</div>}
        </div>
      </Modal.Body>

      <hr />
      <Modal.Footer style={{ background: "#fff" }}>
        <Button
          disabled={false}
          size="large"
          className="default tw-flex tw-items-center poppins-font"
          onClick={handleModalClose}
        >
          Cancel
        </Button>
        <Button
          disabled={false}
          size="large"
          className="tw-bg-primary tw-text-white tw-flex tw-items-center poppins-font"
          onClick={handleSave}
        >
          Save
        </Button>
      </Modal.Footer>
      <Toaster position="bottom-right" className="tw-mr-4" />
      {loading && (
        <div className="overlay">
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  sellerId: state.sellerIdReducer,
});

export default connect(mapStateToProps)(AddmoreVideos);
