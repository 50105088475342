import React, { useState } from "react";
import { BsGrid3X3GapFill, BsListUl } from "react-icons/bs";
import CustomDataTable from "../../components/DataTable";
import Card from "../../components/Card";
import { Pagination } from "antd";
import {
  AiOutlineDelete,
  AiOutlineStop,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import CustomButton from "../../components/Button";
import { UserapiClient } from "../../components/userManagementApiclient";
import { Toaster, toast } from "sonner";
import { Modal, Row, Col } from "react-bootstrap";
import "./User.css";

function InstanceUsers({
  data,
  setRefresh,
  searchTerm,
  setSearchTerm,
  currentBusiness,
  idToken
}) {
  const [view, setView] = useState("list");
  const [deleteItem, setDeleteItem] = useState(null);
  const [revokeItem, setRevokeItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;

  const columns = [
    {
      name: "Name",
      cell: (param) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
            alt="profile"
            style={{ width: "34px", height: "34px", marginRight: "5px" }}
          />
          <span
            title="Delete"
            style={{
              cursor: "pointer",
              padding: "5px",
              display: "inline-block",
              marginLeft: "5px",
            }}
            // onClick={() => handleDeleteClick(param.id)}
          >
            {param?.name}
          </span>
        </div>
      ),
      selector: (param) => param?.name,
    },
    {
      name: "Email",
      selector: (param) => param?.email,
    },
    {
      name: "Role",
      selector: (param) => param?.role,
      sortable: true,
      cell: (param) => (param?.role !== "admin" ? param?.role : null),
    },
    {
      name: "Status",
      selector: (param) => (param?.isActive ? "Active" : "Not Active"),
      sortable: true,
    },
    {
      name: "Action",
      cell: (param) => (
        <>
          <span
            title={
              param?.isActive
                ? "Revoke Permission for Active User"
                : "Revoke Permission for Inactive User"
            }
            style={{
              cursor: "pointer",
              padding: "5px",
              display: "inline-block",
              border: "1px solid #DFDFDF",
              color: "#666666",
              background: "#F0F0F0",
              borderRadius: "4px",
              marginRight: "5px",
            }}
            onClick={() => handleRevokeClick(param?.userId)}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {param?.isActive ? (
                <AiOutlineStop style={{ fontSize: "20px", color: "#666666" }} />
              ) : (
                <AiOutlineCloseCircle
                  style={{ fontSize: "20px", color: "#666666" }}
                />
              )}
            </div>
          </span>
          <span
            title="Delete"
            style={{
              cursor: "pointer",
              padding: "5px",
              display: "inline-block",
              border: "1px solid #DFDFDF",
              color: "#666666",
              background: "#F0F0F0",
              borderRadius: "4px",
              marginLeft: "5px",
            }}
            onClick={() => handleDeleteClick(param?.userId)}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineDelete style={{ fontSize: "20px", color: "#666666" }} />
            </div>
          </span>
        </>
      ),
    },
  ];

  const handleRevokeClick = (id) => {
    const itemToRevoke = data.find((item) => item?.userId === id);
    setRevokeItem(itemToRevoke);
    setShowRevokeModal(true);
  };

  const handleDeleteClick = (id) => {
    console.log("Delete clicked", id);
    const itemToDelete = data.find((item) => item?.userId === id);
    console.log("data", data);
    setDeleteItem(itemToDelete);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const client = UserapiClient(idToken);
      const res = await client.delete(
        `/removeaccess/${currentBusiness}/${deleteItem?.userId}`
      );
      console.log("User deleted successfully", res);
      toast.success("User deleted successfully");
      window.location.reload();
      setRefresh(true);
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting User");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleConfirmRevoke = async () => {
    try {
      const client = UserapiClient(idToken);
      const newStatus = !revokeItem?.isActive;

      const payload = {
        isActive: newStatus,
      };

      const res = await client.put(
        `/updateuserstatus/${currentBusiness}/${revokeItem?.userId}`,
        payload
      );

      console.log("User status updated successfully", res);
      toast.success("User status updated successfully");
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Error updating user status:", error);
      toast.error("Error updating user status");
    } finally {
      setShowRevokeModal(false);
    }
  };

  // Function to filter data based on search query
  const filteredData = data.filter(
    (item) =>
      item?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) &&
      item?.role !== "admin"
  );

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredData.length);

  return (
    <div className="flex flex-col h-3/4 w-full p-4">
      <Modal
        size="sm"
        animation={false}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ width: "100%" }}>
              <h4 style={{ fontSize: "16px", textAlign: "center" }}>
                Are you sure you want to delete this user?
              </h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col className="d-flex justify-content-around">
              <CustomButton
                BtnTxt="Yes"
                BtnClassName="yes-modal-btn"
                ClickEvent={handleConfirmDelete}
              />
              <CustomButton
                BtnTxt="No"
                BtnClassName="yes-modal-btn"
                ClickEvent={() => setShowDeleteModal(false)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Revoke Permission Modal */}
      <Modal
        size="sm"
        animation={false}
        show={showRevokeModal}
        onHide={() => setShowRevokeModal(false)}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ width: "100%" }}>
              <h4 style={{ fontSize: "16px", textAlign: "center" }}>
                Are you sure you want to revoke this user's permission?
              </h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col className="d-flex justify-content-around">
              <CustomButton
                BtnTxt="Yes"
                BtnClassName="yes-modal-btn"
                ClickEvent={handleConfirmRevoke}
              />
              <CustomButton
                BtnTxt="No"
                BtnClassName="yes-modal-btn"
                ClickEvent={() => setShowRevokeModal(false)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <div className="tw-flex tw-justify-end">
        <button
          onClick={() => {
            setView("list");
          }}
          className={`tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-border tw-rounded-md tw-focus:outline-none ${
            view === "list" ? "tw-bg-sel-btn" : "tw-bg-white"
          }`}
        >
          <BsListUl className="tw-inline-block tw-mr-2" />
          List View
        </button>

        <button
          onClick={() => {
            setView("grid");
          }}
          className={`tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-border tw-rounded-md tw-focus:outline-none ${
            view === "grid" ? "tw-bg-sel-btn" : "tw-bg-white"
          }`}
          style={{ marginLeft: "8px" }}
        >
          <BsGrid3X3GapFill className="tw-inline-block tw-mr-2" />
          Grid View
        </button>
      </div>

      <div className="flex-1 py-4">
        {view === "list" ? (
          <CustomDataTable
            data={filteredData}
            columns={columns}
            pagination={false}
            tableKey="userTable"
          />
        ) : (
          <div className="tw-grid tw-grid-cols-3 tw-sm:grid-cols-2 tw-md:grid-cols-3 tw-lg:grid-cols-3 tw-gap-4 tw-fl">
            {filteredData.slice(startIndex, endIndex).map((item) => (
              <div key={item.id}>
                <Card data={item} setRefresh={setRefresh} idToken={idToken} />
              </div>
            ))}
          </div>
        )}
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <Pagination
            className="mt-4"
            current={currentPage}
            total={filteredData.length}
            pageSize={pageSize}
            onChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <Toaster position="bottom-right" className="tw-mr-4" />
    </div>
  );
}

export default InstanceUsers;
