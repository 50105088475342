const tableStyle = {
  headCells: {
    style: {
      backgroundColor: "#F1F1F1",
      border: "none",
      fontFamily: "Poppins",
      fontWeight: "500",
      fontStyle: "normal",
      color: "#666666",
      fontSize: "14px",
      height: "43px",
      borderBottom: "none",
      borderRight: "1px solid #CCC",
      lineHeight: "16px",
      letterSpacing: "0.6px",
      userSelect: "none", // Prevent text selection during resize
      padding: 0,
    },
  },
  rows: {
    style: {
      backgroundColor: "#FFFFFF !important",
      color: "#111",
      fontFamily: "Poppins",
      border: "none",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
    },
  },
  cells: {
    style: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  pagination: {
    style: {
      borderBottomRightRadius: "5px",
      borderBottomLeftRadius: "5px",
    },
  },
};

export default tableStyle;
