import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { apiClient } from "../../components/apiClient";
import Modal from "react-bootstrap/Modal";


import { DateFormat } from "../../components/DateFormat";


import CustomButton from "../../components/Button";

import { Tooltip } from "antd";
import { connect } from "react-redux";

import { FaEye } from "react-icons/fa";


const addDisArr = ["qr", "rt", "vr", "ty"];

const AddDisplay = ({ accessToken, sellerId }) => {
  const [layoutArr, setLayoutArr] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showLayoutModal, SetShowLayoutModal] = useState(false);

  const handleLayoutClose = () => {
    SetShowLayoutModal(false);
  };

  const handleLayoutModalShow = () => {
    SetShowLayoutModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiClient(accessToken).get(
          `/display/getActiveLayout/${sellerId}`
        );
       // console.log(data.data.data);
        setLayoutArr(data.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [accessToken,sellerId]);

  const columns = [
    {
      name: "Select",
      cell: (param) => (
        <>
          <input type="checkbox" />
        </>
      ),
    },

    {
      name: "Layout Name",
      selector: (param) => param.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (param) => param.status,
      sortable: true,
    },
    {
      name: " Displays",
      selector: (param) => "Screen",
      sortable: true,
    },
    {
      name: " Date",
      selector: (param) => DateFormat(param.createdAt),
      sortable: true,
    },
    {
      name: "Action",
      cell: (param) => (
        <>
          <Tooltip title="View" color="#0071ca">
            <span
              title="View"
              style={{ cursor: "pointer", color: "#0071ca", padding: "5px" }}
              // onClick={() => handleStoreLayout(param)}
            >
              <FaEye />
            </span>
          </Tooltip>
          {/* 
              <Tooltip title="Take Screenshot" color="#0071ca" >
              <span
                  title="Screenshot"
                  style={{ cursor: "pointer", color: "#0071ca", padding: "5px" }}
                  // onClick={() => handleShowViewDisplay(param, true)}
                >
                  <MdOutlineScreenshot />
                </span>
              </Tooltip>
                
              <Tooltip title="Health" color="#0071ca" >
              <span
                  title="Health"
                  style={{ cursor: "pointer", color: "#0071ca", padding: "5px" }}
                  // onClick={() => handleShowViewDisplay(param, true)}
                >
                  <TbHealthRecognition />
                </span>
              </Tooltip>
                 */}

          {/* {param.status == 1 && (
                  <>
                    <span
                      title="Delete Location"
                      style={{ cursor: "pointer", color: "#0071ca" }}
                      onClick={(e) => handleDelete(param.id)}
                    >
                      <FaTrash />
                    </span>
                  </>
                )} */}
        </>
      ),
    },
  ];
  const columns1 = [
    {
      name: "Layout Name",
      selector: (param) => "Forum Mall",
      sortable: true,
    },
    {
      name: "Status",
      selector: (param) => "Active",
      sortable: true,
    },
    {
      name: " Displays",
      selector: (param) => "Screen",
      sortable: true,
    },
    {
      name: " Time",
      selector: (param) => "5:20",
      sortable: true,
    },

    {
      /* 
            <Tooltip title="Take Screenshot" color="#0071ca" >
            <span
                title="Screenshot"
                style={{ cursor: "pointer", color: "#0071ca", padding: "5px" }}
                // onClick={() => handleShowViewDisplay(param, true)}
              >
                <MdOutlineScreenshot />
              </span>
            </Tooltip>
              
            <Tooltip title="Health" color="#0071ca" >
            <span
                title="Health"
                style={{ cursor: "pointer", color: "#0071ca", padding: "5px" }}
                // onClick={() => handleShowViewDisplay(param, true)}
              >
                <TbHealthRecognition />
              </span>
            </Tooltip>
               */
    },
  ];

  return (
    <div className="displays-bg-container">
      <div className="layout-add-btn-container mb-4 mt-2">
        <h6>Add Layout</h6>
        <CustomButton
          disabledButton={false}
          btnType="button"
          BtnClassName="add-layout-button"
          ClickEvent={handleLayoutModalShow}
          BtnTxt={<>View Layout</>}
        />
      </div>
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <div className="">
          <DataTable
            title=""
            columns={columns1}
            data={addDisArr}
            pagination
            highlightOnHover
            paginationRowsPerPageOptions={[5, 10, 15]}
            paginationPerPage={10}
          />
        </div>
      )}
      <>
        <Modal
          show={showLayoutModal}
          size="lg"
          onHide={handleLayoutClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <div style={{ width: "100%" }}>
              <h6 style={{ margin: 0, alignItems: "center" }}>Layouts</h6>
            </div>
          </Modal.Header>
          <div className="">
            <DataTable
              title=""
              columns={columns}
              data={layoutArr}
              pagination
              highlightOnHover
              paginationRowsPerPageOptions={[5, 10, 15]}
              paginationPerPage={10}
            />
          </div>
          <Modal.Body></Modal.Body>
          <Modal.Footer className="modal-footer">
            <CustomButton
              disabledButton={false}
              // ClickEvent={handleSubmit}
              BtnClassName="Filter-button"
              btnType="submit"
              BtnTxt={<>Submit</>}
            />
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  sellerId: state.sellerIdReducer,
});

export default connect(mapStateToProps)(AddDisplay);
