import React from "react";
import { Form } from "react-bootstrap";

const TextBox = ({
  txtBoxLabel,
  txtBoxType,
  txtBoxID,
  txtAs,
  txtBoxPH,
  txtBoxValue,
  changeEvent,
  txtBoxName,
  disabled,
  style,
}) => {
  return (
    <Form.Group>
      <Form.Label>{txtBoxLabel}</Form.Label>
      {txtAs ? (
        <>
          {" "}
          <Form.Control
            type={txtBoxType}
            id={txtBoxID}
            placeholder={txtBoxPH}
            as={txtAs}
            value={txtBoxValue}
            onChange={changeEvent}
            name={txtBoxName}
            disabled={disabled}
            className={style}
          />
        </>
      ) : (
        <Form.Control
          type={txtBoxType}
          id={txtBoxID}
          placeholder={txtBoxPH}
          value={txtBoxValue}
          onChange={changeEvent}
          name={txtBoxName}
          disabled={disabled}
          className={style}
        />
      )}
    </Form.Group>
  );
};

export default TextBox;
